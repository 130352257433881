import React from 'react'
import {StyledProductGroupContentModal} from './_styled'
import {Text} from 'common/text'
import {Input} from "../../../../../common/form/input";
import {useAddressModal} from "../../../hooks/useAddressModal";

const Index = ({...props}) => {
   const {addressName, shipCode, customerName, addressId, validate} = useAddressModal()
   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className={'product-group-content__top'}>
               <div className='product-group-content_group'>
                  <Text color={'#2150B7'} fontSize={18} fontWeight={700} as={'i'}>Khách hàng: {customerName}</Text>
               </div>
            </div>
            <div className={'product-group-content__bottom'}>
               <div className='product-group-content_group'>
                  <Input value={addressName.value}
                         onChange={e => addressName.onChange(e.target.value)}
                         onBlur={e => addressName.onBlur(e.target.value)}
                         validateType={!validate?.addressName ? 'success' : 'danger'}
                         validateText={validate?.addressName}
                         placeholder={'Vui lòng nhập địa chỉ'}
                         label={<Text>Địa chỉ <Text color={'red'}>*</Text></Text>}/>
               </div>
               <div className='product-group-content_group'>
                  <Input value={shipCode.value} disabled={!!addressId}
                         onChange={e => shipCode.onChange(e.target.value)}
                         onBlur={e => shipCode.onBlur(e.target.value)}
                         validateType={!validate?.shipCode ? 'success' : 'danger'}
                         validateText={validate?.shipCode}
                         placeholder={'Vui lòng nhập mã địa chỉ'}
                         label={<Text>Mã địa chỉ <Text color={'red'}>*</Text></Text>}/>
               </div>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index
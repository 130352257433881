import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const OrderCustomerInfo = () => {
  const { customerInfo } = useOrderFilterForm()
  return (
    <CategoryInput
      className={'order-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Khách hàng', value: '' }}
      categoryWidth={100}
      placeholder='Nhập Tên KH/Cardcode/Số điện thoại'
      value={customerInfo?.value || ''}
      onChange={customerInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}

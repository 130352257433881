import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateSaleForm} from "../../hook/useCreateSaleForm";

export const AccountNumber = ()=>{
    const {accountNumber} = useCreateSaleForm()
    const {methods} = accountNumber
    return (
        <Input
            label={
                <>
                    Số tài khoản
                </>
            }
            value={accountNumber?.value}
            onChange={e => methods.onChange(e.target?.value)}
            validateText={accountNumber?.message}
            validateType={accountNumber?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập số tài khoản ngân hàng'}
            maxLength={32}
        />
    )
}
import {Button} from 'common/button'
import {Text} from 'common/text'
import styled from 'styled-components'
import {Input} from "../../../../common/form/input";
import {useState} from "react";
import {sendRequestAuth} from "../../../../api/api";
import config from "../../../../config";
import useAlert from "../../../../hook/useAlert";

export const ConfirmMoveOrderModal = ({
                                         idOrder,
                                         content,
                                         title,
                                         onClose,
                                        onReload,
                                         ...props
                                      }) => {
   const [isLoading, setIsLoading] = useState(false)
   const [emailDistributor, setEmailDistributor] = useState('')
   const [nameDistributor, setNameDistributor] = useState('')
   const [validateForm, setValidateForm] = useState(null)
   const [emailCc, setEmailCc] = useState('')
  const {showAlert} = useAlert()

   const onEmailDistributorChange = data => {
      setEmailDistributor(data)
   }

   const onEmailDistributorBlur = data => {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!!!data)
         setValidateForm({
            ...validateForm,
            emailDistributor: {
               status: true,
               message: 'Email không được để trống'
            }
         })
      // else if (!regex.test(data) && data !== '')
      //    setValidateForm({
      //       ...validateForm,
      //       emailDistributor: {
      //          status: true,
      //          message: 'Email phải nhập theo example@gmail.com'
      //       }
      //    })
      else if (data?.length >= 255)
         setValidateForm({
            ...validateForm,
            emailDistributor: {
               status: true,
               message: 'Email được phép nhập 255 ký tự.'
            }
         })
      else
         setValidateForm({
            ...validateForm,
            emailDistributor: {
               status: false,
               message: ''
            }
         })
   }
   const onEmailCcChange = data => {
      setEmailCc(data)
   }

   const onEmailCcBlur = data => {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!!!data)
         setValidateForm({
            ...validateForm,
            emailCc: {
               status: false,
               message: ''
            }
         })
      else if (!regex.test(data) && data !== '')
         setValidateForm({
            ...validateForm,
            emailCc: {
               status: true,
               message: 'Email phải nhập theo example@gmail.com'
            }
         })
      else if (data?.length >= 201)
         setValidateForm({
            ...validateForm,
            emailCc: {
               status: true,
               message: 'Email được phép nhập 200 ký tự.'
            }
         })
      else
         setValidateForm({
            ...validateForm,
            emailCc: {
               status: false,
               message: ''
            }
         })
   }
   const onNameDistributorChange = data => {
      setNameDistributor(data)
   }

   const onNameDistributorBlur = data => {
      if (!!!data)
         setValidateForm({
            ...validateForm,
            nameDistributor: {
               status: true,
               message: 'Tên nhà cung cấp không được để trống'
            }
         })
      else if (data?.length >= 201)
         setValidateForm({
            ...validateForm,
            nameDistributor: {
               status: true,
               message: 'Tên nhà cung cấp chỉ được phép nhập 200 ký tự.'
            }
         })
      else
         setValidateForm({
            ...validateForm,
            nameDistributor: {
               status: false,
               message: ''
            }
         })
   }

   const validateFormBeforeSubmit = () => {
      let validate = {}
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!!!emailCc)
         validate = {
            ...validate, emailCc: {
               status: false,
               message: ''
            }
         }
      else if (!regex.test(emailCc) && emailCc !== '')

         validate = {
            ...validate, emailCc: {
               status: true,
               message: 'Email phải nhập theo example@gmail.com'
            }
         }
      else if (emailCc?.length >= 201)
         validate = {
            ...validate, emailCc: {
               status: true, message: 'Email được phép nhập 200 ký tự.'
            }
         }
      else
         validate = {
            ...validate, emailCc: {
               status: false, message: ''
            }
         }
      if (!!!emailDistributor)
         validate = {
            ...validate, emailDistributor: {
               status: true,
               message: 'Email không được để trống'
            }
         }
      else if (emailDistributor?.length >= 201)
         validate = {
            ...validate, emailDistributor: {
               status: true, message: 'Email được phép nhập 200 ký tự.'
            }
         }
      else
         validate = {
            ...validate, emailDistributor: {
               status: false, message: ''
            }
         }
      if (!!!nameDistributor)
         validate = {
            ...validate, nameDistributor: {
               status: true,
               message: 'Tên nhà cung cấp không được để trống'
            }
         }
      else if (nameDistributor?.length >= 201)
         validate = {
            ...validate, nameDistributor: {
               status: true,
               message: 'Tên nhà cung cấp chỉ được phép nhập 200 ký tự.'
            }
         }
      else
         validate = {
            ...validate, nameDistributor: {
               status: false,
               message: ''
            }
         }
      setValidateForm(validate)
      if (!!validate?.nameDistributor?.status || !!validate?.emailDistributor?.status || !!validate?.emailCc?.status) return false
      return true
   }
   const onSubmitForm = async () => {
      if (!validateFormBeforeSubmit()) return
      setIsLoading(true)
      const response = await sendRequestAuth('post',
         `${config.API}/order/send-to-distributor/${idOrder}`,
         {
            "distributor_email": emailDistributor,
            "distributor_name": nameDistributor,
            "email_cc": emailCc
         }
      )
      if(!!response.data?.success){
        showAlert({
          type: 'success',
          content: response.data?.message || ""
        })
        onReload()
        onClose()
      }else{

        showAlert({
          type: 'danger',
          content: response.data?.message || ""
        })
      }
      setIsLoading(false)


   }
   return (
      <StyledConfirmDeleteModal {...props} onClick={onClose}>
         <div
            className="order-table__confirm-delete-modal__container"
            onClick={e => e.stopPropagation()}
         >
            <div className="order-table__confirm-delete-modal__header">
               <Text as="h2" fontSize={20} lineHeight={28}>
                  {title}
               </Text>
            </div>
            <div className="order-table__confirm-delete-modal__body">
               <div className={'order-table__container'}>
                  <div className={'order-table__item'}>
                     <Input label={<Text>Email nhà phân phối <Text color={'red'}> *</Text></Text>}
                            value={emailDistributor}
                            onChange={e => onEmailDistributorChange(e.target.value)}
                            onBlur={e => onEmailDistributorBlur(e.target.value)}
                            validateType={!!validateForm?.emailDistributor?.status ? 'danger' : 'success'}
                            validateText={validateForm?.emailDistributor?.message || ''}
                            placeholder={"Nhập email nhà phân phối"}/>
                  </div>
                  <div className={'order-table__item'}>
                     <Input label={<Text>Tên nhà phân phối <Text color={'red'}> *</Text></Text>}
                            value={nameDistributor}
                            onChange={e => onNameDistributorChange(e.target.value)}
                            onBlur={e => onNameDistributorBlur(e.target.value)}
                            validateType={!!validateForm?.nameDistributor?.status ? 'danger' : 'success'}
                            validateText={validateForm?.nameDistributor?.message || ''}
                            placeholder={"Nhập tên nhà phân phối"}/>
                  </div>
                  <div className={'order-table__item'}>
                     <Input label={<Text>Email cc</Text>}
                            value={emailCc}
                            onChange={e => onEmailCcChange(e.target.value)}
                            onBlur={e => onEmailCcBlur(e.target.value)}
                            validateType={!!validateForm?.emailCc?.status ? 'danger' : 'success'}
                            validateText={validateForm?.emailCc?.message || ''}
                            placeholder={"Nhập email cc"}/>
                  </div>
               </div>
            </div>
            <div className="order-table__confirm-delete-modal__footer">
               <Button
                  size="sm"
                  appearance="ghost"
                  style={{minWidth: 110}}
                  onClick={onClose}
               >
                  Đóng
               </Button>
               <Button
                  disabled={isLoading}
                  size="sm"
                  style={{minWidth: 110, marginLeft: 8}}
                  onClick={onSubmitForm}
               >
                  Xác nhận
               </Button>
            </div>
         </div>
      </StyledConfirmDeleteModal>
   )
}

const StyledConfirmDeleteModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.25);

  .order-table__confirm-delete-modal {
    &__container {
      width: 480px;
      padding: 24px;

      background: #ffffff;
      border-radius: 8px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__header {
      margin-bottom: 24px;
    }

    &__body {
      margin-bottom: 32px;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  .order-table{
    &__item{
      margin-bottom: 16px;
    }
  }
`

import React from 'react'
import {StyledProductGroupContentModal} from './_styled'
import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from '../../../../common/form/autoComplete/_option'
import {useEditOrderModal} from '../../hooks/useEditOrderModal'
import {Input} from "../../../../common/form/input";

const Index = ({...props}) => {

   const {customer, detail} = useEditOrderModal()

   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className={'product-group-content__bottom'}>
               <div className='product-group-content_group'>
                  <Input value={detail?.id} disabled={true} label={'Mã đơn hàng'}/>
               </div>
               <div className='product-group-content_group'>
                  <AlternativeAutoComplete
                     className="customer-filter-form__input-wide"
                     // main input
                     inputProps={{
                        categoryList: [], // menu list in category dropdown
                        categoryValue: {name: 'Khách hàng SAP', value: ''}, // if not exist this value -> default category: categoryList[0]
                        categoryWidth: 82,
                        categoryHidden: true,
                        placeholder: 'Chọn khách hàng SAP', label: "Khách hàng SAP",
                        readOnly: true,
                        value: customer.value?.card_code ? customer.value?.card_name : '',
                     }}
                     // menu
                     menuProps={{
                        empty: customer.list.length <= 0 ? 'Không tìm thấy khách hàng SAP' : '',
                     }}
                     // search input in dropdown menu
                     searchInputProps={{
                        placeholder: 'Tìm kiếm khách hàng SAP',
                        value: customer.keyword,
                        onChange: customer.onKeywordChange,
                     }}
                  >
                     {customer.list.length > 0 &&
                     customer.list.map(item => (
                        <Option
                           key={item?.card_code}
                           className="customer-filter-form__option-text"
                           data-active={item.card_code === customer.value?.card_code}
                           onClick={() => customer?.onChange(item)}
                        >
                           {item?.card_name}
                        </Option>
                     ))}
                  </AlternativeAutoComplete>
               </div>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index
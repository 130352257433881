import React from "react";
import {Text} from "../../../../../../common/text";
import styled from 'styled-components'
import {useCreateSaleForm} from "../../hook/useCreateSaleForm";
// import {SALE_ICON} from "../../interfaces/_icon";
import {CREATE_SALE_GENDER} from "../../interfaces/constant";
import {Radio} from "../../../../../../common/form/radio";
export const Gender = ()=>{
    const { gender} = useCreateSaleForm()
    const {methods} = gender
    const handleChange = (data)=>{
        methods.onChange(data)
    }
    return (
        <StyleGender>
            <Text>Giới tính</Text>
            <div className='sale_gender' >
                {
                    CREATE_SALE_GENDER.map((map,i)=>{
                        return(
                            <div className='sale_gender_radio' onClick={()=>handleChange(map) }>
                                <Radio
                                    checked={map.value === gender?.value?.value}
                                />
                                <Text style={{marginLeft: 8}} >{map.name}</Text>
                            </div>

                        )
                    })
                }
            </div>

        </StyleGender>
    )
}
const StyleGender = styled.div`
    .sale_gender{
       margin-top: 8px;
       display: flex;
       align-items: center;
       
       &_radio{
          margin-right: 24px;
          display: flex;
          align-items: center;
          cursor: pointer;
       }
    }
`
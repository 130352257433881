import {Box, Modal} from "@mui/material";
import React from "react";
import './~style.scss'
import {Button} from "common/button";
import {useTranslation} from "react-i18next";
import { GLOBAL_ICONS } from 'interface/icon'

export const HistoryPopup = ({
  header,
  headerProps,
  body,
  bodyProps,
  footer,
  footerProps,
  openModal,
  clickClose,
  animationClose,
  acceptance,
  disableSubmit,
  ...props
}) => {
  const {t} = useTranslation()
  return (
    <Modal open={openModal} onClose={clickClose} {...props}>
      <Box className={`history-popup__box ${animationClose && `history-popup__box-close`}`}>
        <div className={`history-popup__box--dismiss`} onClick={clickClose}>
          {GLOBAL_ICONS.dismiss}
        </div>
        <div className={`history-popup__wrapper common-scrollbar`}>
          {!!header && (
            <div
              {...headerProps}
              className={`history-popup__header ${headerProps?.className || ''}`}
            >
              <p className={`history-popup__header-title`}>{t(header?.title)}</p>
              {
                !!header?.subTitle && <p className={`history-popup__header-sub-title`}>{t(header?.subTitle)}</p>
              }

            </div>
          )}
          {Array.isArray(body) && body.length > 0 && (
            <div
              {...bodyProps}
              className={`history-popup__container ${bodyProps?.className || ''}`}
            >
              {body.map((content, i) => (
                <div className={`history-popup__group`} key={i}>
                  {content.item}
                </div>
              ))}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}
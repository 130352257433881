import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateSaleForm} from "../../hook/useCreateSaleForm";

export const UserName = ()=>{
    const {userName} = useCreateSaleForm()
    const {methods} = userName
    return (
        <Input
            label={
                <>
                    Tên đăng nhập <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={userName?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={userName?.message}
            validateType={userName?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập tên đăng nhập'}
            // maxLength={255}
        />
    )
}
import {useContext} from "react";
import {CreateSaleContext} from "../provider/_context";
import {useCreateSaleAction} from "../provider/_reducer";

export const useCreateSaleTab = ()=>{
    const {pageState, pageDispatch} = useContext(CreateSaleContext)
    const {tab} = pageState

    const handleChangeTab = (data) =>
        pageDispatch({type: useCreateSaleAction.FORM_CREATE_TAB_CHANGE, payload: data})

    return{
        tab:{
            list: tab?.list,
            active: tab?.activeTab,
            onChange: handleChangeTab
        }
    }

}
import React, {memo, useEffect} from "react";
import useProductGroup from "./hook/useProductGroup";
import {ProductGroupProvider} from "./provider";
import PageHeader from "./component/header/index";
import {TableLayout} from "layouts/tableLayout";
import ModalDelete from "./component/delete/index"
import ModalCreate from "./component/modal/index"
import FilterForm from "./component/filter";
import ProductGroupHeader from "./component/table/thead"
import ProductGroupBody from "./component/table/tbody"
import {sendRequestAuth} from "../../api/api";
import config from "../../config";
import {useProductAction} from "./provider/_reducer";

const Index = memo(() => {
   const {provider,pagination} = useProductGroup()
   const {state, dispatch} = provider
   const {table} = state

  const getCategories = async () => {
    const response = await sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`)
    if (response.data?.length > 0) {
      dispatch({
        type: useProductAction.CREATE_BANNER_CATEGORY_UPDATE,
        payload: {
          list: response?.data,
          listOrigin: response?.data
        }
      })
    }
  }
  const getProduct = async () => {
    const response = await sendRequestAuth(
      'get',
      `${
        config.API
      }/order/filter/books?keyword=&category_id=&status=&per_page=999&start=`,
    )
    if (response.data?.length > 0) {
      dispatch({
        type: useProductAction.CREATE_BANNER_PRODUCT_UPDATE,
        payload: {
          list: response?.data,
          listOrigin: response?.data
        }
      })
    }
  }

  useEffect(() => {
    if (state.category.list?.length < 1) {
      getProduct()
      getCategories()
    }
  }, [state.category.list])
   return (
      <ProductGroupProvider value={{pageState: state, pageDispatch: dispatch}}>
         <PageHeader/>
         {/*<SearchProduct/>*/}
         <TableLayout
            header={
               <FilterForm/>
            }
            table={{
               tHead: <ProductGroupHeader/>,
               tBody: <ProductGroupBody/>,
            }}
            pagination={{
               ...table.pagination,
               onAmountChange: pagination.onAmountChange,
               onPageChange: pagination.onPageChange,
            }}
         />
         {state.check_confirm_delete && <ModalDelete/>}
         {state.open_modal && <ModalCreate/>}
      </ProductGroupProvider>

   )
})
export default Index
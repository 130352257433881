import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import React from 'react'

export const UtmSource = () => {
  const {utmSource} = useOrderFilterForm()
  return (
    <AlternativeAutoComplete
      className="order-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Nguồn đơn hàng', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 130,
        placeholder: 'Chọn nguồn đơn hàng',
        readOnly: true,
        value: utmSource.value?.name || '',
        onIconClick: () => utmSource.onChange(null),
      }}
      // menu
      menuProps={{
        empty: utmSource.list.length <= 0 ? 'Không tìm thấy nguồn đơn hàng' : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm nguồn đơn hàng',
        value: utmSource.keyword,
        onChange: utmSource.onKeywordChange,
      }}
      hideSearchBar={true}
    >
      {utmSource.list.length > 0 &&
        utmSource.list.map(item => (
          <Option
            key={item.value}
            className="order-filter-form__option-text"
            data-active={item.value === utmSource.value?.value}
            onClick={() => utmSource.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}

import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {useContext, useState} from 'react'
import styled from 'styled-components'
import {Tooltip as TooltipV2} from 'common/tooltipv2'
import {Loading} from '../../../../common/loading'
import {fDateTimeCustom, fDateTimeSuffix} from "../../../../util/formatTime";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {Button} from "../../../../common/button";
import {Tooltip} from "../../../../common/tooltip";
import {useAddressModalAction, useContactModalAction} from "../../provider/_reducer";
import {CustomerContext} from "../../provider/_context";

export const RowTabDetail = ({data, rowData, ...props}) => {
   const [isLoading, setIsLoading] = useState(null)
   const {pageDispatch} = useContext(CustomerContext)
   const {detail} = rowData
   const handleCreateContactModal = (contact = null) => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL,
         payload: {
            open_modal: true,
            change_modal: true,
            cardCode: detail?.id || '',
            contactId: contact?.id || '',
            customerName: detail?.active?.card_name,
            contactName: contact?.contact_name || '',
            contactPhone: contact?.contact_phone || '',
            cntctCode: contact?.cntct_code || '',
         }
      })
   }
   const handleConfirmDeleteContact = (contact) => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            modal_confirm_delete: {
               open: true,
               data: {
                  ...contact,
                  customer_name: detail?.active?.card_name
               },
            },
         }
      })
   }

   const handleCreateAddressModal = (address = null) => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL,
         payload: {
            open_modal: true,
            change_modal: true,
            cardCode: detail?.id || '',
            addressId: address?.id || '',
            customerName: detail?.active?.card_name,
            addressName: address?.address || '',
            shipCode: address?.ship_to_code || '',
         }
      })
   }
   const handleConfirmDeleteAddress = (address) => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            modal_confirm_delete: {
               open: true,
               data: {
                  ...address,
                  customer_name: detail?.active?.card_name
               },
            },
         }
      })
   }
   return (
      <StyledRowTabDetail>
         {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
         <div className="row-tab-detail__content">
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  Thông tin khách hàng
               </Text>

               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Tên khách hàng
                  </Text>
                  <Text>
                     {detail?.active?.card_name || '---'}
                  </Text>
               </div>

               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Cardcode
                  </Text>
                  <Text>
                     {detail?.active?.card_code || '---'}
                  </Text>
               </div>

               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Sale quản lý
                  </Text>
                  <Text>
                     {detail?.active?.teacher_name || '---'}
                  </Text>
               </div>
            </div>
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  &nbsp;
               </Text>
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Group khách hàng
                  </Text>
                  <Text>{detail?.active?.group_name || '---'}</Text>
               </div>
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Địa chỉ
                  </Text>
                  <Text>{detail.active?.province_name + ', ' + detail.active?.region_name}</Text>
               </div>
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">
                     Ngày phân công quản lý
                  </Text>
                  <Text>{!!detail.active?.assign_at ? fDateTimeCustom(detail.active.assign_at, {format: 'HH:mm:ss dd/MM/yyyy'}) : '---'}</Text>
               </div>
            </div>
         </div>
         {!!detail?.active?.card_code &&
         <div className="row-tab-detail__content row-tab-detail__sap flex">
            <div className="row-tab-detail__content--item">
               <div className="row-tab-detail__content-header flex">
                  <Text as={'p'} fontSize={16} fontWeight={600}>Người liên hệ</Text>
                  <Button size={'md'} icon={GLOBAL_ICONS.plus} appearance={'secondary'}
                          onClick={() => handleCreateContactModal()}>Thêm liên hệ</Button>
               </div>
               <div className="row-tab-detail__content-table">
                  <div className="row-tab-detail__content-table_th">
                     <div className="row-tab-detail__content-table_contact-col">
                        Tên
                     </div>
                     <div className="row-tab-detail__content-table_contact-col">
                        Số điện thoại
                     </div>
                     <div className="row-tab-detail__content-table_contact-col">
                        Mã liên hệ
                     </div>
                     <div className="row-tab-detail__content-table_contact-col">
                     </div>
                  </div>
                  <div className={'row-tab-detail__content-table_content common-scrollbar'}>
                     {detail?.active?.contacts?.length > 0 ?
                        detail?.active.contacts.map(contact => (
                           <div className="row-tab-detail__content-table_tr">

                              <div className="row-tab-detail__content-table_contact-col">
                                 {contact.contact_name}
                              </div>
                              <div className="row-tab-detail__content-table_contact-col">
                                 {contact.contact_phone}
                              </div>
                              <div className="row-tab-detail__content-table_contact-col">
                                 {contact.cntct_code}
                              </div>
                              <div className="row-tab-detail__content-table_contact-col"
                                   style={{cursor: 'pointer', justifyContent: 'end'}}>
                                 <Tooltip title={'Chỉnh sửa liên hệ'}
                                          onClick={() => handleCreateContactModal(contact)}><Text
                                    as={'p'}>{GLOBAL_ICONS.edit}</Text></Tooltip>
                                 {/*<Tooltip title={'Xóa liên hệ'} onClick={() => handleConfirmDeleteContact(contact)}><Text as={'p'}>{GLOBAL_ICONS.recycle}</Text></Tooltip>*/}
                              </div>
                           </div>
                        ))

                        :

                        <div className="row-tab-detail__content-table_tr">

                           <div className="row-tab-detail__content-table_contact-col"
                                style={{height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <Text fontSize={18}>Chưa có thông tin liên hệ</Text>
                           </div>

                        </div>
                     }
                  </div>
               </div>

            </div>
            <div className="row-tab-detail__content--item">
               <div className="row-tab-detail__content-header flex">
                  <Text as={'p'} fontSize={16} fontWeight={600}>Địa chỉ</Text>
                  <Button size={'md'} icon={GLOBAL_ICONS.plus} appearance={'secondary'}
                          onClick={() => handleCreateAddressModal()}>Thêm địa chỉ</Button>
               </div>
               <div className="row-tab-detail__content-table">
                  <div className="row-tab-detail__content-table_th">

                     <div className="row-tab-detail__content-table_address-col">
                        Địa chỉ
                     </div>
                     <div className="row-tab-detail__content-table_address-col">
                        Mã địa chỉ
                     </div>
                     <div className="row-tab-detail__content-table_address-col">
                     </div>
                  </div>

                  <div className={'row-tab-detail__content-table_content common-scrollbar'}>
                     {detail?.active?.addresses?.length > 0 ?
                        detail.active.addresses.map(address => (
                           <div className="row-tab-detail__content-table_tr">

                              <div className="row-tab-detail__content-table_address-col">
                                 {address?.address || "---"}
                              </div>
                              <div className="row-tab-detail__content-table_address-col">
                                 {address?.ship_to_code || "---"}
                              </div>
                              <div className="row-tab-detail__content-table_address-col">
                                 <Tooltip title={'Chỉnh sửa địa chỉ'}
                                          onClick={() => handleCreateAddressModal(address)}><Text
                                    as={'p'}>{GLOBAL_ICONS.edit}</Text></Tooltip>
                                 <Tooltip title={"Xóa địa chỉ"}
                                          onClick={() => handleConfirmDeleteAddress(address)}><Text
                                    as={'p'}>{GLOBAL_ICONS.recycle}</Text></Tooltip>
                              </div>
                           </div>
                        ))
                        : <div className="row-tab-detail__content-table_tr">

                           <div className="row-tab-detail__content-table_address-col"
                                style={{height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <Text fontSize={18}>Chưa có thông tin địa chỉ</Text>
                           </div>
                        </div>
                     }
                  </div>
               </div>
            </div>
         </div>
         }
         {!!isLoading && (
            <Loading/>
         )}
      </StyledRowTabDetail>
   )
}

const StyledRowTabDetail = styled.div`
  .row-tab-detail {
    &__sap{
      border-top: 4px solid #E2EAF8;
      padding-top: 16px;
      margin-top: 16px;
    }
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-header{
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
      }
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
      
      &--item{
        width: calc(50% - 16px);
        margin-right: 16px;
        &:last-child{
          margin-right: 0px;
          margin-left: 16px;
        }
      }
      &-table{
        width: 100%;
        border: 1px solid #E2EAF8;
        &_th{
          display: flex;
          padding: 10px 8px;
          align-items: center;
          align-self: stretch;
          
          border-top: 1px solid #E2EAF8;
          border-bottom: 1px solid #E2EAF8;
          background: #EFF3FB;
        }
        &_tr{
          display: flex;
          padding: 13px 8px;
          align-items: center;
          align-self: stretch;
          
          border-top: 1px solid #E2EAF8;
          border-bottom: 1px solid #E2EAF8;
          background: #FFF;
        }
        &_content{
          max-height: 400px;
          overflow: auto;
        }
        &_contact-col{
          padding: 0 10px;
          &:nth-child(1){
            flex: 1;
          }
          &:nth-child(2){
            width: 25%;
          }
          &:nth-child(3){
            width: 25%;
          }
          &:nth-child(4){
            padding: 0 4px;
            width: 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        &_address-col{
          padding: 0 10px;
          &:nth-child(1){
            flex: 1;
          }
          &:nth-child(2){
            width: 25%;
          }
          &:nth-child(3){
            padding: 0 4px;
            width: 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }

    &__content-group {
      width: calc(100% / 3 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;

        text-align: right;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;
        cursor: pointer;
        text-align: right;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
`

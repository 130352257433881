import React from 'react'
import {StyledProductGroupContentModal} from './_styled'
import {Text} from 'common/text'
import {Input} from "../../../../../common/form/input";
import {useContactModal} from "../../../hooks/useContactModal";

const Index = ({...props}) => {
   const {contactName, contactPhone, cntctCode, customerName, contactId, validate} = useContactModal()
   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className={'product-group-content__top'}>
               <div className='product-group-content_group'>
                  <Text color={'#2150B7'} fontSize={18} fontWeight={700} as={'i'}>Khách hàng: {customerName}</Text>
               </div>
            </div>
            <div className={'product-group-content__bottom'}>
               <div className='product-group-content_group'>
                  <Input value={contactName.value}
                         onChange={e => contactName.onChange(e.target.value)}
                         onBlur={e => contactName.onBlur(e.target.value)}
                         placeholder={'Vui lòng nhập tên liên hệ'}
                         validateType={validate?.contactName ? 'danger' : 'success'}
                         validateText={validate?.contactName}
                         label={<Text>Tên liên hệ <Text color={'red'}>*</Text></Text>}/>
               </div>
               {!!contactId &&
               <div className='product-group-content_group'>
                  <Input value={cntctCode.value} disabled={!!contactId}
                         onChange={e => cntctCode.onChange(e.target.value)}
                     // onBlur={e => cntctCode.onBlur(e.target.value)}
                         validateType={validate?.cntctCode ? 'danger' : 'success'}
                         validateText={validate?.cntctCode}
                         placeholder={'Vui lòng nhập mã liên hệ trên hệ thống SAP'}
                         label={<Text>Mã liên hệ <Text color={'red'}>*</Text></Text>}/>
               </div>
               }
               <div className='product-group-content_group'>
                  <Input value={contactPhone.value}
                         onChange={e => contactPhone.onChange(e.target.value)}
                         onBlur={e => contactPhone.onBlur(e.target.value)}
                         validateType={validate?.contactPhone ? 'danger' : 'success'}
                         validateText={validate?.contactPhone}
                         type={'number'}
                         maxLength={11}
                         placeholder={'Vui lòng nhập số điện thoạ theo mã liên hệ trên hệ thống SAP'}
                         label={<Text>Số điện thoại <Text color={'red'}>*</Text></Text>}/>
               </div>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index
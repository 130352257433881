import React, {useContext, useState} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Text} from 'common/text'
import ReactImageFallback from "react-image-fallback";
import {fDateTimeSuffix} from "util/formatTime";
import {ProductContext} from "../../../../provider/~context";
import {useModalProductRelateAction} from "../../../../provider/~action";
import {formatMoney} from "../../../../../../util/functionUtil";
import {Tooltip} from "../../../../../../common/tooltipv2";
import {AddProductModal} from "./popup/_addProduct";
import {useProductTab} from "../../../../hooks/useProductTab";

const TbodyProduct = () => {
  const {t} = useTranslation()
  const {pageState, pageDispatch} = useContext(ProductContext)
  const {productTab} = pageState.formCreate
  const {table} = productTab
  const displayList = table?.display?.list
  const displayListDefault = table?.display?.listDefault
  const displayLoading = table.display.loading
  const paginationAmount = table.pagination.amount
  const paginationTotalItems = displayList.length
  const {productRelated} = pageState.formCreate
  const {modalProduct} = productRelated
  const {methods} = useProductTab()

  return (
    <>
      {displayLoading ? (
        Array.from(Array(paginationAmount), (e, i) => (
          <ProductPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map((item, key) => <ProductTr t={t} key={item.id} stt={key} data={item}/>)
      ) : (
        <ProductEmpty t={t} list={displayListDefault}/>
      )}
      <AddProductModal open={modalProduct.show}
                       onClose={() => {
                         methods.applyOtherFilter()
                         pageDispatch({type: useModalProductRelateAction.DISPLAY_MODAL_PRODUCT, payload: false})
                         pageDispatch({type: useModalProductRelateAction.RESET_MODAL_FILTER})
                       }}/>
    </>
  )
}

export default TbodyProduct

const ProductPlaceholder = ({...props}) => {
  return (
    <div {...props} className="row-tab-detail__tr" style={{background: '#fff'}}>
      {Array.from(Array(4), (e, i) => (
        <div key={i} className="row-tab-detail__td" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
            }}
          />
        </div>
      ))}
    </div>
  )
}

const ProductEmpty = ({list, t, ...props}) => {
  return (
    <StyledProductEmpty {...props}>
      <img
        className="create-teacher-table-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
        {!!!list ? t('Bạn chưa có danh sách giáo viên nào') : t('no_matching_data')}
      </Text>
    </StyledProductEmpty>
  )
}

const ProductTr = ({data, t, stt, ...props}) => {
  return (
    <div key={data.id} className="row-tab-detail__tr">
      <div className="row-tab-detail__td">
        {stt + 1}
      </div>
      <div
        className="row-tab-detail__td"
      >
        <div className="create-teacher-table__cell-image">
          <ReactImageFallback
            src={data?.image}
            fallbackImage={'/img/grid-default.png'}
            alt="img bottm img"
          />
        </div>

        <div style={{width: 'calc(100% - 60px)'}}>
          <Text as={'p'} fontSize={13} >{data?.category_name || '---'}</Text>
          <Tooltip baseOn={'height'} title={data?.title} className={'store-upos-table__tooltipV2'}>
            <Text as={'p'} fontWeight={600} color={"#2150B7"}>{data?.title || '---'}</Text>
          </Tooltip>
          <Text as={'p'} fontSize={13}>{data?.product_sku || '---'}</Text>
        </div>
      </div>
      <div className="row-tab-detail__td">
        <Text>
          {formatMoney(data?.price || 0) || '---'}
        </Text>
      </div>
      <div className="row-tab-detail__td">
        {data?.discount && +data?.discount > 0 && formatMoney((+data?.price * +data?.discount / 100) || 0)}
      </div>
      <div className="row-tab-detail__td">
        {!!data?.discount_started_at ? fDateTimeSuffix(data?.discount_started_at) : '---'} <br/>
        {!!data?.discount_ended_at ? fDateTimeSuffix(data?.discount_ended_at) : '---'}
      </div>
    </div>
  )
}

export const StyledProductEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`

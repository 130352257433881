import {Text} from 'common/text'
import {Grid} from '@mui/material'
import styled from 'styled-components'
import React, {useContext} from 'react'
import {ProductContext} from "../../../provider/~context";
import {Radio} from "../../../../../common/form/radio";
import {ProductTab} from "./tabAllProduct";
import {CategoryType} from "./tabCategory";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import {useCategoryAction, useProductAction} from "../../../provider/~reducer";
import {useParams} from "react-router-dom";
import ActionFormBrnList from "../actionFormBrnList";
import {useModalProductRelateAction} from "../../../provider/~action";

const ConfigProduct = () => {

   const {pageState, pageDispatch} = useContext(ProductContext)
   const {configProductType} = pageState.formCreate
   const {customerId} = useParams()
   const getListCategory = async () => {
      const response = await sendRequestAuth('get', `${config.API}/product-category/all-categories?type=digital&is_parent=`)
      if (response?.data?.length > 0) {
         const categories = response?.data?.map(item => {
            if (item?.childs?.length > 0) {

               const childCates = item.childs.map(child => {
                  return {
                     ...child,
                     selected: true
                  }
               })
               return {

                  ...item,
                  selected: true,
                  childs: childCates
               }

            } else {
               return {
                  ...item,
                  selected: true
               }
            }
         })
         pageDispatch({
            type: useCategoryAction.UPDATE_CATEGORY_DATA,
            payload: {
               listDefault: categories,
               selected: categories,
            }
         })
      }

   }

   const getListProduct = async () => {
      const response = await Promise.all([
         sendRequestAuth('get', `${config.API}/product-category/group-categories?type=book&is_parent=0`),
         sendRequestAuth('get', `${config.API}/customer/detail/${customerId}/linked-products?product_name=&category_id=&status&start=0&per_page=20`)
      ])
      if (response[0]?.data?.length > 0) {
         const listCategories = response[0]?.data
         pageDispatch({
            type: useProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
            payload: {
               list: listCategories,
               listOrigin: listCategories
            }
         })
         pageDispatch({
            type: useModalProductRelateAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
            payload: {
               list: listCategories,
               listOrigin: listCategories
            }
         })
      }
      if (!!response[1]?.data?.success) {
         const productList = response[1]?.data
         pageDispatch({
            type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
            payload: {
               list: productList?.data,
               listDefault: productList?.data,
               loading: false
            }
         })

         pageDispatch({
            type: useProductAction.TABLE_UPDATE_PAGINATION,
            payload: {
               active: Math.ceil(productList?.meta?.start / productList?.meta?.per_page),
               amount: productList?.meta?.per_page,
               total: Math.ceil(productList?.meta?.total / productList?.meta?.per_page),
               totalItems: productList?.meta?.total,
            }
         })
      }
   }

   const handleActionConfigChange = action => {
      switch (action) {
         case 'all':
            pageDispatch({
               type: 'UPDATE_TYPE_PRODUCT_CONFIG',
               payload: {
                  value: action
               }
            })
            break

         case 'category':
            pageDispatch({
               type: 'UPDATE_TYPE_PRODUCT_CONFIG',
               payload: {
                  value: action
               }
            })
            getListCategory()
            break
         case 'product':
            getListProduct()
            pageDispatch({
               type: 'UPDATE_TYPE_PRODUCT_CONFIG',
               payload: {
                  value: action
               }
            })
            break

         default:
            break
      }
   }
   return (
      <StyledInfoBasic>
         <Grid container spacing={2} style={{marginBottom: 16}}>
            <Grid xs={3} sm={3} md={3} lg={3} item className={'flex product-info-basic'}
                  onClick={() => handleActionConfigChange('all')}>
               <Radio checked={configProductType?.value === 'all'}/>
               <Text style={{marginLeft: 8}}>Bán tất cả sản phẩm</Text>
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} item className={'flex product-info-basic'}
                  onClick={() => handleActionConfigChange('category')}>
               <Radio checked={configProductType?.value === 'category'}/>
               <Text style={{marginLeft: 8}}>Theo danh mục</Text>
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} item className={'flex product-info-basic'}
                  onClick={() => handleActionConfigChange('product')}>
               <Radio checked={configProductType?.value === 'product'}/>
               <Text style={{marginLeft: 8}}>Theo sản phẩm</Text>
            </Grid>
         </Grid>
         {
            configProductType?.value === 'category' && <CategoryType/>
         }
         {
            configProductType?.value === 'product' && (
               <div style={{
                  padding: "12px 24px 24px 12px",
                  borderBottom: '1px solid #ebeef5',
                  marginBottom: 24
               }}>
                  <ActionFormBrnList type={'product'}/>
               </div>
            )
         }
         {
            (configProductType?.value === 'product' && pageState.formCreate?.customer?.product_sale_type === 'product') &&
            <ProductTab/>
         }
         {
            configProductType?.value === 'all' && (
               <div style={{
                  position: 'sticky',
                  bottom: -44,
                  marginBottom: 0,
                  zIndex: 10,
                  padding: "12px 24px 12px 12px",
                  background: '#fff'
               }}>
                  <ActionFormBrnList type={'all'}/>
               </div>
            )
         }
      </StyledInfoBasic>
   )
}

export default ConfigProduct

export const StyledInfoBasic = styled.div`
  padding-top: 16px;
  .product-info-basic {
    cursor: pointer;
  }
`

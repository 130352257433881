import React, {useState} from 'react';
import {SkeletonProductPage} from "./~productPlaceHolder";
import {Td} from "layouts/tableLayout/_td";
import {Checkbox} from "common/form/checkbox";
import {Tr} from "layouts/tableLayout/_tr";
import ProductEmpty from "./~productEmpty";
import useCategoryType from "../../../../hooks/useCategoryType";
import styled from 'styled-components'
import {GLOBAL_ICONS} from "../../../../../../interface/icon";
import useCategoryRow from "../../../../hooks/useCategoryRow";
import {Text} from "common/text";

const ProductTBody = () => {
  const {displayList} = useCategoryType()
  return (
    <StyleCategoryType>
      {displayList?.loading ? (
        <SkeletonProductPage/>
      ) : displayList?.length > 0 ? (
        <>
          {displayList?.map((item, i) => <CategoryTr key={(item.id || 0) + i} data={item}/>)}
        </>
      ) : <ProductEmpty/>}
    </StyleCategoryType>
  )
}


const CategoryTr = ({data}) => {
  const {row, handleCheckBoxTabDetail} = useCategoryRow(data)
  // console.log(row)
  const [toggle, setToggle] = useState(true)

  return (
    <div className="product-table__parent_row" data-show={toggle} onClick={() => setToggle(!toggle)}>
      <Tr
        className="product-table__row"
      >
        <Td className="product-table__cell" data-type="td" style={{padding: '12px 18px'}}>
          {data?.childs?.length > 0 && <Text className={'product-table__expand'} data-expand={toggle}>
             {GLOBAL_ICONS.arrowUp}
          </Text>}
          <Checkbox
            checked={data?.selected || row.indeterminate }
            indeterminate={row.indeterminate  || !data?.selected}
            onClick={e => {
              e.stopPropagation()
              row.onCheckboxChange()
            }}
          />
        </Td>
        <Td className="product-table__cell" data-type="td"> {data?.title} </Td>
      </Tr>
      {
        data?.childs?.length > 0 && (
          <div style={{background: '#fff', paddingLeft: 32}}>
            {
              data.childs.map(child => (
                <Tr
                  key={child.id}
                  className="product-table__child_row"

                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <Td className="product-table__cell" data-type="td" style={{padding: '12px 18px'}}>
                    <Checkbox
                      checked={!!child?.selected}
                      onClick={e => {
                        e.stopPropagation()
                        handleCheckBoxTabDetail(child)
                      }}
                    />
                  </Td>
                  <Td className="product-table__cell" data-type="td"> {child?.title} </Td>
                </Tr>
              ))
            }
          </div>
        )
      }
    </div>
  )
}
const StyleCategoryType = styled.div`
   .product-table__parent_row{
     height: 68px;
     overflow: hidden;
     &[data-show=true]{
       height: auto;
     }
    transition: height 0.25s ease 0.1s;
   }
   .product-table__expand{
    svg{
      transform: rotate(270deg);
    }
    &[data-expand=true]{
      svg{
        transform: rotate(0deg);
      }
    }
   }

`

export default ProductTBody;
import {Text} from '../../../../../common/text'
import {Grid} from '@mui/material'
import styled from 'styled-components'
import React, {useContext} from 'react'
import {ProductContext} from "../../../provider/~context";

const InfoBasic = () => {

  const {pageState} = useContext(ProductContext)

  const {customer} = pageState.formCreate

  return (
    <StyledInfoBasic>
      <Grid container spacing={2} style={{marginBottom: 16}}>
        <Grid xs={3} sm={3} md={3} lg={3} item>
          <div className="product-info-basic__form-input ">
            <Text as={'p'} color={'#757575'}>Tên khách hàng</Text>
            <Text as={'p'}>{customer?.card_name || '---'}</Text>
          </div>
        </Grid>
        <Grid xs={3} sm={3} md={3} lg={3} item>
          <div className="product-info-basic__form-input ">
            <Text as={'p'} color={'#757575'}>Cardcode</Text>
            <Text as={'p'}>{customer?.card_code || '---'}</Text>
          </div>
        </Grid>
        <Grid xs={3} sm={3} md={3} lg={3} item>
          <div className="product-info-basic__form-input ">
            <Text as={'p'} color={'#757575'}>Group khách hàng</Text>
            <Text as={'p'}>{customer?.group_name}</Text>
          </div>
        </Grid>
        <Grid xs={3} sm={3} md={3} lg={3} item>
          <div className="product-info-basic__form-input ">
            <Text as={'p'} color={'#757575'}>Khu vực</Text>
            <Text
              as={'p'}>{customer?.province_name ? (customer.province_name + ', ') : ''} {customer?.region_name ? customer.region_name : ''}</Text>
          </div>
        </Grid>
      </Grid>
    </StyledInfoBasic>
  )
}

export default InfoBasic

export const StyledInfoBasic = styled.div`
  .config-product {
      background: #E2EAF8;
      padding: 12px 48px;
      border-radius: 8px 8px 0px 0px;
      &[data-active=true]{
        background: #fff;
      }
    }
`

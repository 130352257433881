import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useRevenueFilterForm from 'Pages/revenue/hooks/useRevenueFilterForm'
import { REVENUE_FILTER_TAG_FIELDS } from 'Pages/revenue/interfaces/_constants'
import { StyledRevenueTags } from './_styled'
import { RevenueTag } from './_tag'

export const RevenueTags = ({ ...props }) => {
  const {
    dateTime,
    paymentMethod,
    orderType,
    orderStatus,
    orderId,
    customerInfo,
    teacher,
    principal,
    functions,
  } = useRevenueFilterForm()

  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledRevenueTags {...props}>

      {orderId?.activeValue && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete(REVENUE_FILTER_TAG_FIELDS[0])}
        >
          Mã đơn hàng: {orderId.activeValue}
        </RevenueTag>
      )}
      {customerInfo.activeValue && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete(REVENUE_FILTER_TAG_FIELDS[1])}
        >
          Tên khách hàng/Số điện thoại: {customerInfo.activeValue}
        </RevenueTag>
      )}
      {orderType?.activeValue?.name && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete(REVENUE_FILTER_TAG_FIELDS[3])}
        >
          Loại đơn hàng: {orderType.activeValue.name}
        </RevenueTag>
      )}

      {principal?.activeValue?.name && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete(REVENUE_FILTER_TAG_FIELDS[4])}
        >
          Hiệu trưởng: {principal.activeValue.name}
        </RevenueTag>
      )}
      {teacher?.activeValue?.name && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete(REVENUE_FILTER_TAG_FIELDS[5])}
        >
          Sale quản lý: {teacher.activeValue.name}
        </RevenueTag>
      )}
      {paymentMethod?.activeValue?.name && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete(REVENUE_FILTER_TAG_FIELDS[6])}
        >
          Hình thức thanh toán: {paymentMethod.activeValue.name}
        </RevenueTag>
      )}
      {Array.isArray(orderStatus.activeValue) && orderStatus.activeValue.length > 0 && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete('status.current')}
        >
          Trạng thái đơn hàng: {orderStatus.activeValue
          .map(item => item?.name || 'unknown')
          .join(', ')}
        </RevenueTag>
      )}
      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <RevenueTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </RevenueTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledRevenueTags>
  )
}

import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateSaleForm} from "../../hook/useCreateSaleForm";

export const Password = ()=>{
    const {password} = useCreateSaleForm()
    const {methods} = password
    return (
        <Input
            label={
                <>
                    Mật khẩu  <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={password?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={password?.message}
            validateType={password?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập mật khẩu'}
            maxLength={33}
        />
    )
}
import {Button} from 'common/button'
import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {formatMoney} from '../../../../util/functionUtil'
import {useNavigate} from 'react-router-dom'
import {Tooltip} from '../../../../common/tooltip'
import {Tooltip as TooltipV2} from 'common/tooltipv2'
import {checkPermission} from '../../../../Component/NavBar/until'
// import PopupNotPermission from "../../../permision/popupNotPermission";
import {GLOBAL_ICONS} from '../../../../interface/icon'
import {fDateTimeCustom, fDateTimeDMY} from '../../../../util/formatTime'

export const RowTabSapDetail = ({data, rowData, ...props}) => {
   const {row, cell} = rowData
   const navigate = useNavigate()

   const [exportUrl, setExportUrl] = useState('#')

   const exportLink = useRef()

   const validEditPermission = checkPermission('order', 'edit')
   const validExportPermission = checkPermission('order', 'export')
   const [showPermission, setShowPermission] = useState(false)
   const handleEditClick = () => {
      if (validEditPermission) {
         navigate(`/order/${data.id}`)
      } else setShowPermission(true)
   }
   const handleExportClick = () => {
      if (validExportPermission) {
         const exportURLData = row.onExportOrderExcel()
         exportURLData.then(res => {
            if (res && res !== '#') setExportUrl(res)
         })
      } else setShowPermission(true)
   }

   useEffect(() => {
      if (exportUrl && exportUrl !== '#') {
         if (exportLink?.current) exportLink.current.click()
      }
   }, [exportUrl])
   return (
      <StyledRowTabDetail>
         {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
         <div className="row-tab-detail__content">
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  Thông tin đơn hàng
               </Text>
               {!!data?.created_at && (
                  <div className={'row-tab-detail__content-group--items'}>
                     <Text as="p" color="#7C88A6">
                        Số SQ
                     </Text>
                     <Text
                     >
                        {data?.order_process_reference || '---'}
                     </Text>
                  </div>
               )}
            </div>
            {(!!data?.customer_phone || !!data?.customer_address) && (
               <div className="row-tab-detail__content-group">
                  <Text as="h4" fontSize={16} lineHeight={22}>
                     &nbsp;
                  </Text>
                  {!!data?.customer_name && (
                     <div className={'row-tab-detail__content-group--items'}>
                        <Text as="p" color="#7C88A6">Mã đơn hàng portal sales</Text>
                        <Text>
                           {data?.DocNbr || '---'}
                        </Text>
                     </div>
                  )}
               </div>
            )}
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  &nbsp;
               </Text>
              <div className={'row-tab-detail__content-group--items'}>
                 <Text as="p" color="#7C88A6">Số SO</Text>
                 <Text>
                    {(!!data?.DocSO && data?.DocSO != 0 ) ? data?.DocSO : '---'}
                 </Text>
              </div>
            </div>
            <div className="row-tab-detail__content-group">
               <Text as="h4" fontSize={16} lineHeight={22}>
                  &nbsp;
               </Text>
               <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Thời gian giao hàng dự kiến</Text>
                  <Text>
                     {!!data.est_delivery_date ? fDateTimeCustom(data.est_delivery_date) : '---'}
                  </Text>
               </div>
            </div>
         </div>
         {
            data.deliveries?.length > 0
            && <>
               <div className="row-tab-detail__info-table">
                  <div>
                     <Text as="b" fontSize={16} lineHeight={22}>
                        Thông tin Phiếu xuất{' '}
                     </Text>
                  </div>
               </div>
               {data.deliveries.map((delivery, key) => (
                  <div key={key} style={{marginBottom: 36}}>
                     <div className="row-tab-detail__content" style={{marginBottom: 0}}>
                        <div className="row-tab-detail__content-group" style={{marginBottom: 0}}>
                           {!!delivery?.doc_entry && (
                              <div className={'row-tab-detail__content-group--items'} style={{marginBottom: 12}}>
                                 <Text as="p" color="#7C88A6">
                                    Số phiếu xuất
                                 </Text>
                                 <Text
                                 >
                                    {delivery?.doc_entry}
                                 </Text>
                              </div>
                           )}
                        </div>
                        <div className="row-tab-detail__content-group" style={{marginBottom: 0}}>
                           {!!delivery?.status_name && (
                              <div className={'row-tab-detail__content-group--items'} style={{marginBottom: 12}}>
                                 <Text as="p" color="#7C88A6">
                                    Trạng thái
                                 </Text>
                                 <Text
                                 >
                                    {delivery?.status_name}
                                 </Text>
                              </div>
                           )}
                        </div>
                        <div className="row-tab-detail__content-group" style={{marginBottom: 0}}>
                           {!!delivery?.status_name && (
                              <div className={'row-tab-detail__content-group--items'} style={{marginBottom: 12}}>
                                 <Text as="p" color="#7C88A6">
                                    Tracking đơn hàng
                                 </Text>
                                 <Text
                                    as={'a'}
                                    href={delivery?.tracking_detail || '#'}
                                    target={'_blank'}
                                    color={'#3264FF'}
                                 >
                                    {delivery?.tracking_detail}
                                 </Text>
                              </div>
                           )}
                        </div>
                     </div>
                     <div className="row-tab-detail__table">
                        <div className="row-tab-detail__thead">
                           <div className="row-tab-detail__tr">
                              <div className="row-tab-detail__th" style={{width: '30%'}}>Mã sản phẩm (ISBN/ID SAP)</div>
                              <div className="row-tab-detail__th">Tên sản phẩm</div>
                              <div className="row-tab-detail__th" style={{width: '20%'}}>Số lượng</div>
                           </div>
                        </div>
                        <div className="row-tab-detail__tbody">
                           {JSON.parse(delivery.list_items).map(item => (
                              <div key={item.product_id} className="row-tab-detail__tr">
                                 <div
                                    className="row-tab-detail__td"
                                    title={item?.ItemCode}
                                    style={{
                                       overflow: 'hidden',
                                       whiteSpace: 'nowrap',
                                       textOverflow: 'ellipsis',
                                       width: '30%'
                                    }}
                                 >
                                    {item?.ItemCode || '---'}
                                 </div>
                                 <div className="row-tab-detail__td">
                                    <Text
                                       color={THEME_SEMANTICS.delivering}
                                       style={{cursor: 'pointer'}}
                                    >
                                       {item?.ItemName || '---'}
                                    </Text>
                                    <span style={{cursor: 'pointer'}}></span>
                                 </div>
                                 <div className="row-tab-detail__td" style={{width: '20%'}}>
                                    {item?.Quantity
                                       ? Number(item.Quantity) < 10
                                          ? `0${item.Quantity}`
                                          : item.Quantity
                                       : '---'}
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               ))}
            </>
         }

      </StyledRowTabDetail>
   )
}

const StyledRowTabDetail = styled.div`
  .row-tab-detail {
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
    }

    &__content-group {
      width: calc(100% / 4 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3), &:nth-child(4), &:nth-child(5){
        width: 150px;
        text-align:center;
      }
      &:nth-child(6) {
        width: 200px;
        text-align:center;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        width: 150px;
        text-align:center;
      }
      &:nth-child(6) {
        width: 200px;
        text-align:center;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
`

import { productActions } from './~action'

export const productReducer = (state, action) => {
  switch (action.type) {

    case productActions.CHANGE_LOADING:
      return {
        ...state,
        loading: action?.payload
      }

    case productActions.CHANGE_FORM_STATUS:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          statusForm: action?.payload || '',
        },
      }

    case productActions.FORM_CREATE_CHANGE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          ...action.payload
        }
      }
    //  BASIC
    case productActions.FORM_CREATE_CHANGE_ACTIVE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            status: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_NAME_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            name: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_AUTHOR_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            author: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_LANGUAGE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            language: action.payload
          }
        }
      }

    case productActions.FORM_CREATE_CHANGE_STATUS_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            status: action.payload
          }
        }
      }

    case productActions.FORM_UPDATE_GROUP_PRODUCT_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            groupProduct: {
              ...state.formCreate.basic.groupProduct,
              ...action.payload
            }
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_PUBLISHER_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            publisher: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_CODE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            code: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_BARCODE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            barCode: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_PUBLISH_YEAR_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            publicationYear: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_ADD_LIST_ORIGIN:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            groupProduct: {
              ...state.formCreate.basic.groupProduct,
              list: action?.payload?.list,
              listOrigin: action?.payload?.listOrigin,
            }
          }
        }
      }
    case productActions.FORM_CREATE_UPDATE_LIST_CHILDREN_TWO:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            groupProduct: {
              ...state.formCreate.basic.groupProduct,
              listChildTwo: action?.payload,
            }
          }
        }
      }
    case productActions.FORM_CREATE_SEARCH_LIST_ORIGIN:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            groupProduct: {
              ...state.formCreate.basic.groupProduct,
              search: {
                keyword: action?.payload?.keyword,
                list: action?.payload?.list,
              },
            }
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_ID_GROUP_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            groupProduct: {
              ...state.formCreate.basic.groupProduct,
              id: action?.payload,
            }
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_VALUE_GROUP_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            groupProduct: {
              ...state.formCreate.basic.groupProduct,
              value: action?.payload,
            }
          }
        }
      }
    case productActions.FORM_CREATE_OPEN_MODAL_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              open: action?.payload,
            }
          }
        }
      }
    case productActions.FORM_CREATE_MODAL_CODE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              form: {
                ...state.formCreate.basic.modal.form,
                code: action?.payload,
              }
            }
          }
        }
      }
    case productActions.FORM_CREATE_MODAL_NAME_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              form: {
                ...state.formCreate.basic.modal.form,
                name: action?.payload,
              }
            }
          }
        }
      }
    case productActions.FORM_CREATE_MODAL_GROUP_PRODUCT_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              form: {
                ...state.formCreate.basic.modal.form,
                group: action?.payload,
              }
            }
          }
        }
      }
    case productActions.FORM_CREATE_MODAL_NOTE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              form: {
                ...state.formCreate.basic.modal.form,
                note: action?.payload,
              }
            }
          }
        }
      }
    case productActions.FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              validate: {
                ...state.formCreate.basic.modal.validate,
                code: {
                  status: action?.payload?.status,
                  message: action?.payload?.message,
                },
              }
            }
          }
        }
      }
    case productActions.FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            modal: {
              ...state.formCreate.basic.modal,
              validate: {
                ...state.formCreate.basic.modal.validate,
                name: {
                  status: action?.payload?.status,
                  message: action?.payload?.message,
                },
              }
            }
          }
        }
      }
    //  PRICE
    case productActions.FORM_CREATE_INIT_PRICE_RETAIL:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            retail: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_INIT_PRICE_DISCOUNT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            discount: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_INIT_PRICE_LAST_ENTRY:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            lastEntry: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_INIT_PRICE_COST:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            cost: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_INIT_QUANTITY:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            quantity: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_DATE_TIME_UPDATE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            dateTime: {
              ...state.formCreate.price.dateTime,
              ...action.payload
            }
          }
        }
      }

    //  PRODUCT
    case productActions.FORM_CREATE_CHANGE_IMAGE_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            image: {
              ...state.formCreate.product.image,
              name: action?.payload?.name || '',
              link: action?.payload?.link || '',
            }
          }
        }
      }
    case productActions.FORM_CREATE_UPDATE_IMAGE_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            image: {
              ...state.formCreate.product.image,
              ...action.payload
            }
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_INFO_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            ...action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_INFORMATION_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            ...action.payload
          }
        }
      }
    case productActions.FORM_CREATE_CHANGE_UNIT_VALUE_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            unit: {
              ...state.formCreate.product.unit,
              value: action.payload
            }
          }
        }
      }
    case productActions.FORM_CREATE_ADD_LIST_ORIGIN_UNIT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            unit: {
              ...state.formCreate.product.unit,
              list: action?.payload?.list,
              listOrigin: action?.payload?.listOrigin,
            }
          }
        }
      }
    case productActions.FORM_CREATE_SHOW_NOTE_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            note: {
              ...state.formCreate.product.note,
              open: action.payload
            }
          }
        }
      }
    case productActions.FORM_CREATE_INIT_INVENTORY_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          inventory: {
            ...state.formCreate.inventory,
            statusInit: action.payload
          }
        }
      }

    case productActions.FORM_CREATE_INIT_WEIGHT_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            weight: action.payload
          }
        }
      }
    case productActions.FORM_CREATE_INIT_NOTE_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            note: {
              ...state.formCreate.product.note,
              content: action.payload
            }
          }
        }
      }

    case productActions.FORM_CREATE_OPEN_MODAL_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            modal: {
              ...state.formCreate.product.modal,
              open: action?.payload,
            }
          }
        }
      }
    //  VALIDATE
    case productActions.VALIDATE_FORM_CREATE_NAME:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            validate: {
              ...state.formCreate.basic.validate,
              name: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_CODE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            validate: {
              ...state.formCreate.basic.validate,
              code: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_BASIC:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            validate: {
              ...state.formCreate.basic.validate,
              ...action.payload
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_BARCODE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            validate: {
              ...state.formCreate.basic.validate,
              barCode: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_GROUP_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          basic: {
            ...state.formCreate.basic,
            validate: {
              ...state.formCreate.basic.validate,
              group: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_UNIT_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            validate: {
              ...state.formCreate.product.validate,
              unit: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_WEIGHT_PRODUCT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            validate: {
              ...state.formCreate.product.validate,
              weight: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_INIT_INVENTORY:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          inventory: {
            ...state.formCreate.inventory,
            validate: {
              ...state.formCreate.inventory.validate,
              init: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_RETAIL:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            validate: {
              ...state.formCreate.price.validate,
              retail: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_DISCOUNT:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            validate: {
              ...state.formCreate.price.validate,
              discount: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_DISCOUNT_DATETIME:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            validate: {
              ...state.formCreate.price.validate,
              dateTime: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_LAST_ENTRY:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            validate: {
              ...state.formCreate.price.validate,
              lastEntry: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_COST:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            validate: {
              ...state.formCreate.price.validate,
              cost: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_QUANTITY:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          price: {
            ...state.formCreate.price,
            validate: {
              ...state.formCreate.price.validate,
              quantity: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.VALIDATE_FORM_CREATE_PRODUCT_IMAGE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          product: {
            ...state.formCreate.product,
            validate: {
              ...state.formCreate.product.validate,
              image: {
                status: action?.payload?.status,
                message: action?.payload?.message,
              }
            }
          }
        }
      }
    case productActions.FORM_CREATE_ZOOM_IN_IMAGE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          zoomIn: {
            ...state.formCreate.zoomIn,
            open: action?.payload
          }
        }
      }
    case productActions.FORM_CREATE_ZOOM_IN_IMAGE_LINK_ACTIVE:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          zoomIn: {
            ...state.formCreate.zoomIn,
            linkActive: action?.payload
          }
        }
      }

    //  MODAL
    case productActions.MODAL_CONFIRM_PRODUCT_STATUS:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmProductStatus: action?.payload
        }
      }
    case productActions.MODAL_CONFIRM_PRODUCT_DETAIL_STATUS:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmProductDetailStatus: action?.payload
        }
      }
    case productActions.MODAL_CONFIRM_PRODUCT_GROUP_STATUS:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmProductGroupStatus: action?.payload
        }
      }
    case productActions.DATA_CHANGE_PRODUCT_STATUS:
      return {
        ...state,
        modal: {
          ...state.modal,
          dataChangeProductStatus: action?.payload
        }
      }
    case productActions.DATA_CHANGE_PRODUCT__DETAIL_STATUS:
      return {
        ...state,
        modal: {
          ...state.modal,
          dataChangeProductDetailStatus: action?.payload
        }
      }
    case productActions.MODAL_CONFIRM_REMOVE_PRODUCT:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmRemoveProduct: action?.payload
        }
      }
    case productActions.DATA_REMOVE_PRODUCT_TBODY:
      return {
        ...state,
        modal: {
          ...state.modal,
          dataRemoveProduct: action?.payload
        }
      }
    case productActions.EDIT_PRODUCT_CONFIRM_POPUP_1:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmPopup1: action?.payload
        }
      }
    case productActions.EDIT_PRODUCT_CONFIRM_POPUP_2:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmPopup2: action?.payload
        }
      }
    case productActions.EDIT_PRODUCT_CONFIRM_POPUP_3:
      return {
        ...state,
        modal: {
          ...state.modal,
          confirmPopup3: action?.payload
        }
      }

    case productActions.FORM_CREATE_TOGGLE_MODAL_PRODUCT_PRICE_MANAGER:
      return {
        ...state,
        formCreate: {
          ...state.formCreate,
          version: {
            ...state.formCreate.version,
            modalPrice: {
              ...state.formCreate.version.modalPrice,
              open: action.payload
            }
          }
        }
      }
    case productActions.EDIT_PRODUCT_STATUS_CONFIRM:
      return {
        ...state,
        modal: {
          ...state.modal,
          statusConfirmEdit: {
            ...state.modal.statusConfirmEdit,
            warehouse_quantity: action?.payload?.warehouse_quantity,
            order: action?.payload?.order,
          }
        }
      }
    case productActions.EXPORT_FILE:
      return {
        ...state,
        export_file: action.payload
      }
    case productActions.IMPORT_FILE:
      return {
        ...state,
        import_file: action.payload,
      }
    case productActions.CHECK_DETAIL_TAB_ID:
      return {
        ...state,
        tab: {
          ...state.tab,
          tab_id: action.payload,
        }
      }
    case productActions.CHECK_LIST_TAB_ID:
      return {
        ...state,
        tab: {
          ...state.tab,
          list_id: action.payload
        }
      }
    //erorrs import data
    case 'NOTIFICATIONS_LIST_UPDATE':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: action.payload?.notifications?.list || [],
          total: action?.payload?.notifications?.total || 0
        },
      }
    default:
      throw new Error()
  }
}
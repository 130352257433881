import { useCallback, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { debounce } from '@mui/material'

// import { removeAcent } from '../../../../common/fieldText/_functions'
import {SaleContext} from "../provider/_context";
import {useSaleAction} from "../provider/_reducer";
import config from "../../../config";
import {sendRequestAuth} from "../../../api/api";
import {TAG_DELETE} from "../interface";

const useSaleFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { pageState, pageDispatch } = useContext(SaleContext)
    const { filter, table } = pageState

    // ===== ===== ===== ===== =====
    // SEARCH
    // ===== ===== ===== ===== =====
    const searchValue = filter.search.value
    const searchActive = filter.search.active
    const handleSearchChange = (e) => {
        if (e == ' ') e = ''
        const keyword = e.replace(/\s+/g, ' ') || ''
        pageDispatch({
            type: useSaleAction.FILTER_SEARCH_UPDATE,
            payload: { value:keyword },
        })
    }

    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
    // ===== ===== ===== ===== =====
    // ===== ===== ===== ===== =====
    // STATUS FILTER
    // ===== ===== ===== ===== =====
    const statusValue = filter?.status?.value
    const statusActiveValue = filter?.status?.activeValue

    const handleStatusChange = value => {
        pageDispatch({
            type: useSaleAction.FILTER_STATUS_VALUE_UPDATE,
            payload: value
        })
    }
    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
    // ===== ===== ===== ===== =====
    // SUBMIT FILTER
    // ===== ===== ===== ===== =====
    // Badge
    const otherFilterBadge = [
        !!statusActiveValue,
        !!searchActive
    ].filter(item => item === true).length

    const shouldShowResetAll = [
        !!statusActiveValue,
        !!searchActive
    ].includes(true)

    const canSubmitOtherFilter = [
        JSON.stringify(statusValue) !== JSON.stringify(statusActiveValue),
        JSON.stringify(filter?.search?.value) !== JSON.stringify(filter?.search?.active)
    ].includes(true)
    const queries = {
        per_page: table?.pagination?.amount || 20,
        keyword: filter?.search?.value || '',
        status: statusValue?.value || '',
        start: 0
    }


    const handleStatusActiveValue = status => pageDispatch({
        type: useSaleAction.FILTER_STATUS_ACTIVE_VALUE_UPDATE,
        payload: status
    })

    const fetchSaleByFilter = async (qs, opt) => {
        pageDispatch({
            type: useSaleAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/sale/sales${queryString}`),
        ])
        if (response[0]?.data?.success) {
            pageDispatch({
                type: useSaleAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = response[0]?.data
            pageDispatch({
                type: useSaleAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            pageDispatch({
                type: useSaleAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: 0,
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }
    }
    const applyOtherFilter = async () => {
        pageDispatch({
            type: useSaleAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        fetchSaleByFilter(queries)
            pageDispatch({type:useSaleAction.FILTER_STATUS_ACTIVE_VALUE_UPDATE,payload: statusValue})
            pageDispatch({type:useSaleAction.FILTER_SEARCH_ACTIVE_UPDATE,payload:{
                    active: filter?.search?.value
            }})

    }
    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

    const filterTagDelete =  (type) => {
        queries.status = ''
        pageDispatch({
            type: useSaleAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        let qs={...queries}
        switch (type) {
            case TAG_DELETE[0]:
                qs={...qs, keyword: ''}
                pageDispatch({
                    type: useSaleAction.FILTER_TAG_DELETE,
                    payload: type
                })
                break;
            case TAG_DELETE[1]:
                qs={...qs, status: ''}
                pageDispatch({
                    type: useSaleAction.FILTER_TAG_DELETE,
                    payload: type
                })
                break;
            default:
                break;
        }

        fetchSaleByFilter(qs)
    }
    const filterTagDeleteAll = ()=>{
        TAG_DELETE.forEach(each=> pageDispatch({
            type: useSaleAction.FILTER_TAG_DELETE,
            payload: each
        }))
        fetchSaleByFilter({...queries, keyword:'', status: ''})
    }
    return {
        queries: queries,
        search: {
            value: searchValue,
            active: filter?.search?.active,
            onChange: handleSearchChange,
        },
        statusFilter: {
            value: statusValue,
            statusValue: statusActiveValue,
            activeValue: statusActiveValue,
            onChange: handleStatusChange,
        },
        badge: {
            others: otherFilterBadge,
        },
        methods: {
            applyOtherFilter,
            filterTagDelete,
            filterTagDeleteAll
        },
        canSubmitOtherFilter,
        shouldShowResetAll,
    }
}

export default useSaleFilter
import React, {useCallback, useContext, useState} from 'react';
import {ProductContext} from "../provider/~context";
import {productActions} from "../provider/~action";
import {postData, sendRequestAuth} from "../../../api/api";
import {useTranslation} from "react-i18next";
import {DISPLAY_NAME_MENU} from "../../../const/display_name_menu";
import { uploadImageProduct } from '../../../api/url'
import { removeVietnameseTones } from '../../../util/checkPasswordVN'

const useCreateInfoProduct = () => {
  const { t } = useTranslation()
  const {pageState, pageDispatch} = useContext(ProductContext)
  const formInfoProduct = pageState?.formCreate?.product
  const formInfoProductValidate = formInfoProduct?.validate
  const zoomInImage = pageState?.formCreate?.zoomIn
  const [showDropdownWeight, setShowDropdownWeight] = useState(false)
  const [animationClose, setAnimationClose] = useState(false)

  const handleUpdateIMG = async val =>{
    let image = val.target.files[0]
    if(!!image) {
      const formData= new FormData()
      formData.append('image',image)
      formData.get('image')
      try{
        const res= await postData(uploadImageProduct(), formData)
        if(res?.data?.success) {
          pageDispatch({type: productActions.FORM_CREATE_CHANGE_IMAGE_PRODUCT,
            payload: {name: res?.data?.image, link: res?.data?.url}})
        }
        pageDispatch({
          type: productActions.VALIDATE_FORM_CREATE_PRODUCT_IMAGE,
          payload: {
            status: !res?.data?.success,
            message: !res?.data?.success ? t(DISPLAY_NAME_MENU.GENERAL.MAX_IMG_3MB) : ''}
        })
      }catch (e) {
        console.log(e)
      }
    }
  }

  const removeProductImg = () => {
    pageDispatch({type: productActions.FORM_CREATE_CHANGE_IMAGE_PRODUCT, payload: {name: '', link: ''}})
  }

  const handleShowNote = () => pageDispatch({type: productActions.FORM_CREATE_SHOW_NOTE_PRODUCT, payload: !pageState.formCreate.product.note.open})

  const handleChangeNote = value => {
    pageDispatch({type: productActions.FORM_CREATE_INIT_NOTE_PRODUCT, payload: value})
  }

  const handleZoomInImage = (bool, image) => {
    pageDispatch({type: productActions.FORM_CREATE_ZOOM_IN_IMAGE, payload: bool})
    pageDispatch({type: productActions.FORM_CREATE_ZOOM_IN_IMAGE_LINK_ACTIVE, payload: image})
  }

  const handleChangeSize = value => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_INFO_PRODUCT, payload: {size: value} })
  }

  const handleShortDescription = value => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_INFO_PRODUCT, payload: {shortDescription: value} })
  }

  const handleDescription = event => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_INFO_PRODUCT, payload: {description: event?.editor?.getData()} })
  }
  const handleInformation = event => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_INFORMATION_PRODUCT, payload: {information: event?.editor?.getData()} })
  }
  const handleSelectCover = value => {
    pageDispatch({ type: productActions.FORM_CREATE_CHANGE_INFO_PRODUCT, payload: {cover: value} })
  }
  return {
    value: {
      formInfoProduct,
      showDropdownWeight,
      zoomInImage,
    },
    functions: {
      onUploadIMG: handleUpdateIMG,
      onChangeSize: handleChangeSize,
      onSelectCover: handleSelectCover,
      removeProductImg,
      onShowNote: handleShowNote,
      onChangeNote: handleChangeNote,
      onChangeShortDescription: handleShortDescription,
      onChangeDescription: handleDescription,
      onChangeInformation: handleInformation,
      onZoomInImage: handleZoomInImage,

      animationClose,
    },
    validate: {
      formInfoProductValidate,
    }
  }
}

export default useCreateInfoProduct;
import { PageHeader } from "layouts/pageHeader";
import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {CREATE_SALE_BREARDCUM, EDIT_SALE_BREARDCUM} from "../../interfaces/constant";
import {CreateSaleContext} from "../../provider/_context";
import {useParams} from "react-router-dom";

const Index = () => {
    const {t} = useTranslation()
    const {saleId} = useParams()
    return (
        <StyledProductGroupHeader>
            <div className={"sale-header"} >
                <PageHeader
                    breadcrumbLinks={!!saleId ?  EDIT_SALE_BREARDCUM : CREATE_SALE_BREARDCUM}
                    breadcrumbTitle={ !!saleId ? 'Chỉnh sửa Sale quản lý' : 'Thêm mới Sale quản lý'}
                />
            </div>
        </StyledProductGroupHeader>
    )
}
export default Index;
const StyledProductGroupHeader = styled.div``
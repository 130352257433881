import useCreateInfoPrice from '../../../hooks/useCreateInfoPrice'
import {Grid} from '@mui/material'
import {Input} from '../../../../../common/form/input'
import {Text} from '../../../../../common/text'
import {THEME_SEMANTICS} from '../../../../../common/theme/_semantics'
import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {CategoryDateTimeRangePicker} from '../../../../../common/form/datePicker/_categoryDateTimeRangePicker'
import {DateRangePicker} from 'rsuite'
import {CategoryDatePicker} from "../../../../../common/form/datePicker";

const {beforeToday} = DateRangePicker;

const InfoPrice = () => {
   const {t} = useTranslation()
   const {dateTime, value, functions, validate} = useCreateInfoPrice()
   console.log('dateTime', dateTime.start > dateTime.end, dateTime)
   const dateTimeDefaultValue = [dateTime?.start, dateTime?.end]
   return (
      <StyledInfoPrice>
         <Grid container spacing={2}>
            <Grid xs={6} sm={6} md={6} lg={6} item>
               <div className="product-info-price__form-input">
                  <Input
                     label={
                        <>{t('Giá bán')} <Text color={THEME_SEMANTICS.failed}>*</Text></>
                     }
                     value={value?.formInfoPrice?.retail}
                     onChange={e => functions.onChangeRetail(e.target?.value)}
                     onBlur={_ => validate.onBlurRetail()}
                     validateText={validate?.formInfoPriceValidate?.retail?.message}
                     validateType={validate?.formInfoPriceValidate?.retail?.status ? 'danger' : 'success'}
                     placeholder={t('Vui lòng nhập giá bán')}
                     maxLength={11}
                     icon={
                        <Text as="u"
                              style={{color: '#7C88A6', position: 'absolute', right: 0}}>
                           ₫
                        </Text>
                     }
                  />
               </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} item>
               <div className="product-info-price__form-input">
                  <Input
                     label={
                        <div style={{marginLeft: 4}}>
                           {t('Số lượng mặc định')}
                        </div>
                     }
                     value={value?.formInfoPrice?.quantity}
                     onChange={e => functions.onChangeQuantity(e.target?.value)}
                     onBlur={e => validate.onBlurQuantity()}
                     placeholder={t('Vui lòng nhập giá khuyến mãi')}
                     validateText={validate?.formInfoPriceValidate?.quantity?.message}
                     validateType={validate?.formInfoPriceValidate?.quantity?.status ? 'danger' : 'success'}
                     maxLength={11}
                     icon={
                        <Text as="u" style={{color: '#7C88A6', position: 'absolute', right: -8}}>₫</Text>
                     }
                  />
               </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} item>
               <div className="product-info-price__form-input-1">
                  <Input
                     label={
                        <div style={{marginLeft: 4}}>
                           {t('% Chiết khấu')}
                        </div>
                     }
                     value={value?.formInfoPrice?.discount}
                     onChange={e => functions.onChangeDiscount(e.target?.value)}
                     onBlur={e => validate.onBlurDiscount()}
                     placeholder={t('Vui lòng nhập % chiết khấu')}
                     validateText={validate?.formInfoPriceValidate?.discount?.message}
                     validateType={validate?.formInfoPriceValidate?.discount?.status ? 'danger' : 'success'}
                     maxLength={11}
                     icon={
                        <Text as="u" style={{color: '#7C88A6', position: 'absolute', right: -8}}>₫</Text>
                     }
                  />
               </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} item>
               {/*<div className="product-info-price__form-input-1" style={{ margin: '50px 4px 0px 0px'}}>*/}
               {/*  <CategoryDateTimeRangePicker*/}
               {/*    className="store-upos-filter-form__input-wide"*/}
               {/*    categoryList={[{ id: 1, name: 'Thời gian chiết khấu', value: 'create' }]}*/}
               {/*    categoryWidth={148}*/}
               {/*    value={dateTime.value}*/}
               {/*    triggerDefault={dateTime.triggerDefault}*/}
               {/*    onChange={dateTime.onChange}*/}
               {/*    placement={'autoVerticalStart'}*/}
               {/*    datePickerProps={{*/}
               {/*      defaultValue: dateTimeDefaultValue,*/}
               {/*      disabledDate: beforeToday(),*/}
               {/*      placeholder: 'dd/mm/yyyy HH:mm ~ dd/mm/yyyy HH:mm',*/}
               {/*    }}*/}
               {/*    validateText={validate?.formInfoPriceValidate?.dateTime?.message}*/}
               {/*    validateType={validate?.formInfoPriceValidate?.dateTime?.status ? 'danger' : 'success'}*/}
               {/*  />*/}
               {/*</div>*/}
               <div className="product-group-content_group flex product-info-price__form-input-1"
                    style={{width: '100%'}}>
                  <div className="product-group-content_group-items" style={{width: 'calc(50% - 8px)'}}>
                     <label className={'input__label'} style={{margin: '0px 0px 8px', display: 'block'}}><Text>Ngày bắt
                        đầu</Text></label>
                     <CategoryDatePicker
                        trigger={dateTime.triggerDefault}
                        datePickerProps={{
                           defaultValue: dateTime.start,
                           cleanable: false,
                        }}
                        onChange={e => dateTime.onStartChange(e)}
                        format={'dd/MM/yyyy'}
                     />
                  </div>
                  <div className="product-group-content_group-items" style={{width: 'calc(50% - 8px)', marginLeft: 16}}>
                     <label className={'input__label'} style={{margin: '0px 0px 8px', display: 'block'}}><Text>Ngày kết
                        thúc</Text></label>
                     <CategoryDatePicker
                        trigger={dateTime.triggerDefault}
                        horizontal={'Start'}
                        datePickerProps={{
                           defaultValue: dateTime.end,
                           cleanable: false,
                           // disabled: !dateTime.start
                        }}
                        onChange={e => dateTime.onEndChange(e)}
                        format={'dd/MM/yyyy'}
                     />
                  </div>
                  <Text color={'red'} fontSize={12} style={{position: 'absolute', bottom: 2}}>{validate?.formInfoPriceValidate?.dateTime?.message}</Text>
               </div>
            </Grid>
         </Grid>
      </StyledInfoPrice>
   )
}

export default InfoPrice

export const StyledInfoPrice = styled.div`
  .product-info-price {
    &__form-input-1 {
      margin-top: 24px;
    }
  }
`
import React from "react";
import styled from 'styled-components'
import {Grid} from "@mui/material";
import {UserName} from "./_userName";
import {SchoolName} from "./_schoolName";
import {Phone} from "./_phone";
import {Email} from "./_email";
import {NamePerson} from "./_name";
import {LastName} from "./_lastName";
import {Identity} from "./_identity";
import {Address} from "./_address";
import {Password} from "./_password";
import {BirthDay} from "./_birthday";
import {Gender} from "./_gender";
import {StatusPerson} from "./_statusPerson";
import {useParams} from "react-router-dom";
import {Text} from "../../../../../../common/text";
import {useCreateSalePassword} from "../../hook/useCreateSalePassword";
import {ConfirmModalV2} from "../../../../../../Component/ConfirmModal";
import {Input} from "../../../../../../Component/Input";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";

export const InfoPerson = () => {
    const {saleId} = useParams()
    const {deleteConfirm} = useCreateSalePassword()
    return (
        <StyleInfoPerson>
            <Grid container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <UserName/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <SchoolName/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Phone/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Email/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <NamePerson/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <LastName/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <BirthDay/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Identity/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Gender/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Address/>
                </Grid>
                {
                    !!!saleId &&  <Grid xs={6} sm={6} md={6} lg={6} item>
                        <Password/>
                    </Grid>
                }
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <StatusPerson/>
                </Grid>
            </Grid>

            {
                !!saleId &&   <div
                    style={{marginTop: 20, width: 'fit-content', cursor:'pointer'}}
                    onClick={()=>deleteConfirm.methods?.onOpen(true)}
                >
                    <Text color={'#2150B7'} >Đặt lại mật khẩu</Text>
                </div>
            }
            {
                !!deleteConfirm?.open && <ConfirmModalV2
                    open={deleteConfirm?.open}
                    width={480}
                    body={
                        <BodyConfirm  />
                    }
                    footerButton={{
                        cancel:{
                            title: 'Hủy',
                            action: () => deleteConfirm.methods?.onOpen(false)
                        },
                        confirm:{
                            title: 'Lưu',
                            action: () => deleteConfirm.methods?.onSave(),
                            disabled: deleteConfirm?.status
                        }
                    }}
                />
            }
        </StyleInfoPerson>
    )
}
const BodyConfirm = ({fullName, userName})=>{
    const {deleteConfirm} = useCreateSalePassword()
    const {methods} = deleteConfirm
    return(
        <div className='create-sale_confirm-body'>
            <Text fontSize={20} fontWeight={600}>Đặt mật khẩu : {fullName}</Text>
            <div style={{margin: '24px 0'}}>
                <Text>Mật khẩu người dùng {userName} sẽ được thay đổi ngay sau khi bạn đặt mật khẩu.</Text>
            </div>
            <Input
                label={
                    <>
                        Mật khẩu  <Text color={THEME_SEMANTICS.failed}>*</Text>
                    </>
                }
                value={deleteConfirm?.value}
                onChange={e => methods.onChange(e.target?.value)}
                onBlur={e => methods.onBlur(e.target?.value)}
                validateText={deleteConfirm?.message}
                validateType={deleteConfirm?.status ? 'danger' : ''}
                placeholder={'Vui lòng nhập mật khẩu'}
                maxLength={32}
            />

        </div>
    )
}
const StyleInfoPerson = styled.div`
    .create-sale{
      &_confirm-body{
        
      }
    }
    .auto-complete__option-container{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

`
import {Grid} from '@mui/material'
import styled from 'styled-components'
import React, {useContext} from 'react'
import {ProductContext} from "../../../provider/~context";
import {Input} from "../../../../../common/form/input";
import useCategoryType from "../../../hooks/useCategoryType";

const InfoAmount = () => {

   const {pageState} = useContext(ProductContext)

   const {func} = useCategoryType()

   return (
      <StyledInfoBasic>
         <Grid container spacing={2} style={{marginTop: 6, paddingLeft: 16,}}>
            <Input value={pageState?.formCreate?.defaultQuantity}
                   onChange={(e) => func.handleDefaultQuantityChange(e.target.value)}
                   placeholder={'Nhập số lượng sản phẩm mặc định của khách hàng'} style={{width: '60%'}}/>
         </Grid>
      </StyledInfoBasic>
   )
}

export default InfoAmount

export const StyledInfoBasic = styled.div`
  .config-product {
      background: #E2EAF8;
      padding: 12px 48px;
      border-radius: 8px 8px 0px 0px;
      &[data-active=true]{
        background: #fff;
      }
    }
`

import styled from 'styled-components'

export const StyledRevenuePanels = styled.div`
  margin: 24px -8px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .order-panels {
    &__item {
      width: calc(40% - 12px);
      margin: 0 16px;
    }
  }
`

import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import { memo } from 'react'
import { OrderDateTime } from './_orderDateTime'
import { OrderSearch } from './_orderSearch'
import { OrderStatus } from './_orderStatus'
import { StyledOrderFilterForm } from './_styled'
import { useHistoryOrderFilterForm } from '../../../../hooks/useHistoryOrderFilterForm'
import { ProductSearch } from './_productSearch'
import {OrderCategory} from "./_category";
import {UtmSource} from "./_utmSource";
import {OrderType} from "./_orderType";

export const OrderHistoryFilterForm = memo(({ data,...props }) => {
  const { functions } = useHistoryOrderFilterForm(data)

  return (
    <StyledOrderFilterForm {...props}>
      <div
        className="order-filter-form__group order-filter-form__collapse"
        data-collapse={true}
      >
        <OrderSearch/>
        <ProductSearch/>
        <OrderCategory/>
        <UtmSource/>
        <OrderType/>
        <OrderStatus/>
        <OrderDateTime/>
        <div className="order-filter-form__input-wide flex">
          <Button
            size="md-"
            onClick={() => functions.applyCustomerOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
        </div>
      </div>
    </StyledOrderFilterForm>
  )
})

import React, {useEffect} from 'react';
import {GridLayout} from "../../layouts/gridLayout";
import {PageHeader} from "../../layouts/pageHeader";
import {CREATE_PRODUCT_CONSTANTS, EDIT_PRODUCT_CONSTANTS} from "./interfaces/~constants";
import styled from "styled-components";
import ActionFormBrnList from "./createProduct/conponents/actionFormBrnList";
import InfoBasic from "./createProduct/conponents/infoBasic";
import {ProductProvider} from "./provider";
import useProduct from "./hooks/useProduct";
import {useTranslation} from "react-i18next";
import InfoAmount from "./createProduct/conponents/infoAmount";
import ConfigProduct from "./createProduct/conponents/configProduct";

const ProductOfCustomer = ({...props}) => {
  const { t } = useTranslation()
  const {fetch, provider} = useProduct()
  const {state, dispatch} = provider

  useEffect(() => {
    fetch.origin()
  }, [])

  return (
    <ProductProvider value={{pageState: state, pageDispatch: dispatch}}>
      <StyledProductOfCustomer>
          <GridLayout
            {...props}
            header={
              <PageHeader
                breadcrumbLinks={CREATE_PRODUCT_CONSTANTS.header.breadcrumb}
                breadcrumbTitle={"Cấu hình sản phẩm bán"}
              />
            }
            grid={[
              {
                width: 100,
                sections: [
                  {
                    title: 'Thông tin khách hàng',
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoBasic />,
                    },
                  },
                  {
                    title: 'Số lượng sản phẩm mặc định',
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <InfoAmount />,
                    },
                  },
                  {
                    title: 'Loại cấu hình sản phẩm bán',
                    props: {
                      style: {
                        position: 'relative',
                        padding: 24
                      },
                      children: <ConfigProduct />,
                    },
                  },
                  // {
                  //   type: 'sticky-bottom-transparent',
                  //   props: {
                  //     style: {
                  //       position: 'sticky',
                  //       bottom: -44,
                  //       marginBottom: 0,
                  //       zIndex: 10,
                  //       padding: "12px 24px 0 12px"
                  //     },
                  //     children: <ActionFormBrnList />,
                  //   },
                  // },
                ],
                props: {style: {position: 'relative'}},
              },
            ]}
            data-model="container"
          />
    </StyledProductOfCustomer>
    </ProductProvider>
  );
};

export default ProductOfCustomer;

export const StyledProductOfCustomer = styled.div`
  .grid-layout {
    &__header {
      & .breadcrumb__title h1 {
        line-height: 140% !important;
        min-width: 13.1875rem !important;
        height: 2.125rem !important;
      }
    }
  }
`
import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import useOrderTHead from 'Pages/orderBook/hooks/useOrderTHead'

export const OrderTHead = ({ ...props }) => {
  const { globalLoading } = useOrderTHead()

  return (
    <>
    <Tr {...props} type="tHead">
      <Th className="order-table__cell"> </Th>
      <Th className="order-table__cell">Mã đơn hàng</Th>
      <Th className="order-table__cell">Số lượng</Th>
      <Th className="order-table__cell">Tổng tiền</Th>
      <Th className="order-table__cell">Chiết khấu</Th>
      <Th className="order-table__cell">Khách hàng</Th>
      <Th className="order-table__cell">Nhân viên Sale</Th>
      <Th className="order-table__cell">Nguồn</Th>
      <Th className="order-table__cell">Đơn SAP/Nhà PP</Th>
      <Th className="order-table__cell">Trạng thái</Th>
      <Th
        className="order-table__cell"
        style={{ display: 'flex' }}
      />
    </Tr>

  {
    globalLoading.value && (
      <div className="order-table__loading">
        <img src="/img/loading.gif"/>
      </div>
    )
  }
</>
)
}

import {Button} from 'common/button'
import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {formatMoney} from '../../../../util/functionUtil'
import {Tooltip as TooltipV2} from 'common/tooltipv2'
import {checkPermission} from '../../../../Component/NavBar/until'
import {fDateTimeCustom, fDateTimeDMY} from '../../../../util/formatTime'
import {useEditOrderModal} from "../../hooks/useEditOrderModal";
import {fNumber} from "../../../../util/formatNumber";

export const RowTabDetail = ({data, rowData, ...props}) => {
  const {row, cell} = rowData
  const [exportUrl, setExportUrl] = useState('#')
  const exportLink = useRef()
  const validExportPermission = checkPermission('order', 'export')
  const [showPermission, setShowPermission] = useState(false)
  const {methods} = useEditOrderModal()
  const handleEditClick = () => {
    // check đơn hàng tự do - đơn hàng sap
    if(!!data?.card_code){
      // Đơn hàng sap
      methods.getAddressList(data?.card_code)
      methods.getContactList(data?.card_code)

    }else{
      // đơn hàng tự do
      methods.getSchoolList(rowData?.detail?.active)

    }
    methods.updateEditOrderModal(rowData?.detail?.active, true)
  }
  const handleExportClick = () => {
    if (validExportPermission) {
      const exportURLData = row.onExportOrderExcel()
      exportURLData.then(res => {
        if (res && res !== '#') setExportUrl(res)
      })
    } else setShowPermission(true)
  }

  useEffect(() => {
    if (exportUrl && exportUrl !== '#') {
      if (exportLink?.current) exportLink.current.click()
    }
  }, [exportUrl])
  return (
    <StyledRowTabDetail>
      {/*{showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}*/}
      <div className="row-tab-detail__content">
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            Thông tin đơn hàng
          </Text>
          {!!data?.created_at && (
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Ngày mua hàng
              </Text>
              <Text
              >
                {fDateTimeCustom(data?.created_at, {format: 'HH:mm dd/MM/yyyy'}) || '---'}
              </Text>
            </div>
          )}
          {!!data?.updated_at && (
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Cập nhật lần cuối
              </Text>
              <Text>
                {fDateTimeCustom(data?.updated_at, {format: 'HH:mm dd/MM/yyyy'}) || '---'}</Text>
            </div>
          )}

          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Ghi chú
            </Text>
            <Text>
              {data?.order_notes || '---'}</Text>
          </div>
        </div>
        {(!!data?.customer_phone || !!data?.customer_address) && (
          <div className="row-tab-detail__content-group">
            <Text as="h4" fontSize={16} lineHeight={22}>
              Thông tin liên hệ
            </Text>
            {!!data?.customer_name && (
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Tên khách hàng</Text>
                <Text color={THEME_SEMANTICS.delivering}>
                  {data?.customer_name}
                </Text>
              </div>
            )}
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">Mã người liên hệ</Text>
              <Text as="strong">
                {!!data?.cntct_code ? data.cntct_code : '---'}
              </Text>
            </div>
            {!!data?.customer_phone && (
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Người liên hệ</Text>
                <Text as="strong">
                  {data?.card_code ? data?.contact_name + ' - ' + data?.customer_phone : data?.customer_phone}
                </Text>
              </div>
            )}
          </div>
        )}
        {!!data?.customer_address && (
          data?.card_code ? (
              <div className="row-tab-detail__content-group">
                <Text as="h4" fontSize={16} lineHeight={22}>
                  Thông tin địa chỉ
                </Text>
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Mã địa chỉ:</Text>
                  <Text>{!!data?.ship_to_code && +data?.ship_to_code !== 0 ? data?.ship_to_code : '---'}</Text>
                </div>
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Địa chỉ:</Text>
                  <TooltipV2 title={data?.customer_address} className="order-table__tooltipV2">
                    <Text>{data?.customer_address || '---'}</Text>
                  </TooltipV2>
                </div>
              </div>
            ) :
            <div className="row-tab-detail__content-group">
              <Text as="h4" fontSize={16} lineHeight={22}>
                Thông tin địa chỉ
              </Text>
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Trường học:</Text>
                <TooltipV2 title={data?.customer_school} className="order-table__tooltipV2">
                  <Text>{data?.customer_school || '---'}</Text>
                </TooltipV2>
              </div>
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Địa chỉ:</Text>
                <TooltipV2 title={data?.customer_address} className="order-table__tooltipV2">
                  <Text>{data?.customer_address || '---'}</Text>
                </TooltipV2>
              </div>
            </div>
        )}

        <div style={{position: 'absolute', top: 0, right: 0}}>
          {['new', 'processing'].includes(data?.order_status) && (
            <Button appearance="secondary" size="sm" onClick={() => handleEditClick()}>Sửa</Button>
          )}
          <Button appearance="secondary" size="sm" style={{marginLeft: 8}} onClick={handleExportClick}>
            Xuất Excel
          </Button>
          <a ref={exportLink} href={exportUrl} style={{display: 'none'}}></a>
        </div>
      </div>
      <div className="row-tab-detail__info-table">
        <div>
          <Text as="b" fontSize={16} lineHeight={22}>
            Thông tin sản phẩm{' '}
          </Text>
          <Text>
            (Tổng số lượng:{' '}
            {fNumber(data?.total_quantity || 0)}
            {/* {Array.isArray(data?.details) ? data.details.length : '0'} */}
            )
          </Text>
        </div>
      </div>
      {Array.isArray(data?.details) && data.details.length > 0 && (
        <div className="row-tab-detail__table">
          <div className="row-tab-detail__thead">
            <div className="row-tab-detail__tr">
              <div className="row-tab-detail__th">Mã sản phẩm (ISBN/ID SAP)</div>
              <div className="row-tab-detail__th">Tên sản phẩm</div>
              <div className="row-tab-detail__th">Đơn giá</div>
              <div className="row-tab-detail__th">Số lượng</div>
              <div className="row-tab-detail__th">Chiết khấu</div>
              <div className="row-tab-detail__th">Thành tiền</div>
            </div>
          </div>
          <div className="row-tab-detail__tbody">
            {data.details.map(item => (
              <div key={item.product_id} className="row-tab-detail__tr">
                <div
                  className="row-tab-detail__td"
                  title={item?.product_sku}
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item?.product_sku || '---'}
                </div>
                <div className="row-tab-detail__td">
                  <Text
                    color={THEME_SEMANTICS.delivering}
                    style={{cursor: 'pointer'}}
                  >
                    {item?.product_name || '---'}
                  </Text>
                  <span style={{cursor: 'pointer'}}></span>
                </div>
                <div className="row-tab-detail__td">
                  {formatMoney(item?.price)}
                </div>
                <div className="row-tab-detail__td">
                  {item?.quantity
                    ? Number(item.quantity) < 10
                      ? `0${item.quantity}`
                      : fNumber(item.quantity)
                    : '---'}
                </div>
                <div className="row-tab-detail__td">
                  {formatMoney(Number(item?.discount_value) * Number(item?.quantity || 1))}
                  {` ( -${item?.discount}% )`}
                </div>
                <div className="row-tab-detail__td">
                  {formatMoney(Number(item?.price) * Number(item?.quantity || 1))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="row-tab-detail__result">
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Số lượng</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{fNumber(data?.total_quantity || 0)}</b>
          </div>
        </div>
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Chiết khấu</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.total_discount)}</b>
          </div>
        </div>
        <div className="row-tab-detail__result-item">
          <div className="row-tab-detail__result-label">
            <b>Tổng tiền</b>
          </div>
          <div className="row-tab-detail__result-value">
            <b>{formatMoney(+data?.total_amount)}</b>
          </div>
        </div>
      </div>
    </StyledRowTabDetail>
  )
}

const StyledRowTabDetail = styled.div`
  .row-tab-detail {
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
    }

    &__content-group {
      width: calc(100% / 3 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3), &:nth-child(4){
        width: 150px;
        text-align:center;
      }
      &:nth-child(5){
        width: 200px;
        text-align:center;
      }
      &:nth-child(6) {
        width: 200px;
        text-align:center;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 13%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3), &:nth-child(4) {
        width: 150px;
        text-align:center;
      } 
      &:nth-child(5) {
        width: 200px;
        text-align:center;
      }
      &:nth-child(6) {
        width: 200px;
        text-align:center;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
`

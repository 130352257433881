import React, {useContext, useState} from 'react';
import {Button} from "../../../../common/button";
import {PATH} from "../../../../const/path";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DISPLAY_NAME_MENU} from "../../../../const/display_name_menu";
import {Loading} from '../../../../common/loading'
import useCategoryType from "../../hooks/useCategoryType";
import {ConfirmConfigModal} from "../../components/_confirmConfigModal";
import {Text} from "../../../../common/text";
import {ProductContext} from "../../provider/~context";

const ActionFormBrnList = ({type, ...props}) => {
   const {pageState} = useContext(ProductContext)
   const {customer} = pageState.formCreate
   const {t} = useTranslation()
   const {func} = useCategoryType()
   const navigate = useNavigate()
   const [confirmConfigModalData, setConfirmConfigModalData] = useState(null)

   const handleActionConfigChange = action => {
      switch (action) {
         case 'all':
            setConfirmConfigModalData({
               content: (
                  <>
                     <Text as={'p'}> Bạn có chắc chắn muốn cấu hình bán <b>tất cả sản phẩm</b> cho khách
                        hàng <b>{customer?.card_name}</b> không?</Text>
                     <Text as={'b'}>Lưu ý:</Text>
                     <ul>
                        <li>
                           <Text as={'p'}> &bull; Khi cấu hình bán tất cả sản phẩm, tất cả sản sản phẩm sẽ hiển thị trên
                              DTP
                              Books cho khách hàng duyệt và đặt hàng.</Text>
                        </li>
                     </ul>
                  </>
               ),
               title: 'Xác nhận cấu hình sản phẩm bán',
               onClose: () => setConfirmConfigModalData(null),
               onSubmit: () => {
                  func.updateSettingProductSale(action)
                  setConfirmConfigModalData(null)
               },
            })
            break

         case 'category':
            setConfirmConfigModalData({
               content: (
                  <>
                     <Text as={'p'}> Bạn có chắc chắn muốn cấu hình <b>bán sản phẩm theo danh mục</b> cho khách
                        hàng <b>{customer?.card_name}</b> không?</Text>
                     <Text as={'b'}>Lưu ý:</Text>
                     <ul>
                        <li>
                           <Text as={'p'}> &bull; Khi cấu hình bán sản phẩm theo danh mục, chỉ những danh mục được cấu
                              hình mới
                              được hiển thị trên DTP Books cho khách hàng duyệt và đặt hàng.</Text>
                        </li>
                        <li>
                           <Text as={'p'}> &bull; Đối với các danh mục thêm mới, hệ thống sẽ tự động thêm vào danh mục
                              bán cho
                              khách hàng.</Text>
                        </li>
                     </ul>
                  </>
               ),
               title: 'Xác nhận cấu hình sản phẩm bán',
               onClose: () => setConfirmConfigModalData(null),
               onSubmit: () => {
                  func.updateSettingProductSale(action)
                  setConfirmConfigModalData(null)
               },
            })
            break
         case 'product':
            setConfirmConfigModalData({
               content: (
                  <>
                     <Text as={'p'}> Bạn có chắc chắn muốn cấu hình <b>bán sản phẩm theo sản phẩm</b> cho khách
                        hàng <b>{customer?.card_name}</b> không?</Text>
                     <Text as={'b'}>Lưu ý:</Text>
                     <ul>
                        <li>
                           <Text as={'p'}> &bull; Khi cấu hình bán sản phẩm theo sản phẩm, chỉ những sản phẩm được cấu
                              hình tại
                              mục <b>Danh sách sản phẩm bán mới</b> được hiển thị trên DTP Books cho khách hàng duyệt và
                              đặt hàng.</Text>
                        </li>
                        <li>
                           <Text as={'p'}> &bull; Đối với các sản phẩm thêm mới, hệ thống sẽ tự động thêm vào sản phẩm
                              bán cho
                              khách hàng.</Text>
                        </li>
                     </ul>
                  </>
               ),
               title: 'Xác nhận cấu hình sản phẩm bán',
               onClose: () => setConfirmConfigModalData(null),
               onSubmit: () => {
                  func.updateSettingProductSale(action)
                  setConfirmConfigModalData(null)
               },
            })
            break

         default:
            break
      }
   }

   return (
      <div style={{textAlign: 'right'}}>
         <Button appearance="ghost"
                 onClick={() => navigate(PATH.CUSTOMER)}
                 style={{width: 74}}
         >
            Hủy
         </Button>
         <Button
            onClick={() => handleActionConfigChange(type)}
            style={{marginLeft: 12, padding: '0 16px', width: 108}}
            // disabled={value.canSaveProduct}
         >
            {t(DISPLAY_NAME_MENU.GENERAL.SAVE)}
         </Button>
         {/*{!!value.loading && <Loading/>}*/}

         {!!confirmConfigModalData && (
            <ConfirmConfigModal {...confirmConfigModalData} />
         )}
      </div>
   )
}

export default ActionFormBrnList;
import { sendRequestAuth } from 'api/api'
import { removeAcent } from 'common/fieldText/_functions'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import {
  CUSTOMER_FILTER_TAG_FIELDS,
} from '../interfaces/_constants'
import { CustomerContext } from '../provider/_context'
import {customerActions, useOrderHistoryAction, useProductAction} from '../provider/_reducer'
import { customerInitialState } from '../provider/_initState'
import { useSearchParams } from 'react-router-dom'

export const useHistoryOrderFilterForm = (data) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageState, pageDispatch } = useContext(CustomerContext)
  const { table } = pageState
  const { orderHistory } = table
  const { filter } = orderHistory

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // DATE TIME
  // ===== ===== ===== ===== =====
  const { afterToday } = DateRangePicker
  const dateTimeActiveValue = filter.dateTime.activeValue
  const dateTimeEnd = filter.dateTime.end
  const dateTimeStart = filter.dateTime.start
  const dateTimeDefaultValue = [dateTimeStart, dateTimeEnd]
  const dateTimeType = filter.dateTime.type
  const dateTimeValue = filter.dateTime.value
  const dateTimeTrigger = filter.dateTime.trigger

  const handleDateTimeChange = data =>
    pageDispatch({
      type: customerActions.FILTER_HISTORY_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })

  // ===== ===== ===== ===== =====
  // Payment Method
  // ===== ===== ===== ===== =====
  const paymentMethodActiveValue = filter.paymentMethod.activeValue
  const paymentMethodKeyword = filter.paymentMethod.keyword
  const paymentMethodList = filter.paymentMethod.list
  const paymentMethodListOrigin = filter.paymentMethod.listOrigin
  const paymentMethodValue = filter.paymentMethod.value

  const handlePaymentMethodChange = data =>
    pageDispatch({
      type: customerActions.FILTER_HISTORY_PAYMENT_METHOD_UPDATE,
      payload: { value: data },
    })

  const handlePaymentMethodKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const paymentMethodListData = paymentMethodListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_HISTORY_PAYMENT_METHOD_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: paymentMethodListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Order Type
  // ===== ===== ===== ===== =====
  const orderTypeActiveValue = filter.orderType.activeValue
  const orderTypeKeyword = filter.orderType.keyword
  const orderTypeList = filter.orderType.list
  const orderTypeListOrigin = filter.orderType.listOrigin
  const orderTypeValue = filter.orderType.value

  const handleOrderTypeChange = data =>
     pageDispatch({
       type: customerActions.FILTER_HISTORY_ORDER_TYPE_UPDATE,
       payload: { value: data },
     })

  const handleOrderTypeKeywordChange = data => {
    const formatDataValue = data?.value
       ? removeAcent(data?.value?.toLowerCase())
       : ''

    const orderTypeListData = orderTypeListOrigin.filter(item => {
      const formatNameItem = item?.name
         ? removeAcent(item.name.toLowerCase())
         : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_HISTORY_ORDER_TYPE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: orderTypeListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // Utm Source
  // ===== ===== ===== ===== =====
  const utmSourceActiveValue = filter.utmSource.activeValue
  const utmSourceKeyword = filter.utmSource.keyword
  const utmSourceList = filter.utmSource.list
  const utmSourceListOrigin = filter.utmSource.listOrigin
  const utmSourceValue = filter.utmSource.value

  const handleUtmSourceChange = data =>{
    pageDispatch({
      type: customerActions.FILTER_HISTORY_UTM_SOURCE_UPDATE,
      payload: { value: data },
    })

  }

  const handleUtmSourceKeywordChange = data => {
    const formatDataValue = data?.value
       ? removeAcent(data?.value?.toLowerCase())
       : ''

    const utmSourceListData = utmSourceListOrigin.filter(item => {
      const formatNameItem = item?.name
         ? removeAcent(item.name.toLowerCase())
         : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_HISTORY_UTM_SOURCE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: utmSourceListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Payment Status
  // ===== ===== ===== ===== =====
  const paymentStatusActiveValue = filter.paymentStatus.activeValue
  const paymentStatusKeyword = filter.paymentStatus.keyword
  const paymentStatusList = filter.paymentStatus.list
  const paymentStatusListOrigin = filter.paymentStatus.listOrigin
  const paymentStatusValue = filter.paymentStatus.value

  const handleSourceChange = data =>
    pageDispatch({
      type: customerActions.FILTER_HISTORY_PAYMENT_STATUS_UPDATE,
      payload: { value: data },
    })

  const handleSourceKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const paymentStatusListData = paymentStatusListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_HISTORY_PAYMENT_STATUS_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: paymentStatusListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== // ===== ===== ===== ===== =====


// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // CATEGORY FILTER
  // ===== ===== ===== ===== =====
  const categoryList = filter?.category?.list
  const categoryListOrigin = filter?.category?.listOrigin
  const categoryValue = filter?.category?.value
  const categoryActiveValue = filter?.category?.activeValue

  const handleCategoryChange = value => {
    pageDispatch({
      type: useOrderHistoryAction.FILTER_CATEGORY_VALUE_UPDATE,
      payload: value
    })
  }

  const handleCategoryKeywordChange = data => {
    const formatDataValue = data?.value
       ? removeAcent(data?.value?.toLowerCase())
       : ''

    const categoryListData = categoryListOrigin.filter(item => {
      const formatNameItem = item?.title
         ? removeAcent(item?.title.toLowerCase())
         : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    pageDispatch({
      type: useOrderHistoryAction.FILTER_CATEGORY_LIST_UPDATE,
      payload: categoryListData
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // Product type
  // ===== ===== ===== ===== =====
  const productTypeActiveValue = filter.paymentStatus.activeValue
  const productTypeKeyword = filter.productType.keyword
  const productTypeList = filter.productType.list
  const productTypeListOrigin = filter.productType.listOrigin
  const productTypeValue = filter.productType.value

  const handleProductTypeChange = data => {
    pageDispatch({
      type: customerActions.FILTER_HISTORY_PRODUCT_TYPE_UPDATE,
      payload: { value: data },
    })
  }

  const handleProductTypeKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const productTypeListData = productTypeListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_HISTORY_PRODUCT_TYPE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: productTypeListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Customer Status
  // ===== ===== ===== ===== =====
  const orderStatusActiveValue = filter.orderStatus.activeValue
  const orderStatusKeyword = filter.orderStatus.keyword
  const orderStatusList = filter.orderStatus.list
  const orderStatusListOrigin = filter.orderStatus.listOrigin
  const orderStatusValue = filter.orderStatus.value

  const handleWarehouseChange = data =>
    pageDispatch({
      type: customerActions.FILTER_HISTORY_ORDER_STATUS_UPDATE,
      payload: { value: data },
    })

  const handleWarehouseKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const orderStatusListData = orderStatusListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_HISTORY_ORDER_STATUS_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: orderStatusListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // ORDER ID
  // ===== ===== ===== ===== =====
  const orderIdValue = filter.orderId.value
  const orderIdActiveValue = filter.orderId.activeValue

  const handleCustomerIdChange = value => {
    if (value === ' ') value = ''
    pageDispatch({
      type: customerActions.FILTER_HISTORY_ORDER_ID_UPDATE,
      payload: { value: value },
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // PRODUCT
  // ===== ===== ===== ===== =====
  const productActiveValue = filter.product.activeValue
  const productValue = filter.product.value

  const handleProductChange = value => {
    if (value === ' ') value = ''
    pageDispatch({
      type: customerActions.FILTER_HISTORY_PRODUCT_UPDATE,
      payload: { value: value },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  const canSubmitOtherFilter = [
    dateTimeActiveValue.value !== dateTimeValue ||
    JSON.stringify(dateTimeActiveValue.type) !== JSON.stringify(dateTimeType),
    JSON.stringify(paymentMethodActiveValue) !==
    JSON.stringify(paymentMethodValue),
    JSON.stringify(paymentStatusActiveValue) !== JSON.stringify(paymentStatusValue),
    JSON.stringify(orderStatusActiveValue) !== JSON.stringify(orderStatusValue),
    JSON.stringify(productActiveValue) !== JSON.stringify(productValue),
    JSON.stringify(orderIdActiveValue) !== JSON.stringify(orderIdValue),
    JSON.stringify(productTypeActiveValue) !== JSON.stringify(productTypeValue),
  ].includes(true)

  const queries = {
    date_type: dateTimeActiveValue?.type?.value || '',
    start_date:
      dateTimeActiveValue?.start && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[0])
        : '',
    end_date:
      dateTimeActiveValue?.end && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[1])
        : '',
    product_name: productActiveValue,
    category_id: categoryValue?.id || '',
    type: productTypeActiveValue?.id || '',
    order_status: orderStatusActiveValue?.value || '',
    utm_source: utmSourceActiveValue?.value || '',
    order_type: orderTypeActiveValue?.value || '',
    payment_status: paymentStatusActiveValue?.value || '',
    payment_method: paymentMethodActiveValue?.value || '',
    order_id: orderIdActiveValue || '',
    per_page: table?.pagination?.amount || 20,
    start: 0,
  }

  const applyCustomerOtherFilter = async () => {
    const collection = {
      ...queries,
      date_type: dateTimeType?.value || '',
      type: productTypeValue?.id || '',
      start_date:
        dateTimeStart && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[0])
          : '',
      end_date:
        dateTimeEnd && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[1])
          : '',
      order_status: orderStatusValue?.value || '',
      utm_source: utmSourceValue?.value || '',
      order_type: orderTypeValue?.value || '',
      payment_method: paymentMethodValue?.value || '',
      payment_status: paymentStatusValue?.value || '',
      product_name: productValue,
      order_id: orderIdValue || '',
    }
    fetchCustomerByFilter(collection, { forceLoading: true })
  }

  const fetchCustomerByFilter = async (qs, opt) => {
    setSearchParams('')
    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: customerActions.HISTORY_TABLE_DISPLAY_LOADING_UPDATE,
        payload: true,
      })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/customer/detail/${data?.card_code}/orders${queryString}&type=1`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: customerActions.OTHER_FILTER_HISTORY_APPLY,
        payload: {
          list: response[0].data.data
        },
      })
      pageDispatch({
        type: customerActions.HISTORY_TABLE_DISPLAY_LOADING_UPDATE,
        payload: false,
      })
    }
    pageDispatch({
      type: customerActions.HISTORY_TABLE_DISPLAY_LOADING_UPDATE,
      payload: false,
    })
  }

  const filterTagDeleteAll = isSoft => {
    CUSTOMER_FILTER_TAG_FIELDS.forEach(item =>
      pageDispatch({
        type: customerActions.TAG_FILTER_HISTORY_DELETE,
        payload: { type: item },
      }),
    )

    pageDispatch({
      type: customerActions.FILTER_HISTORY_DATE_TIME_TRIGGER_UPDATE,
      payload: {
        trigger: dateTimeTrigger === null ? true : !dateTimeTrigger,
      },
    })

    if (isSoft) return

    const collection = {
      ...queries,
      date_type: 'created',
      start_date: '',
      end_date: '',
      user_id: '',
      payment_method: '',
      product_name: '',
      type: '',
      payment_status: '',
      order_status: '',
      utm_source: '',
      order_type: '',
      order_id: '',
    }

    fetchCustomerByFilter(collection, { forceLoading: true })
  }

  return {
    dateTime: {
      activeValue: dateTimeActiveValue,
      defaultValue: dateTimeDefaultValue,
      disabledDate: afterToday(),
      triggerDefault: dateTimeTrigger,
      value: dateTimeValue,
      onChange: handleDateTimeChange,
    },
    product: {
      activeValue: productActiveValue,
      value: productValue,
      onChange: handleProductChange,
    },
    orderId: {
      value: orderIdValue,
      activeValue: orderIdActiveValue,
      onChange: handleCustomerIdChange,
    },
    paymentMethod: {
      activeValue: paymentMethodActiveValue,
      keyword: paymentMethodKeyword,
      list: paymentMethodList,
      value: paymentMethodValue,
      onChange: handlePaymentMethodChange,
      onKeywordChange: handlePaymentMethodKeywordChange,
    },
    paymentStatus: {
      activeValue: paymentStatusActiveValue,
      keyword: paymentStatusKeyword,
      list: paymentStatusList,
      value: paymentStatusValue,
      onChange: handleSourceChange,
      onKeywordChange: handleSourceKeywordChange,
    },
    productType: {
      activeValue: productTypeActiveValue,
      keyword: productTypeKeyword,
      list: productTypeList,
      value: productTypeValue,
      onChange: handleProductTypeChange,
      onKeywordChange: handleProductTypeKeywordChange,
    },
    categoryFilter: {
      value: categoryValue,
      activeValue: categoryActiveValue,
      list: categoryList,
      onChange: handleCategoryChange,
      onKeywordChange: handleCategoryKeywordChange
    },
    orderType: {
      activeValue: orderTypeActiveValue,
      keyword: orderTypeKeyword,
      list: orderTypeList,
      value: orderTypeValue,
      onChange: handleOrderTypeChange,
      onKeywordChange: handleOrderTypeKeywordChange,
    },
    utmSource: {
      activeValue: utmSourceActiveValue,
      keyword: utmSourceKeyword,
      list: utmSourceList,
      value: utmSourceValue,
      onChange: handleUtmSourceChange,
      onKeywordChange: handleUtmSourceKeywordChange,
    },
    orderStatus: {
      activeValue: orderStatusActiveValue,
      keyword: orderStatusKeyword,
      list: orderStatusList,
      value: orderStatusValue,
      onChange: handleWarehouseChange,
      onKeywordChange: handleWarehouseKeywordChange,
    },
    canSubmitOtherFilter,
    queries,
    functions: {
      hasFilter: () => [
        JSON.stringify(dateTimeActiveValue?.value) !==
        JSON.stringify(customerInitialState.filter.dateTime.activeValue?.value),
        !!paymentMethodActiveValue?.name,
        !!paymentStatusActiveValue?.name,
        !!orderStatusActiveValue?.name,
        !!orderIdActiveValue,
      ].includes(true),
      applyCustomerOtherFilter,
      refresh: () =>
        fetchCustomerByFilter(
          {
            ...queries,
            type: 1,
            start: table.pagination.active * table.pagination.amount || 0,
          },
          { activePage: table.pagination.active, forceLoading: true },
        ),
      fetchUpdateData: () =>
        fetchCustomerByFilter(
          {
            ...queries,
            type: 1,
            start: table.pagination.active * table.pagination.amount,
          },
          { activePage: table.pagination.active, notClearDetail: true },
        ),
      filterTagDeleteAll,
    },
  }
}

import {sendRequestAuth} from 'api/api'
import {Button} from 'common/button'
import config from 'config'
import useCustomerFilterForm from 'Pages/customer/hooks/useCustomerFilterForm'
import {CustomerContext} from 'Pages/customer/provider/_context'
import {customerActions} from 'Pages/customer/provider/_reducer'
import {memo, useContext, useEffect} from 'react'
import {CustomerTags} from '../customerTags'
import {CustomerDateTime} from './_customerDateTime'
import {StyledCustomerFilterForm} from './_styled'
import {CustomerInfo} from './_customerInfo'
import {CustomerTeacher} from './_customerTeacher'
import {GLOBAL_ICONS} from '../../../../interface/icon'
import {CustomerStatus} from './_status'
import {CustomerAssignDateTime} from "./_customerAssignDateTime";
import {CustomerSyncSap} from "./_customerSyncSap";

export const CustomerFilterForm = memo(({...props}) => {
   const {canSubmitOtherFilter, functions} = useCustomerFilterForm()

   const {pageDispatch} = useContext(CustomerContext)

   useEffect(() => {
      const fetchData = async () => {
         const response = await Promise.all([
            sendRequestAuth(
               'get',
               `${
                  config.API
               }/order/filter/teachers?principal_id=`,
            )
         ])
         pageDispatch({
            type: customerActions.FILTER_ORIGIN_DATA_UPDATE,
            payload: {
               teacher: {
                  list: response[0].data.map(item => ({
                     data: item || null,
                     name: ((!!item?.last_name ? (item?.last_name + ' ') : '') + item?.name) || '',
                     value: item?.id || '',
                  })),
               },
            },
         })

         pageDispatch({
            type: customerActions.MODAL_TEACHER_LIST_UPDATE,
            payload: {
               teacher: {
                  list: response[0].data.map(item => ({
                     data: item || null,
                     name: ((!!item?.last_name ? (item?.last_name + ' ') : '') + item?.name) || '',
                     value: item?.id || '',
                  })),
               }
            },
         })
      }
      fetchData()
   }, [])

   return (
      <StyledCustomerFilterForm {...props}>
         <div
            className="customer-filter-form__group customer-filter-form__collapse"
            data-collapse={true}
         >
            <CustomerInfo/>
            <CustomerStatus/>
            <CustomerDateTime/>
            <CustomerTeacher/>
            <CustomerAssignDateTime/>
            <CustomerSyncSap/>
            <div className="customer-filter-form__input-wide flex">
               <Button
                  appearance="secondary"
                  disabled={!canSubmitOtherFilter}
                  size="md-"
                  onClick={() =>
                     canSubmitOtherFilter && functions.applyCustomerOtherFilter()
                  }
               >
                  Tìm kiếm
               </Button>
               <Button
                  appearance="secondary"
                  size="md-"
                  onClick={functions.refresh}
                  className={'customer-filter-form__btn-refresh'}
               >
                  {GLOBAL_ICONS.repeat}
               </Button>
            </div>
         </div>
         <div className="customer-filter-form__group" style={{marginBottom: 4}}>
            <CustomerTags/>
         </div>
      </StyledCustomerFilterForm>
   )
})

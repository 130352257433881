import React from 'react'
import {StyledProductGroupContentModal} from './_styled'
import {Text} from 'common/text'
import {Input} from "../../../../../common/form/input";
import {useCreateCustomerModal} from "../../../hooks/useCreateCustomerModal";

const Index = ({...props}) => {
   const {cardCode, groupCustomer, customerName, regionCustomer, stateCustomer, status, validate} = useCreateCustomerModal()
   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className={'product-group-content__bottom'}>
               <div className='product-group-content_group'>
                  <Input value={customerName.value}
                         onChange={e => customerName.onChange(e.target.value)}
                         onBlur={e => customerName.onBlur(e.target.value)}
                         validateType={validate?.customerName ? 'danger' : 'success'}
                         validateText={validate?.customerName}
                         placeholder={"Vui lòng nhập tên khách hàng"}
                         label={<Text>Tên khách hàng <Text color={'red'}>*</Text></Text>}/>
               </div>
               <div className='product-group-content_group'>
                  <Input value={cardCode.value}
                         disabled={!!status}
                         onChange={e => !status ? cardCode.onChange(e.target.value) : ''}
                         onBlur={e => cardCode.onBlur(e.target.value)}
                         validateType={validate?.cardCode ? 'danger' : 'success'}
                         validateText={validate?.cardCode}
                         placeholder={"Vui lòng nhập cardcode"}
                         label={<Text>Cardcode <Text color={'red'}>*</Text></Text>}/>
               </div>
               <div className='product-group-content_group'>
                  <Input value={groupCustomer.value}
                         onChange={e => groupCustomer.onChange(e.target.value)}
                         onBlur={e => groupCustomer.onBlur(e.target.value)}
                         validateType={validate?.groupCustomer ? 'danger' : 'success'}
                         validateText={validate?.groupCustomer}
                         placeholder={"Vui lòng nhập group khách hàng"}
                         label={<Text>Group khách hàng <Text color={'red'}>*</Text></Text>}/>
               </div>
               <div className='product-group-content_group'>
                  <Input value={regionCustomer.value}
                         onChange={e => regionCustomer.onChange(e.target.value)}
                         onBlur={e => regionCustomer.onBlur(e.target.value)}
                         validateType={validate?.regionCustomer ? 'danger' : 'success'}
                         validateText={validate?.regionCustomer}
                         placeholder={"Vui lòng nhập vùng của khách hàng"}
                         label={<Text>Vùng <Text color={'red'}>*</Text></Text>}/>
               </div>
               <div className='product-group-content_group'>
                  <Input value={stateCustomer.value}
                         onChange={e => stateCustomer.onChange(e.target.value)}
                         onBlur={e => stateCustomer.onBlur(e.target.value)}
                         validateType={validate?.stateCustomer ? 'danger' : 'success'}
                         validateText={validate?.stateCustomer}
                         placeholder={"Vui lòng nhập tỉnh thành"}
                         label={<Text>Tỉnh thành <Text color={'red'}>*</Text></Text>}/>
               </div>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index
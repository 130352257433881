import React, {memo, useEffect} from "react";
import { SaleProvider } from "./provider";
import PageHeader from "./component/header/index";
import { TableLayout } from "layouts/tableLayout";
import useSale from "./hook/useSale";
import FilterForm from "./component/filter";
import SaleHeader from "./component/table/thead"
import SaleBody from "./component/table/tbody"
const SaleComponent= memo(() =>{
    const {provider, fetch, pagination} = useSale()
    const {state, dispatch} = provider
    const {table} = state
    useEffect(() => {
        fetch.origin()
    }, [])
    return (
        <SaleProvider  value={{ pageState: state, pageDispatch: dispatch }}>
            <PageHeader/>
            <TableLayout
                header={
                    <>
                        <FilterForm />
                    </>
                }
                table={{
                    tHead: <SaleHeader />,
                    tBody: <SaleBody />,
                }}
                pagination={{
                    ...table.pagination,
                    onAmountChange: pagination.onAmountChange,
                    onPageChange: pagination.onPageChange,
                }}
            />
        </SaleProvider>

    )
})
export default SaleComponent
import React from 'react'
import { CategoryInput } from 'common/form/input/_categoryInput'
import {useAddProductModal} from "../../../../../../hooks/useAddProductModal";

const Search = () => {
  const { search } = useAddProductModal()
  return (
    <CategoryInput
      className={'store-upos-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Sản phẩm', value: '' }}
      categoryWidth={92}
      placeholder='Tìm kiếm theo tên sản phẩm'
      value={search?.value || ''}
      onChange={search.onChange}
      style={{ position: 'relative' }}
    />
  )
}

export default Search
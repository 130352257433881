import { useReducer } from 'react'
import { productInitialState } from '../provider/~initState'
import { productReducer } from '../provider/~reducer'
import { sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { productActions } from '../provider/~action'
import { useLocation, useSearchParams } from 'react-router-dom'
import { STATUS_PRODUCT } from '../../products/book/interface/_contants'
import { BACKGOUND_PRODUCT } from '../interfaces/~constants'
import { formatDatetime } from '../../../common/form/datePicker/_functions'
import { fNumber } from '../../../util/formatNumber'

const useProduct = () => {
  const [state, dispatch] = useReducer(productReducer, productInitialState)
  const location = useLocation()?.pathname?.split('/')
  let [searchParams] = useSearchParams()
  const handleOriginFetch = async () => {
    const search = searchParams.get('search') || ''
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product-category/group-categories?type=book&is_parent=0&keyword=${search}&status&per_page=999`),
    ])
    if (response[0]?.data?.length > 0 ) {
      dispatch({
        type: productActions.FORM_CREATE_ADD_LIST_ORIGIN, payload: {
          list: response[0]?.data,
          listOrigin: response[0]?.data,
        }
      })
    }

    if (location[2] === 'edit') {
      const url = `${config.API}/product/book/detail/${location[3]}`
      const res = await sendRequestAuth('get', url)
      if (res?.data?.success) {
        const data = res?.data?.data
        const endDate = data?.discount_ended_at ? new Date(data?.discount_ended_at) : ''
        const startDate = !!data?.discount_started_at ? new Date(data?.discount_started_at) : ''
        // Basic
        const arrImage = !!data?.images ? JSON.parse(data?.images) : []
        const formatDateTimeDefaultValue = `${formatDatetime(data?.discount_started_at)} - ${formatDatetime(data?.discount_ended_at)}`
        const defaultState = arrImage.length > 0
          ? arrImage.map((item, i) => ({
            id: i + 100,
            name: `image-${i}`,
            url: item,
          }))
          : []
        dispatch(
          {
            type: productActions.FORM_CREATE_CHANGE,
            payload: {
              basic: {
                status: STATUS_PRODUCT.find(item => item.value === data?.status),
                name: data?.title,
                publicationYear: data?.option_year,
                publisher: data?.option_publisher,
                author: data?.option_author,
                language: data?.option_language,
                barCode: data?.product_sku,
                groupProduct: {
                  id: '',
                  value: response[0]?.data?.find(item => +item.id === +data?.category_id),
                  list: response[0]?.data,
                  listOrigin: response[0]?.data,
                  keyword: '',
                },
              },
              product: {
                image: {
                  id: '',
                  name: '',
                  link: !!data?.images ? JSON.parse(data?.images) : [],
                  list: defaultState,
                  listOrigin: defaultState.map(item => item.id),
                  imageFiles: []
                },
                size: data?.option_size,
                cover: BACKGOUND_PRODUCT.find(item => item.value == data?.option_book_cover) || {},
                description: data?.description,
                information: data?.information,
                shortDescription: data?.short_description,
                note: {
                  open: false,
                  content: '',
                },
                validate: {
                  image: {
                    status: false,
                    message: ''
                  },
                },
              },
              price: {
                retail: fNumber(data?.price),
                discount: fNumber(data?.discount),
                quantity: fNumber(data?.quantity),
                dateTime: {
                  end: !!data?.discount_ended_at ? endDate : null,
                  start: !!data?.discount_started_at ? startDate : null,
                  trigger: true,
                  value: (!!data?.discount_ended_at && !!data?.discount_started_at) ? formatDateTimeDefaultValue : null,
                },
                validate: {
                  retail: {
                    status: false,
                    message: '',
                  },
                  discount: {
                    status: false,
                    message: '',
                  },
                  quantity: {
                    status: false,
                    message: '',
                  },
                }
              },
            }
          })
        dispatch({
          type: productActions.CHANGE_FORM_STATUS,
          payload: 'edit'
        })
      }
    }
  }

  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    }
  }
}
export default useProduct
import React from 'react';
import {Text} from "common/text";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ProductEmpty = ({...props}) => {
  const {t} = useTranslation()

  return (
    <StyledProductEmpty {...props}>
      <img
        className={"product-empty__banner"}
        src={"/img/product/empty.png"}
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{marginBottom: 12}}>
        {t('general_not_data_found')}
      </Text>
    </StyledProductEmpty>
  );
};

export default ProductEmpty;

export const StyledProductEmpty = styled.div`
  min-height: calc(100vh - 300px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .product-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`

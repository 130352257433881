import {TableLayout} from 'layouts/tableLayout'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {CustomerFilterForm} from './components/customerFilterForm'
import {CustomerTBody} from './components/customerTable/_customerTBody'
import {CustomerTHead} from './components/customerTable/_customerTHead'
import {CustomerProvider} from './provider'
import './components/customerTable/index.scss'
import useCustomer from './hooks/useCustomer'
import {CustomerNotifications} from './components/customerNotifications'
import {CustomerHeader} from "./components/header";

export const CustomerPage = () => {
   const {fetch, pagination, provider} = useCustomer()

   const {state, dispatch} = provider
   const {table} = state

   useEffect(() => {
      fetch.origin()
   }, [])
   return (
      <CustomerProvider value={{pageState: state, pageDispatch: dispatch}}>
         <CustomerHeader/>
         <CustomerNotifications/>
         <TableLayout
            header={
               <>
                  <CustomerFilterForm/>
               </>
            }
            table={{
               tHead: <CustomerTHead/>,
               tBody: <CustomerTBody/>,
            }}
            pagination={{
               ...table.pagination,
               onAmountChange: pagination.onAmountChange,
               onPageChange: pagination.onPageChange,
            }}
         />
      </CustomerProvider>
   )
}


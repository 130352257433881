import React, {useContext} from 'react'
import {TableProductHeader} from './_tableHeader'
import styled from 'styled-components'
import TbodyProduct from './_tableBody'
import FilterForm from './filter'
import {THEME_SEMANTICS} from "common/theme/_semantics";
import {THEME_COLORS} from "common/theme/_colors";
import {TableLayout} from "layouts/tableLayout";
import {useProductTab} from "../../../../hooks/useProductTab";
import {Text} from "../../../../../../common/text";
import {Button} from "../../../../../../common/button";
import {ProductContext} from "../../../../provider/~context";
import {useModalProductRelateAction} from "../../../../provider/~action";
import ActionFormBrnList from "../../actionFormBrnList";

export const ProductTab = ({data, ...props}) => {
   const {pageDispatch} = useContext(ProductContext)
   const {pagination, table} = useProductTab()
   return (
      <StyledTable>
         <div className={'product-list'}>
            <div className={'product-list__header'}>
               <Text fontSize={16} fontWeight={600}>Danh sách sản phẩm</Text>
               <Button onClick={() => pageDispatch({
                  type: useModalProductRelateAction.DISPLAY_MODAL_PRODUCT,
                  payload: true
               })}>Thêm/Xóa sản phẩm bán</Button>
            </div>
            <TableLayout
               header={
                  <>
                     <FilterForm/>
                     {/*<Panels />*/}
                  </>
               }
               table={{
                  tHead: <TableProductHeader/>,
                  tBody: <TbodyProduct/>,
               }}
               pagination={{
                  ...table.pagination,
                  onAmountChange: pagination.onAmountChange,
                  onPageChange: pagination.onPageChange,
               }}
            />
         </div>
      </StyledTable>
   )
}

const StyledTable = styled.div`
position: relative;

.product-list{
  &__header{
    display: flex;
    justify-content: space-between;
    background: rgb(247, 249, 253);
    padding: 8px 16px;
    align-items: center;
  }
  border: 1px solid #E2EAF8;
}

.table-layout__table{
  background: #fff;
}
.table-layout__pagination {
  position: absolute;
  bottom: -78px;
  width: calc(100% + 48px);
  left: -24px;
}
.row-tab-detail{
&__empty{
      align-items: center;
      justify-content: center;
      min-height: 300px;
      p{
         width: 100%!important;
         text-align: center;
      }
   }
}
.row-tab-detail__content{
   display: flex;
&-group{
      width: 33.33%;
      h4{
         margin-bottom: 24px;
      }
   &--items{
         margin-bottom: 16px;
         p{
            margin-bottom: 8px;
         }
      }
   }
}
.store-upos-table__tooltipV2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
.row-tab-payment {
&__list-item {
      margin-bottom: 12px;
      padding: 12px 24px;

      border-left: 3px solid ${THEME_SEMANTICS.delivered};

   &:last-child {
         margin-bottom: 0;
      }
   }

&__list-item-toggle {
      display: flex;
      align-items: center;

      cursor: pointer;

   &[data-active='true'] {
         svg {
            transform: rotate(0deg);
         }
      }

      svg {
         margin-left: 8px;

         transform: rotate(180deg);
         transition: transform 0.6s;
      }
   }

&__list-item-content {
      max-height: 0;

      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      transition: max-height 0.5s;

   &[data-active='true'] {
         max-height: 75vh;
      }
   }

&__list-item-figure {
      width: 250px;
      margin-top: 8px;
   }
}
.row-tab-detail {
&__table {
      margin-top: 24px;
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
      &-body{
        max-height: 600px;
        overflow: auto;
      }
   }

&__thead {
   .row-tab-detail__tr {
         background: #f7f9fd;
      }
   }

&__tr {
      display: flex;
   }

&__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

   &:nth-child(1) {
         width: 60px;
         text-align: center;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(2) {
         flex: 1;
         padding-left: 32px;
      }
   &:nth-child(3) {
         width: 15%;
         text-align: center;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(4) {
         width: 15%;
         text-align: center;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(5) {
         width: 20%;
         text-align: center;
         justify-content: center;
         align-items: center;
      }
   }

&__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

   &:nth-child(2) {
         padding-left: 32px;
         flex: 1;
         display: flex;
         .create-teacher-table__cell-image{
          height: auto;
          max-height: 100px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          img{
            height: auto;
            max-height: 100%;
            width: 100%
          }
        }
      }
   &:nth-child(3) {
         width: 15%;
         color: ${THEME_SEMANTICS.delivering};
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(4) {
         width: 15%;
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(5) {
         width: 20%;
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(1) {
         width: 60px;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;
      }
   }

}
`

import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import styled from 'styled-components'
import {fDateTimeSuffix} from 'util/formatTime'
import {GLOBAL_ICONS} from '../../../../interface/icon'
import {THEME_SEMANTICS} from '../../../../common/theme/_semantics'
import {HistoryPopup} from '../historyPage/historyPopup'
import React, {useState} from 'react'
import {sendRequestAuth} from '../../../../api/api'
import config from '../../../../config'
import {NewHistory, OldHistory} from '../historyPage/historyPopup/content'

export const RowTabHistory = ({data, ...props}) => {

   const [showHistory, setShowHistory] = useState(false)
   const [history, setHistory] = useState()

   const getHistoryEditOrder = async (id) => {
      const response = await sendRequestAuth('get',
         `${config.API}/order/log/detail/${id}`
      )
      if (!!response.data.success) {
         setHistory(response.data?.data)
         setShowHistory(true)
      }

   }
   return (
      <StyledRowTabHistory {...props}>
         {Array.isArray(data?.logs) && (
            <div className="row-tab-history__list">
               {data.logs.map((item, i) => (
                  <div
                     key={i}
                     className="row-tab-history__item"
                     data-only={data.logs.length <= 1 ? true : undefined}
                  >
                     <div className="row-tab-history__bullet" data-active={i === 0}>
                        <div></div>
                     </div>
                     <div>
                        <Text
                           color={THEME_COLORS.secondary_200}
                           style={{marginBottom: 6, display: 'block'}}
                        >
                           {item.user_name} <b>{item.action_desc}</b>
                        </Text>
                        <div className="row-tab-history__content" style={{marginBottom: 6}}>
                           {GLOBAL_ICONS.clockFastForward}{' '}
                           <Text style={{marginLeft: 8}}>
                              {item?.created_at
                                 ? fDateTimeSuffix(item.created_at)
                                 : '---'}
                           </Text>
                        </div>
                        {!!item?.action_detail &&
                        <div style={{fontSize: '14px'}} dangerouslySetInnerHTML={{__html: item?.action_detail}}></div>}
                        {
                           (item?.action == 'update_order' || item?.action == 'sap_update_order')
                           && <Text color={THEME_SEMANTICS.delivering}
                              // href={`/order/history/${item.id}`}
                                    onClick={() => getHistoryEditOrder(item.id)}
                                    style={{cursor: 'pointer'}}>Xem chi tiết</Text>
                        }
                     </div>
                  </div>
               ))}
            </div>
         )}
         <HistoryPopup openModal={showHistory}
                       clickClose={() => setShowHistory(false)}
                       header={{
                          title: 'Lịch sử chỉnh sửa đơn hàng #' + data?.id
                       }}
                       body={[
                          {item: <OldHistory data={history} detail={data}/>},
                          {item: <NewHistory data={history} detail={data}/>}
                       ]}
                       className={'custom-rightside'}/>
      </StyledRowTabHistory>
   )
}

const StyledRowTabHistory = styled.div`
  .row-tab-history {
    &__list {
      position: relative;
      z-index: 0;

      &::before {
        position: absolute;
        top: 23px;
        left: 10px;
        z-index: 1;

        width: 1px;
        height: calc(100% - 46px);

        background: #f4f7fc;

        content: '';
      }
    }

    &__item {
      position: relative;
      z-index: 2;

      margin-bottom: 30px;

      display: flex;
      align-items: start;

      &::before {
        position: absolute;
        top: 50%;
        left: -8px;
        z-index: 2;

        width: 36px;
        height: 0;

        background: #fff;
        border-radius: 50%;

        content: '';

        transform: translate(0, -50%);
      }

      &:first-child {
        &::after {
          position: absolute;
          top: 0;
          left: 10px;
          z-index: 1;

          width: 1px;
          height: 110px;

          background: ${THEME_COLORS.primary_300};

          content: '';
        }

        &[data-only='true'] {
          &:after {
            display: none;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__bullet {
      position: relative;
      z-index: 2;

      width: 20px;
      height: 20px;
      margin-right: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: linear-gradient(
          0deg,
          rgba(244, 247, 252, 0.98),
          rgba(244, 247, 252, 0.98)
        ),
        #00081d;
      border: 2px solid #fffeee;
      border-radius: 50%;

      &[data-active='true'] {
        background: #3264FF;
        border: 2px solid #d8fce7;
      }

      & > div {
        width: 6px;
        height: 6px;

        background: #fff;
        border-radius: 50%;
      }
    }

    &__content {
      display: flex;
      align-items: center;
    }
  }
`

import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateSaleForm} from "../../hook/useCreateSaleForm";

export const Phone = ()=>{
    const {phone} = useCreateSaleForm()
    const {methods} = phone
    return (
        <Input
            label={
                <>
                    Số điện thoại
                </>
            }
            value={phone?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={phone?.message}
            validateType={phone?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập số điện thoại'}
            maxLength={12}
        />
    )
}
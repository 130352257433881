
export const productActions = {
  //  FILTER
  CHANGE_LOADING: 'CHANGE_LOADING',
  FILTER_ADVANCED_SEARCH_UPDATE: 'FILTER_ADVANCED_SEARCH_UPDATE',
  FILTER_ADVANCED_STATUS_UPDATE: 'FILTER_ADVANCED_STATUS_UPDATE',
  FILTER_ADVANCED_CATEGORY_UPDATE: 'FILTER_ADVANCED_CATEGORY_UPDATE',
  SET_LIST_DEFAULT: 'SET_LIST_DEFAULT',

  //TABLE
  TABLE_AMOUNT_UPDATE: 'TABLE_AMOUNT_UPDATE',
  TABLE_DISPLAY_DATA_UPDATE: 'TABLE_DISPLAY_DATA_UPDATE',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_DISPLAY_LOADING_UPDATE: 'TABLE_DISPLAY_LOADING_UPDATE',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DEBOUNCE_CHANGE_PRODUCT_STATUS: 'TABLE_DEBOUNCE_CHANGE_PRODUCT_STATUS',
  //MODAL
  MODAL_CONFIRM_PRODUCT_STATUS: 'MODAL_CONFIRM_PRODUCT_STATUS',
  DATA_CHANGE_PRODUCT_STATUS: 'DATA_CHANGE_PRODUCT_STATUS',
  MODAL_CONFIRM_REMOVE_PRODUCT: 'MODAL_CONFIRM_REMOVE_PRODUCT',
  DATA_REMOVE_PRODUCT_TBODY: 'DATA_REMOVE_PRODUCT_TBODY',
  MODAL_CONFIRM_PRODUCT_DETAIL_STATUS: 'MODAL_CONFIRM_PRODUCT_DETAIL_STATUS',
  DATA_CHANGE_PRODUCT__DETAIL_STATUS: 'DATA_CHANGE_PRODUCT__DETAIL_STATUS',
  MODAL_CONFIRM_PRODUCT_GROUP_STATUS: 'MODAL_CONFIRM_PRODUCT_GROUP_STATUS',
  // CREATE AND EDIT FORM
  CHANGE_FORM_STATUS: 'CHANGE_FORM_STATUS',
  // BASIC
  FORM_CREATE_CHANGE: 'FORM_CREATE_CHANGE',
  FORM_CREATE_CHANGE_ACTIVE_INFO_BASIC: 'FORM_CREATE_CHANGE_ACTIVE_INFO_BASIC',
  FORM_CREATE_CHANGE_NAME_INFO_BASIC: 'FORM_CREATE_CHANGE_NAME_INFO_BASIC',
  FORM_CREATE_CHANGE_CODE_INFO_BASIC: 'FORM_CREATE_CHANGE_CODE_INFO_BASIC',
  FORM_CREATE_CHANGE_BARCODE_INFO_BASIC: 'FORM_CREATE_CHANGE_BARCODE_INFO_BASIC',
  FORM_CREATE_CHANGE_PUBLISHER_BASIC: 'FORM_CREATE_CHANGE_PUBLISHER_BASIC',
  FORM_CREATE_CHANGE_PUBLISH_YEAR_INFO_BASIC: 'FORM_CREATE_CHANGE_PUBLISH_YEAR_INFO_BASIC',
  FORM_CREATE_ADD_LIST_ORIGIN: 'FORM_CREATE_ADD_LIST_ORIGIN',
  FORM_CREATE_SEARCH_LIST_ORIGIN: 'FORM_CREATE_SEARCH_LIST_ORIGIN',
  FORM_CREATE_UPDATE_LIST_CHILDREN_TWO: 'FORM_CREATE_UPDATE_LIST_CHILDREN_TWO',
  FORM_CREATE_CHANGE_ID_GROUP_INFO_BASIC: 'FORM_CREATE_CHANGE_ID_GROUP_INFO_BASIC',
  FORM_CREATE_CHANGE_VALUE_GROUP_INFO_BASIC: 'FORM_CREATE_CHANGE_VALUE_GROUP_INFO_BASIC',
  FORM_CREATE_OPEN_MODAL_INFO_BASIC: 'FORM_CREATE_OPEN_MODAL_INFO_BASIC',
  FORM_CREATE_MODAL_CODE_INFO_BASIC: 'FORM_CREATE_MODAL_CODE_INFO_BASIC',
  FORM_CREATE_MODAL_NAME_INFO_BASIC: 'FORM_CREATE_MODAL_NAME_INFO_BASIC',
  FORM_CREATE_MODAL_GROUP_PRODUCT_INFO_BASIC: 'FORM_CREATE_MODAL_GROUP_PRODUCT_INFO_BASIC',
  FORM_CREATE_MODAL_NOTE_INFO_BASIC: 'FORM_CREATE_MODAL_NOTE_INFO_BASIC',
  FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC: 'FORM_CREATE_MODAL_VALIDATE_CODE_INFO_BASIC',
  FORM_CREATE_CHANGE_AUTHOR_INFO_BASIC: 'FORM_CREATE_CHANGE_AUTHOR_INFO_BASIC',
  FORM_CREATE_CHANGE_LANGUAGE_INFO_BASIC: 'FORM_CREATE_CHANGE_LANGUAGE_INFO_BASIC',
  FORM_CREATE_CHANGE_STATUS_BASIC: 'FORM_CREATE_CHANGE_STATUS_BASIC',
  FORM_UPDATE_GROUP_PRODUCT_INFO_BASIC: 'FORM_UPDATE_GROUP_PRODUCT_INFO_BASIC',
  // PRODUCT
  FORM_CREATE_CHANGE_IMAGE_PRODUCT: 'FORM_CREATE_CHANGE_IMAGE_PRODUCT',
  FORM_CREATE_UPDATE_IMAGE_PRODUCT: 'FORM_CREATE_UPDATE_IMAGE_PRODUCT',
  FORM_CREATE_CHANGE_INFO_PRODUCT: 'FORM_CREATE_CHANGE_INFO_PRODUCT',
  FORM_CREATE_CHANGE_INFORMATION_PRODUCT: 'FORM_CREATE_CHANGE_INFORMATION_PRODUCT',
  FORM_CREATE_CHANGE_UNIT_TYPE_PRODUCT: 'FORM_CREATE_CHANGE_UNIT_TYPE_PRODUCT',
  FORM_CREATE_ADD_LIST_ORIGIN_UNIT: 'FORM_CREATE_ADD_LIST_ORIGIN_UNIT',
  FORM_CREATE_SHOW_NOTE_PRODUCT: 'FORM_CREATE_SHOW_NOTE_PRODUCT',
  FORM_CREATE_INIT_INVENTORY_PRODUCT: 'FORM_CREATE_INIT_INVENTORY_PRODUCT',
  FORM_CREATE_INIT_WEIGHT_PRODUCT: 'FORM_CREATE_INIT_WEIGHT_PRODUCT',
  FORM_CREATE_INIT_NOTE_PRODUCT: 'FORM_CREATE_INIT_NOTE_PRODUCT',
  FORM_CREATE_CHANGE_UNIT_VALUE_PRODUCT: 'FORM_CREATE_CHANGE_UNIT_VALUE_PRODUCT',
  FORM_CREATE_OPEN_MODAL_PRODUCT: 'FORM_CREATE_OPEN_MODAL_PRODUCT',
  FORM_CREATE_MODAL_UNIT_PRODUCT: 'FORM_CREATE_MODAL_UNIT_PRODUCT',
  FORM_CREATE_MODAL_SYMBOL_PRODUCT: 'FORM_CREATE_MODAL_SYMBOL_PRODUCT',
  FORM_CREATE_MODAL_VALIDATE_UNIT_PRODUCT: 'FORM_CREATE_MODAL_VALIDATE_UNIT_PRODUCT',
  FORM_CREATE_MODAL_VALIDATE_SYMBOL_PRODUCT: 'FORM_CREATE_MODAL_VALIDATE_SYMBOL_PRODUCT',
  // PRICE
  FORM_CREATE_INIT_PRICE_RETAIL: 'FORM_CREATE_INIT_PRICE_RETAIL',
  FORM_CREATE_INIT_PRICE_DISCOUNT: 'FORM_CREATE_INIT_PRICE_DISCOUNT',
  FORM_CREATE_INIT_PRICE_LAST_ENTRY: 'FORM_CREATE_INIT_PRICE_LAST_ENTRY',
  FORM_CREATE_INIT_PRICE_COST: 'FORM_CREATE_INIT_PRICE_COST',
  FORM_CREATE_DATE_TIME_UPDATE: 'FORM_CREATE_DATE_TIME_UPDATE',
  FORM_CREATE_INIT_QUANTITY: 'FORM_CREATE_INIT_QUANTITY',
  // WAREHOUSE
  FORM_CREATE_ADD_LIST_ORIGIN_WAREHOUSE: 'FORM_CREATE_ADD_LIST_ORIGIN_WAREHOUSE',
  FORM_CREATE_UPDATE_LIST_WAREHOUSE: 'FORM_CREATE_UPDATE_LIST_WAREHOUSE',
  FORM_CREATE_CHANGE_INVENTORY_VALUE_PRODUCT: 'FORM_CREATE_CHANGE_INVENTORY_VALUE_PRODUCT',
  FORM_CREATE_INIT_INVENTORY: 'FORM_CREATE_INIT_INVENTORY',

  // VERSION
  FORM_CREATE_CHANGE_INIT_ATTRIBUTES_PRODUCT: 'FORM_CREATE_CHANGE_INIT_ATTRIBUTES_PRODUCT',
  FORM_CREATE_CHANGE_ATTRIBUTES_PRODUCT: 'FORM_CREATE_CHANGE_ATTRIBUTES_PRODUCT',
  FORM_CREATE_CHANGE_ATTRIBUTES_VALUE_PRODUCT: 'FORM_CREATE_CHANGE_ATTRIBUTES_VALUE_PRODUCT',
  FORM_CREATE_CHANGE_ATTRIBUTES_COLUMN_PRODUCT: 'FORM_CREATE_CHANGE_ATTRIBUTES_COLUMN_PRODUCT',
  FORM_EDIT_CHANGE_ATTRIBUTES_COLUMN_PRODUCT: 'FORM_EDIT_CHANGE_ATTRIBUTES_COLUMN_PRODUCT',
  FORM_CREATE_TOGGLE_MODAL_PRODUCT_PRICE_MANAGER: 'FORM_CREATE_TOGGLE_MODAL_PRODUCT_PRICE_MANAGER',
  // VALIDATE
  VALIDATE_FORM_CREATE_NAME: 'VALIDATE_FORM_CREATE_NAME',
  VALIDATE_FORM_CREATE_CODE: 'VALIDATE_FORM_CREATE_CODE',
  VALIDATE_FORM_CREATE_BARCODE: 'VALIDATE_FORM_CREATE_BARCODE',
  VALIDATE_FORM_CREATE_PRODUCT_IMAGE: 'VALIDATE_FORM_CREATE_PRODUCT_IMAGE',
  VALIDATE_FORM_CREATE_GROUP_PRODUCT: 'VALIDATE_FORM_CREATE_GROUP_PRODUCT',
  VALIDATE_FORM_CREATE_UNIT_PRODUCT: 'VALIDATE_FORM_CREATE_UNIT_PRODUCT',
  VALIDATE_FORM_CREATE_WEIGHT_PRODUCT: 'VALIDATE_FORM_CREATE_WEIGHT_PRODUCT',
  VALIDATE_FORM_CREATE_INIT_INVENTORY: 'VALIDATE_FORM_CREATE_INIT_INVENTORY',
  VALIDATE_FORM_CREATE_RETAIL: 'VALIDATE_FORM_CREATE_RETAIL',
  VALIDATE_FORM_CREATE_DISCOUNT: 'VALIDATE_FORM_CREATE_DISCOUNT',
  VALIDATE_FORM_CREATE_DISCOUNT_DATETIME: 'VALIDATE_FORM_CREATE_DISCOUNT_DATETIME',
  VALIDATE_FORM_CREATE_LAST_ENTRY: 'VALIDATE_FORM_CREATE_LAST_ENTRY',
  VALIDATE_FORM_CREATE_COST: 'VALIDATE_FORM_CREATE_COST',
  VALIDATE_FORM_CREATE_SKU_CHILD: 'VALIDATE_FORM_CREATE_SKU_CHILD',
  VALIDATE_FORM_CREATE_BAR_CODE_CHILD: 'VALIDATE_FORM_CREATE_BAR_CODE_CHILD',
  VALIDATE_FORM_CREATE_BASIC: 'VALIDATE_FORM_CREATE_BASIC',
  VALIDATE_FORM_CREATE_QUANTITY: 'VALIDATE_FORM_CREATE_QUANTITY',

  FORM_CREATE_ZOOM_IN_IMAGE: 'FORM_CREATE_ZOOM_IN_IMAGE',
  FORM_CREATE_ZOOM_IN_IMAGE_LINK_ACTIVE: 'FORM_CREATE_ZOOM_IN_IMAGE_LINK_ACTIVE',
  FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC: "FORM_CREATE_MODAL_VALIDATE_NAME_INFO_BASIC",

  EDIT_PRODUCT_CONFIRM_POPUP_1: 'EDIT_PRODUCT_CONFIRM_POPUP_1',
  EDIT_PRODUCT_CONFIRM_POPUP_2: 'EDIT_PRODUCT_CONFIRM_POPUP_2',
  EDIT_PRODUCT_CONFIRM_POPUP_3: 'EDIT_PRODUCT_CONFIRM_POPUP_3',
  EDIT_PRODUCT_STATUS_CONFIRM: 'EDIT_PRODUCT_STATUS_CONFIRM',
  //Export -- import
  EXPORT_FILE:'EXPORT_FILE',
  IMPORT_FILE:'IMPORT_FILE',
  SET_FILTER:'SET_FILTER',
  NOTIFICATIONS_LIST_UPDATE: 'NOTIFICATIONS_LIST_UPDATE',
  //Ware house
  WARE_HOUSE_LIST:"WARE_HOUSE_LIST",
  WARE_HOUSE_ID:'WARE_HOUSE_ID',
  FILTER_WAREHOUSE_KEYWORD_UPDATE: 'FILTER_WAREHOUSE_KEYWORD_UPDATE',
  FILTER_WAREHOUSE_UPDATE: 'FILTER_WAREHOUSE_UPDATE',
  CHECK_DETAIL_TAB_ID:'CHECK_DETAIL_TAB_ID',
  CHECK_LIST_TAB_ID:'UPDATE_LIST_TAB_ID',

}
import {PATH} from "../../../../../const/path";

export const CREATE_SALE_BREARDCUM=[
    {id: 1, name: 'Home', url: PATH.SETTING},
    {id: 2, name: 'Khách hàng', url: '#'},
    {id: 3, name: 'Sale quản lý', url: '#'},
    {id: 4, name: 'Thêm mới Sale quản lý', url: '#'}
]
export const EDIT_SALE_BREARDCUM=[
    {id: 1, name: 'Home', url: PATH.SETTING},
    {id: 2, name: 'Khách hàng', url: '#'},
    {id: 3, name: 'Sale quản lý', url: '#'},
    {id: 4, name: 'Chỉnh sửa Sale quản lý', url: '#'}
]
export const CREATE_SALE_FORM = [
    'userName',
    'schoolName',
    'phone',
    'email',
    'name',
    'lastName',
    'birthdate',
    'identify',
    'gender',
    'address',
    'password',
    'statusPerson',
    'accountNumber',
    'accountOwner',
    'nameBank'
]
export const CREATE_SALE_GENDER=[
    {name: 'Nam', value: 'male'},
    {name: 'Nữ', value: 'female'},
]
export const STATUS_SALE = [
    {id: 1, title: 'Đang hoạt động', value: 'active'},
    {id: 2, title: 'Đang khóa', value: 'inactive'},
]

export const TAB_CREATE_SALE_EDIT = [
    {name: 'Thông tin Sale quản lý', tab:'sale'},
    {name: 'Khách hàng quản lý', tab:'customers'},
    // {name: 'Khách hàng trực thuộc', tab:'customers'},
    // {name: 'Sản phấm bán', tab:'products'},
]

export const TEACHER_TABLE_HEADER =[
    {name: 'Khách hàng', id: 1},
    {name: 'Cardcode', id: 2},
    // {name: 'Tên giáo viên', id: 3},
    {name: 'Ngày tạo', id: 4},
    {name: 'Trạng thái', id: 5},
]

export const PRODUCT_TABLE_HEADER =[
    {name: 'Tên sản phẩm', id: 1},
    {name: 'Giá bán', id: 2},
    {name: 'Giá khuyến mãi', id: 3},
    {name: 'Thời hạn bản quyền', id: 4},
]

import React, {useContext} from 'react'
import {StyledRevenuePanels} from './_styled'
import { RevenueContext } from '../../provider/_context'
import { RevenuePanel } from './_revenuePanel'

export const RevenuePanels = ({...props}) => {
  const {pageState} = useContext(RevenueContext)
  const {panels} = pageState

  return (
    <StyledRevenuePanels {...props}>
      <RevenuePanel
        className="order-panels__item"
        currency="đơn"
        title="Tổng số lượng đơn"
        value={panels?.total_orders || 0 }
      />
      <RevenuePanel
        className="order-panels__item"
        currency="₫"
        title="Tổng doanh Thu"
        value={panels?.total_revenue || 0}
      />
      {/*<RevenuePanel*/}
      {/*  className="order-panels__item"*/}
      {/*  currency="₫"*/}
      {/*  title="Tổng hoa hồng cho hiệu trưởng"*/}
      {/*  value={panels?.commission_principal || 0}*/}
      {/*/>*/}
      {/*<RevenuePanel*/}
      {/*  className="order-panels__item"*/}
      {/*  currency="₫"*/}
      {/*  title="Tổng hoa hồng cho giáo viên"*/}
      {/*  value={panels?.commission_teacher || 0}*/}
      {/*/>*/}
    </StyledRevenuePanels>
  )
}

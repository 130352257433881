import {formatDatetime} from 'common/form/datePicker/_functions'
import {getDateFromNow} from 'util/date'

export const dateTimeDefaultValue = [
   getDateFromNow(0, {type: 'start'}),
   getDateFromNow(7, {type: 'end'}),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
   dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const productInitialState = {
   formCreate: {
      productRelated: {
         modalProduct: {
            show: false,
            filter: {
               search: {
                  value: null,
                  active: null
               },
               category: {
                  list: [],
                  listOrigin: [],
                  activeValue: null,
                  value: null,
                  keyword: '',
               },
            },
            table: {
               display: {
                  list: [],
                  listDefault: [],
                  loading: true,
               },
               detail: {
                  id: null,
                  active: null,
                  list: [],
               },
               selected: {
                  list: [],
               },
               pagination: {
                  active: 0,
                  amount: 200,
                  total: 0,
                  totalItems: 0,
               },
            },
         }
      },
      customer: null,
      configProduct: {
         type: 1,
      },
      statusForm: 'create',
      configProductType: {
         name: 'Bán tất cả sản phẩm',
         value: 'all'
      },
      productTab: {
         filter: {
            search: {
               value: null,
               active: null
            },
            category: {
               list: [],
               listOrigin: [],
               activeValue: null,
               value: null,
               keyword: '',
            },
            status: null
         },
         table: {
            display: {
               list: [],
               listDefault: [],
               loading: true,
            },
            selected: {
               list: [],
            },
            detail: {
               id: null,
               active: null,
               list: [],
            },
            pagination: {
               active: 0,
               amount: 999,
               total: 0,
               totalItems: 0,
            },
         },
         orderModal: false
      },
      defaultQuantity: '',
      categoryType: {
         listDefault: [],
         selected: []
      },
   },
   modal: null,
   loading: false
}
import {CategoryDateTimeRangePicker} from 'common/form/datePicker/_categoryDateTimeRangePicker'
import useCustomerFilterForm from 'Pages/customer/hooks/useCustomerFilterForm'
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import { getDateFromNow } from '../../utils/date'
import {CUSTOMER_FILTER_FORM_DATE_TIME_ASSIGN_SORT_TYPES} from "../../interfaces/_constants";

export const CustomerAssignDateTime = () => {
  const {assignDateTime} = useCustomerFilterForm()
  const predefinedRanges = [
    {
      label: 'Hôm qua',
      value: [addDays(getDateFromNow(0, {type: 'start'}), -1), addDays(getDateFromNow(0, {type: 'end'}), -1)],
    },
    {
      label: 'Hôm nay',
      value: [getDateFromNow(0, {type: 'start'}), getDateFromNow(0, {type: 'end'})],
    },
    {
      label: '7 ngày trước',
      value: [subDays(getDateFromNow(0, {type: 'start'}), 6), new Date()],
    },
    {
      label: 'Tháng này',
      value: [startOfMonth(getDateFromNow(0, {type: 'start'})), new Date()],
    },
  ];
  return (
    <CategoryDateTimeRangePicker
      className="customer-filter-form__input-wide"
      categoryList={CUSTOMER_FILTER_FORM_DATE_TIME_ASSIGN_SORT_TYPES}
      categoryWidth={124}
      value={assignDateTime.value}
      triggerDefault={assignDateTime.triggerDefault}
      onChange={assignDateTime.onChange}
      datePickerProps={{
        defaultValue: assignDateTime.defaultValue,
        disabledDate: assignDateTime.disabledDate,
        placeholder: 'dd/mm/yyyy ~ dd/mm/yyyy',
        range: predefinedRanges,
        format: 'dd/MM/yyyy'
      }}
    />
  )
}

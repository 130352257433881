import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import { TYPE_SYNC_APP } from '../../interfaces/_constants'
import useCustomerFilterForm from '../../hooks/useCustomerFilterForm'

export const CustomerSyncSap = () => {
   const { t } = useTranslation()
   const { syncSap } = useCustomerFilterForm()
   return (
      <AlternativeAutoComplete
         className="customer-filter-form__input-wide"
         // main input
         inputProps={{
            categoryList: [], // menu list in category dropdown
            categoryValue: { name: t('DTP phát hành 100%'), value: '' }, // if not exist this value -> default category: categoryList[0]
            categoryWidth: 152,
            placeholder: t('Chọn option'),
            readOnly: true,
            value: syncSap?.value?.name || '',
            onIconClick: () => syncSap.onChange(null),
         }}
         hideSearchBar={true}
      >
         {TYPE_SYNC_APP?.map(item => (
            <Option
               key={item.value}
               className="customer-filter-form__option-text"
               data-active={item.value === syncSap?.value?.value}
               onClick={() => syncSap.onChange(item)}
            >
               {item.name}
            </Option>
         ))}
      </AlternativeAutoComplete>
   )
}


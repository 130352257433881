import {useContext, useState} from 'react'
import {ProductContext} from "../provider/~context";
import {useTranslation} from "react-i18next";
import {productActions} from "../provider/~action";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import {useParams} from "react-router-dom";
import useAlert from "../../../hook/useAlert";

const useCategoryType = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(ProductContext)
   const displayList = pageState?.formCreate?.categoryType?.listDefault
   const defaultQuantity = pageState?.formCreate?.defaultQuantity
   const configProductType = pageState?.formCreate?.configProductType
   const {customerId} = useParams()
   const {showAlert} = useAlert()

   const handleDefaultQuantityChange = value => {
      pageDispatch({
         type: productActions.SETTING_DEFAULT_QUANTITY,
         payload: value > 1000000000 ? 999999999 : value
      })
   }
   const updateSettingProductSale = async (type = '') => {
      let data = []

     pageDispatch({
         type: productActions.UPDATE_CUSTOMER_DATA, payload: {
            ...pageState?.formCreate?.customer,
            product_sale_type: type
         }
      })
      if (type === 'category') {
         let categoryIds = []
         displayList.map(cate => {
            if (cate?.childs?.length === 0) {
               if (!!cate?.selected) categoryIds = [...categoryIds, cate.id]
            } else {
               cate.childs.map(child => {
                  if (!!child?.selected) categoryIds = [...categoryIds, child.id]
               })
            }
         })
         if (categoryIds.length === 0) {
            showAlert({
               type: 'danger',
               content: 'Vui lòng chọn ít nhất 1 danh mục'
            })
            return
         }
         data = {
            "quantity_default": defaultQuantity,
            "product_sale_type": type,
            "categories": categoryIds.join(',')
         }
      } else {

         data = {
            "quantity_default": defaultQuantity,
            "product_sale_type": type,
         }
      }

      const response = await sendRequestAuth('post', `${config.API}/customer/detail/${customerId}/setting`, data)

      if (!!response.data?.success) {
         showAlert({
            type: 'success',
            content: response.data.message
         })
      }

   }

   return {
      displayList,
      func: {
         handleDefaultQuantityChange,
         updateSettingProductSale
      }
   }
}

export default useCategoryType;
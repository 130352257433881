import {productActions} from './~action'
import {useModalProductRelateAction} from './~action'

export const useProductAction = {
   FILTER_SEARCH_UPDATE: 'PRODUCT_TAB_FILTER_SEARCH_UPDATE',
   FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'FILTER_PRODUCT_TAB_LIST_ORIGIN_UPDATE',
   FILTER_CATEGORY_VALUE_UPDATE: 'PRODUCT_TAB_FILTER_CATEGORY_VALUE_UPDATE',
   FILTER_CATEGORY_LIST_UPDATE: 'PRODUCT_TAB_FILTER_CATEGORY_LIST_UPDATE',
   TABLE_LOADING_DISPLAY: 'PRODUCT_TAB_TABLE_LOADING_DISPLAY',
   TABLE_UPDATE_DISPLAY_LIST: 'PRODUCT_TAB_TABLE_UPDATE_DISPLAY_LIST',
   TABLE_UPDATE_PAGINATION: 'PRODUCT_TAB_TABLE_UPDATE_PAGINATION',
   UPDATE_MODAL_ORDER: 'UPDATE_MODAL_ORDER',
   UPDATE_TYPE_PRODUCT_CONFIG: 'UPDATE_TYPE_PRODUCT_CONFIG',
   FILTER_STATUS_VALUE_UPDATE: 'FILTER_STATUS_VALUE_UPDATE'
}
export const useCategoryAction = {
   UPDATE_CATEGORY_DATA: 'UPDATE_CATEGORY_DATA',
}

export const productReducer = (state, action) => {
   switch (action.type) {

      case productActions.SETTING_DEFAULT_QUANTITY:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               defaultQuantity: action.payload
            }
         }
      // CATEGORY TAB

      case useCategoryAction.UPDATE_CATEGORY_DATA:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               categoryType: {
                  ...state.formCreate.categoryType,
                  ...action.payload
               }
            }
         }
      // PRODUCT TAB

      case useProductAction.UPDATE_TYPE_PRODUCT_CONFIG:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               configProductType: {
                  ...state.formCreate.configProductType,
                  ...action.payload
               }
            }
         }
      case useProductAction.TABLE_LOADING_DISPLAY:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  table: {
                     ...state.formCreate.productTab.table,
                     display: {
                        ...state.formCreate?.productTab?.table?.display,
                        loading: action?.payload
                     }
                  },
               }
            }
         }

      case useProductAction.TABLE_UPDATE_DISPLAY_LIST:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  table: {
                     ...state.formCreate.productTab.table,
                     display: {
                        ...state.formCreate?.productTab?.table?.display,
                        list: action?.payload?.list || [],
                        listDefault: action?.payload?.listDefault || [],
                        loading: action?.payload?.loading
                     }
                  },
               }
            }
         }
      case useProductAction.UPDATE_MODAL_ORDER:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  orderModal: action.payload
               }
            }
         }
      case useProductAction.TABLE_UPDATE_PAGINATION:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  table: {
                     ...state.formCreate.productTab.table,
                     pagination: {
                        ...state.formCreate.productTab.table.pagination,
                        active: action.payload?.active || 0,
                        amount: action.payload?.amount || 20,
                        total: action.payload?.total || 0,
                        totalItems: action.payload?.totalItems || 0,
                     },
                  },
               }
            }
         }
      case useProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  filter: {
                     ...state.formCreate.productTab.filter,
                     category: {
                        ...state.formCreate.productTab.filter.category,
                        list: action.payload?.list,
                        listOrigin: action.payload?.listOrigin,
                     },
                  },
               }
            }
         }
      case useProductAction.FILTER_SEARCH_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  filter: {
                     ...state.formCreate.productTab.filter,
                     search: {
                        ...state.formCreate.productTab.filter.search,
                        value: action.payload
                     },
                  },
               }
            }
         }
      case useProductAction.FILTER_CATEGORY_LIST_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  filter: {
                     ...state.formCreate.productTab.filter,
                     category: {
                        ...state.formCreate.productTab.filter.category,
                        list: action.payload,
                     },
                  },
               }
            }
         }
      case useProductAction.FILTER_STATUS_VALUE_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  filter: {
                     ...state.formCreate.productTab.filter,
                     status: {
                        ...state.formCreate.productTab.filter.status,
                        value: action.payload,
                     },
                  },
               }
            }
         }
      case useProductAction.FILTER_CATEGORY_VALUE_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  filter: {
                     ...state.formCreate.productTab.filter,
                     category: {
                        ...state.formCreate.productTab.filter.category,
                        value: action.payload,
                     },
                  },
               }
            }
         }
      case useProductAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productTab: {
                  ...state.formCreate.productTab,
                  filter: {
                     ...state.formCreate.productTab.filter,
                     category: {
                        ...state.formCreate.productTab.filter.category,
                        activeValue: action.payload,
                     },
                  },
               }
            }
         }

      case productActions.CHANGE_LOADING:
         return {
            ...state,
            loading: action?.payload
         }

      case productActions.CHANGE_FORM_STATUS:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               statusForm: action?.payload || '',
            },
         }

      case productActions.FORM_CREATE_CHANGE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               ...action.payload
            }
         }

      case productActions.UPDATE_CUSTOMER_DATA:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               customer: action.payload
            }
         }

      case productActions.CHECK_DETAIL_TAB_ID:
         return {
            ...state,
            tab: {
               ...state.tab,
               tab_id: action.payload,
            }
         }
      case productActions.CHECK_LIST_TAB_ID:
         return {
            ...state,
            tab: {
               ...state.tab,
               list_id: action.payload
            }
         }
      //erorrs import data
      case 'NOTIFICATIONS_LIST_UPDATE':
         return {
            ...state,
            notifications: {
               ...state.notifications,
               list: action.payload?.notifications?.list || [],
               total: action?.payload?.notifications?.total || 0
            },
         }


      // product modal


      case useModalProductRelateAction.TABLE_LOADING_DISPLAY:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     table: {
                        ...state.formCreate.productRelated.modalProduct.table,
                        display: {
                           ...state.formCreate?.productRelated?.modalProduct?.table?.display,
                           loading: action?.payload
                        }
                     },
                  }
               }
            }
         }

      case useModalProductRelateAction.TABLE_UPDATE_DISPLAY_LIST:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     table: {
                        ...state.formCreate.productRelated.modalProduct.table,
                        display: {
                           ...state.formCreate?.productRelated?.modalProduct?.table?.display,
                           list: action?.payload?.list || [],
                           listDefault: action?.payload?.listDefault || [],
                           loading: action?.payload?.loading
                        }
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.TABLE_UPDATE_PAGINATION:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     table: {
                        ...state.formCreate.productRelated.modalProduct.table,
                        pagination: {
                           ...state.formCreate.productRelated.modalProduct.table.pagination,
                           active: action.payload?.active || 0,
                           amount: action.payload?.amount || 20,
                           total: action.payload?.total || 0,
                           totalItems: action.payload?.totalItems || 0,
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.FILTER_SEARCH_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     filter: {
                        ...state.formCreate.productRelated.modalProduct.filter,
                        search: {
                           ...state.formCreate.productRelated.modalProduct.filter.search,
                           value: action.payload
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     filter: {
                        ...state.formCreate.productRelated.modalProduct.filter,
                        category: {
                           ...state.formCreate.productRelated.modalProduct.filter.category,
                           ...action.payload
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.FILTER_CATEGORY_LIST_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     filter: {
                        ...state.formCreate.productRelated.modalProduct.filter,
                        category: {
                           ...state.formCreate.productRelated.modalProduct.filter.category,
                           list: action.payload,
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.FILTER_CATEGORY_VALUE_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     filter: {
                        ...state.formCreate.productRelated.modalProduct.filter,
                        category: {
                           ...state.formCreate.productRelated.modalProduct.filter.category,
                           value: action.payload,
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.RESET_MODAL_FILTER:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     filter: {
                        ...state.formCreate.productRelated.modalProduct.filter,
                        category: {
                           ...state.formCreate.productRelated.modalProduct.filter.category,
                           value: null,
                           search: {
                              ...state.formCreate.productRelated.modalProduct.filter.search,
                              value: null
                           },
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     filter: {
                        ...state.formCreate.productRelated.modalProduct.filter,
                        category: {
                           ...state.formCreate.productRelated.modalProduct.filter.category,
                           activeValue: action.payload,
                        },
                     },
                  }
               }
            }
         }
      case useModalProductRelateAction.DISPLAY_MODAL_PRODUCT:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     show: action.payload
                  }
               }
            }
         }
      case useModalProductRelateAction.TABLE_SELECTED_LIST_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               productRelated: {
                  ...state.formCreate.productRelated,
                  modalProduct: {
                     ...state.formCreate.productRelated.modalProduct,
                     table: {
                        ...state.formCreate.productRelated.modalProduct.table,
                        selected: {
                           ...state.formCreate.productRelated.modalProduct.table.selected,
                           list: action.payload?.selected?.list || [],
                        },
                     },
                  }
               }
            }
         }
      default:
         throw new Error()
   }
}
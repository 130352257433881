import React from 'react';
import {STORE_UPOS_ICON} from "../../../products/book/interface/_icons";
import {Input} from "../../../../common/form/input";
import useSaleFilter from "../../hook/useSaleFilter";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";

const Search = () => {
  const {search} = useSaleFilter()
  return (
    <CategoryInput
      categoryValue={{name:'NV Bán hàng', value:''}}
      categoryWidth={120}
      className="sale-filter-form__input-wide"
      placeholder={"Tên Sale quản lý, tên đăng nhập, email"}
      value={search.value}
      onChange={e => search.onChange(e)}
    />
  )
}

export default Search
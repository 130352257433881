import React, {useContext} from 'react';
import {TableLayout} from "../../../../../../layouts/tableLayout";
import {TableTeacherHeader} from "./_tableHeader";
import styled from 'styled-components'
import TbodyTeacher from "./_tableBody";
import {useCreateSaleTable} from "../../hook/useCreateSaleTable";
export const AffiliateTeachers = ()=>{
    const {table, pagination} = useCreateSaleTable()
    return(
    <StyledTable>
        <TableLayout
            table={{
                tHead: <TableTeacherHeader />,
                tBody: <TbodyTeacher />,
            }}
            pagination={{
                ...table.pagination,
                onAmountChange: pagination.onAmountChange,
                onPageChange: pagination.onPageChange,
            }}

        />
    </StyledTable>
    )
}

const StyledTable = styled.div`
    .create-teacher-table{
        &[data-type = 'tHead']{
            .tr__container{
                height: 44px;
            }
        }
        &_cell{
            &:nth-child(1){
               width: 50px;
               justify-content: center;
            }
             &:nth-child(2){
                //width: 210px;
                flex: 1;
            }
            &:nth-child(3){
               width: 150px;
               flex: 1;
            }
            // &:nth-child(4){
            //    width: 210px;
            // }
            &:nth-child(4){
                width: 154px;
            }
            &:nth-child(5){
                width: 150px;
                text-align: center;
                justify-content: center;
                align-items: center;
            }
            &:nth-child(6){
                width: 100px;
            }
        }
    }
`
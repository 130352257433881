import {useContext} from "react";
import {CreateSaleContext} from "../provider/_context";
import {CREATE_SALE_FORM} from "../interfaces/constant";
import {useCreateSaleAction} from "../provider/_reducer";
import {
   convertDateTimeToApiFormatV2,
   formatDatetimeV2
} from "../../../../../common/form/datePicker/_functions";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import useAlert from "../../../../../hook/useAlert";
import {useNavigate, useParams} from "react-router-dom";
import {PATH} from "../../../../../const/path";
import {removeVietnameseTones} from "../../../../../util/checkPasswordVN";

export const useCreateSaleForm = () => {
   const {pageState, pageDispatch} = useContext(CreateSaleContext)
   const {infomation} = pageState

   const {showAlert} = useAlert()
   const navigate = useNavigate()
   //=======USER NAME========
   const valueUserName = infomation.userName?.value
   const statusUserName = infomation.userName?.status
   const messageUserName = infomation.userName?.message

   const handleChangeUserName = data => {
      const userNameFormat = data.replace(/[^\w\s]/gi, '').replaceAll(' ', '')
      pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            userName: {
               value: userNameFormat.trim(),
               status: false,
               message: ''
            }
         }
      })
   }
   const handleBlurUserName = data => {
      if (!!!data) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            userName: {
               value: data,
               status: true,
               message: 'Tên đăng nhập không được để trống'
            }
         }
      })
      else if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            userName: {
               value: data,
               status: true,
               message: 'Tên đăng nhập không được phép vượt quá 200 ký tự'
            }
         }
      })
      else pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               userName: {
                  value: data,
                  status: false,
                  message: ''
               }
            }
         })
   }

   //=======SCHOOL NAME========
   const valueSchoolName = infomation.schoolName?.value
   const statusSchoolName = infomation.schoolName?.status
   const messageSchoolName = infomation.schoolName?.message

   const handleChangeSchoolName = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         schoolName: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurSchoolName = data => {
      if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            schoolName: {
               value: data,
               status: true,
               message: 'Tên trường không được phép vượt quá 200 ký tự'
            }
         }
      })

      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            schoolName: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }


   //=======Phone========
   const valuePhone = infomation.phone?.value
   const statusPhone = infomation.phone?.status
   const messagePhone = infomation.phone?.message

   const handleChangePhone = data => {
      const regexNumb = /^(|[0-9]\d*)$/
      if (regexNumb.test(data) || data === '') pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            phone: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }
   const handleBlurPhone = data => {
      if (data?.length > 11 || data?.length < 10 && data?.length > 0) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            phone: {
               value: data,
               status: true,
               message: 'Số điện thoại chỉ được phép nhập 10, 11 ký tự.'
            }
         }
      })
      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            phone: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }


   //=======Email========
   const valueEmail = infomation.email?.value
   const statusEmail = infomation.email?.status
   const messageEmail = infomation.email?.message

   const handleChangeEmail = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         email: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurEmail = data => {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!!!data)
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               email: {
                  value: data,
                  status: true,
                  message: 'Email không được để trống'
               }
            }
         })
      else if (!regex.test(data) && data !== '') pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            email: {
               value: data,
               status: true,
               message: 'Email phải nhập theo example@gmail.com'
            }
         }
      })
      else if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            email: {
               value: data,
               status: true,
               message: 'Email được phép nhập 200 ký tự.'
            }
         }
      })
      else pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               email: {
                  value: data,
                  status: false,
                  message: ''
               }
            }
         })
   }

   //=======NAME========
   const valueName = infomation.name?.value
   const statusName = infomation.name?.status
   const messageName = infomation.name?.message

   const handleChangeName = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         name: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurName = data => {
      if (!!!data) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            name: {
               value: data,
               status: true,
               message: 'Tên giáo viên không được để trống'
            }
         }
      })
      else if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            name: {
               value: data,
               status: true,
               message: 'Tên giáo viên không được phép vượt quá 200 ký tự'
            }
         }
      })
      else pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               name: {
                  value: data,
                  status: false,
                  message: ''
               }
            }
         })
   }


   //=======last name========
   const valueLastName = infomation.lastName?.value
   const statusLastName = infomation.lastName?.status
   const messageLastName = infomation.lastName?.message

   const handleChangeLastName = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         lastName: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurLastName = data => {
      if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            lastName: {
               value: data,
               status: true,
               message: 'Họ giáo viên không được phép vượt quá 200 ký tự'
            }
         }
      })
      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            lastName: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }

   //=======Birthday========
   const valueBirthday = infomation.birthday?.value
   const statusBirthday = infomation.birthday?.status
   const messageBirthday = infomation.birthday?.message

   const handleChangeBirthday = data => {
      pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            birthday: {
               value: data.value,
               status: false,
               message: '',
               trigger: !infomation.birthday?.trigger
            }
         }
      })
   }


   //=======Identify========
   const valueID = infomation.identify?.value
   const statusID = infomation.identify?.status
   const messageID = infomation.identify?.message

   const handleChangeID = data => {
      const regexNumb = /^(|[0-9]\d*)$/
      if (regexNumb.test(data)) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            identify: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }
   const handleBlurID = data => {
      const regexNumb = /^(|[0-9]\d*)$/
      if (regexNumb.test(data)) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            identify: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
      else if (data?.length > 12 || data?.length < 12) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            identify: {
               value: data,
               status: true,
               message: 'Số CMND chỉ được phép nhập 12 ký tự.'
            }
         }
      })
      else pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               identify: {
                  value: data,
                  status: false,
                  message: ''
               }
            }
         })
   }


   //======gender=====
   const valueGender = infomation.gender?.value
   const statusGender = infomation.gender?.status
   const messageGender = infomation.gender?.message
   const activeValue = infomation.gender?.message

   const handleChangeGender = (data) => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         gender: {
            ...infomation.gender,
            value: data
         }
      }
   })

   //=======Address========
   const valueAddress = infomation.address?.value
   const statusAddress = infomation.address?.status
   const messageAddress = infomation.address?.message

   const handleChangeAddress = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         address: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurAddress = data => {
      if (data?.length >= 101) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            address: {
               value: data,
               status: true,
               message: 'Địa chỉ không được phép vượt quá 100 ký tự'
            }
         }
      })
      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            address: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }
   //=======Password========
   const valueLastPassword = infomation.password?.value
   const statusLastPassword = infomation.password?.status
   const messageLastPassword = infomation.password?.message

   const handleChangePassword = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         password: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurPassword = data => {
      const regex = removeVietnameseTones(data)
      if (!!!regex) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            password: {
               value: data,
               status: true,
               message: 'Mật khẩu không được để trống'
            }
         }
      })
      else if (+regex.length > 32 || +regex.length < 8) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            password: {
               value: data,
               status: true,
               message: 'Mật khẩu phải có ít nhất 8 ký tự và không được quá 32 ký tự.'
            }
         }
      })
      else pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               password: {
                  value: data,
                  status: false,
                  message: ''
               }
            }
         })
   }


   //=======StatusPerson========
   const valueLastStatusPerson = infomation.statusPerson?.value
   const statusLastStatusPersond = infomation.statusPerson?.status
   const messageLastStatusPerson = infomation.statusPerson?.message

   const handleChangeStatusPerson = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         statusPerson: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurStatusPerson = data => {
      if (!!!data) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            statusPerson: {
               value: data,
               status: true,
               message: 'Trạng thái không được để trống'
            }
         }
      })
      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            statusPerson: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }


   //=======AccountNumber========
   const valueCommission = infomation.commission?.value
   const statusCommission = infomation.commission?.status
   const messageCommission = infomation.commission?.message

   const handleChangeCommission = data => {
      const regexNumb = /^(|[0-9]\d*)$/
      if (regexNumb.test(data)) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            commission: {
               value: data > 100 ? 100 : data,
               status: false,
               message: ''
            }
         }
      })
   }

   //=======AccountNumber========
   const valueAccountNumber = infomation.accountNumber?.value
   const statusAccountNumber = infomation.accountNumber?.status
   const messageAccountNumber = infomation.accountNumber?.message

   const handleChangeAccountNumber = data => {
      const regexNumb = /^(|[0-9]\d*)$/
      if (regexNumb.test(data)) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            accountNumber: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }

   //=======AccountOwner========
   const valueLastAccountOwner = infomation.accountOwner?.value
   const statusLastAccountOwner = infomation.accountOwner?.status
   const messageLastAccountOwner = infomation.accountOwner?.message

   const handleChangeAccountOwner = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         accountOwner: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurAccountOwner = data => {
      if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            accountOwner: {
               value: data,
               status: true,
               message: 'Chủ tài khoản không được phép vượt quá 200 ký tự'
            }
         }
      })
      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            accountOwner: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }
   //=======BankName========
   const valueLastBankName = infomation.bankName?.value
   const statusLastBankName = infomation.bankName?.status
   const messageLastBankName = infomation.bankName?.message

   const handleChangeBankName = data => pageDispatch({
      type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
         bankName: {
            value: data,
            status: false,
            message: ''
         }
      }
   })
   const handleBlurBankName = data => {
      if (data?.length >= 201) pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            bankName: {
               value: data,
               status: true,
               message: 'Tên ngân hàng không được phép vượt quá 200 ký tự'
            }
         }
      })
      else pageDispatch({
         type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
            bankName: {
               value: data,
               status: false,
               message: ''
            }
         }
      })
   }
   const canConfirmForm = [
      statusUserName,
      statusSchoolName,
      statusUserName,
      statusLastName,
      statusPhone,
      statusEmail,
      statusLastPassword,
      statusCommission,
      statusID,
      statusLastStatusPersond,
      statusLastBankName,
      statusLastAccountOwner,
      statusAccountNumber
   ].filter(filter => filter === true)


   const data = {
      "username": !!valueUserName ? valueUserName.trim() : '',
      "school_name": !!valueSchoolName ? valueSchoolName.trim() : '',
      "phone": !!valuePhone ? valuePhone.trim() : '',
      "email": !!valueEmail ? valueEmail.trim() : '',
      "name": !!valueName ? valueName.trim() : '',
      "last_name": !!valueLastName ? valueLastName.trim() : '',
      "birthday": !!valueBirthday ? convertDateTimeToApiFormatV2(formatDatetimeV2(valueBirthday)) : '',
      "identity_number": valueID || '',
      "gender": valueGender?.value || 'male',
      "address": !!valueAddress ? valueAddress.trim() : '',
      "password": !!valueLastPassword ? valueLastPassword.trim() : '',
      "status": valueLastStatusPerson?.value || '',
      "commission_rate": !!valueCommission ? valueCommission : '',
      "bank_name": !!valueLastBankName ? valueLastBankName?.trim() : '',
      "bank_number": valueAccountNumber || '',
      "bank_owner": !!valueLastAccountOwner ? valueLastAccountOwner?.trim() : ''
   }

   const {saleId} = useParams()

   const validateBeforeSubmit = () => {

      let canSubmit = true

      if (!valueCommission) {
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               commission: {
                  value: '',
                  status: true,
                  message: '% hoa hồng không được để trống'
               }
            }
         })

         canSubmit = false
      }

      if (!valueUserName) {
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               userName: {
                  value: '',
                  status: true,
                  message: 'Tên đăng nhập không được để trống'
               }
            }
         })

         canSubmit = false
      }

      if (!valueName) {
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               name: {
                  value: '',
                  status: true,
                  message: 'Tên giáo viên không được để trống'
               }
            }
         })

         canSubmit = false
      }else handleBlurName(valueName)

      if (!valueEmail) {
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               email: {
                  value: '',
                  status: true,
                  message: 'Email không được để trống'
               }
            }
         })

         canSubmit = false
      }else handleBlurEmail(valueEmail)

      if (!valueLastPassword) {
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               password: {
                  value: '',
                  status: true,
                  message: 'Mật khẩu không được để trống'
               }
            }
         })

         canSubmit = false
      }

      if (!valueLastStatusPerson) {
         pageDispatch({
            type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
               statusPerson: {
                  value: '',
                  status: true,
                  message: 'Trạng thái không được để trống'
               }
            }
         })

         canSubmit = false
      }

      return canSubmit
   }

   const handleSave = async () => {
      if(!saleId && !validateBeforeSubmit()) return
      const url = !!saleId ? `update/${saleId}` : 'create'
      let formData = {...data}
      if (!!saleId) formData = {...formData, avatar: infomation?.avatar?.value || ''}
      const response = await sendRequestAuth('post', `${config.API}/sale/${url}`, formData)
      if (response?.data?.success) {
         showAlert({type: 'success', content: response?.data?.message})
         navigate(PATH.SALE)
      } else {
         showAlert({type: 'danger', content: 'Bạn cần kiểm tra lại các thông tin không hợp lệ'})
         response?.data?.errors?.forEach((map) => {
            switch (map.field) {
               case 'username':
                  pageDispatch({
                     type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
                        userName: {
                           value: '',
                           status: true,
                           message: map?.message
                        }
                     }
                  })
                  break;
               case 'name':
                  pageDispatch({
                     type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
                        name: {
                           value: '',
                           status: true,
                           message: map?.message
                        }
                     }
                  })
                  break;
               case 'password':
                  pageDispatch({
                     type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
                        password: {
                           value: '',
                           status: true,
                           message: map?.message
                        }
                     }
                  })
                  break;
               case 'email':
                  pageDispatch({
                     type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
                        email: {
                           value: '',
                           status: true,
                           message: map?.message
                        }
                     }
                  })
                  break;
               case 'status':
                  pageDispatch({
                     type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
                        statusPerson: {
                           value: '',
                           status: true,
                           message: map?.message
                        }
                     }
                  })
                  break;
               case 'phone':
                  pageDispatch({
                     type: useCreateSaleAction.FORM_INFOMATION_UPDATE, payload: {
                        phone: {
                           value: '',
                           status: true,
                           message: map?.message
                        }
                     }
                  })
                  break;
               default:
                  break;
            }
         })
      }
   }

   const handleUploadAvatar = async (val) => {
      let image = val.target.files[0]
      const formData = new FormData()
      formData.append('image', image)
      formData.get('image')
      try {
         const response = await sendRequestAuth('post', `${config.API}/sale/upload`, formData)
         if (response?.data?.success) {
            showAlert({type: 'success', content: response?.data?.message})
            pageDispatch({type: useCreateSaleAction.FORM_UPLOAD_AVATAR, payload: response?.data?.file_url})
         } else showAlert({type: 'danger', content: response?.data?.errors[0]?.message})
      } catch (e) {
         console.log(e)
      }
   }


   return {
      canConfirmForm,
      userName: {
         value: valueUserName,
         status: statusUserName,
         message: messageUserName,
         methods: {
            onChange: handleChangeUserName,
            onBlur: handleBlurUserName
         }
      },
      schoolName: {
         value: valueSchoolName,
         status: statusSchoolName,
         message: messageSchoolName,
         methods: {
            onChange: handleChangeSchoolName,
            onBlur: handleBlurSchoolName
         }
      },
      phone: {
         value: valuePhone,
         status: statusPhone,
         message: messagePhone,
         methods: {
            onChange: handleChangePhone,
            onBlur: handleBlurPhone
         }
      },
      email: {
         value: valueEmail,
         status: statusEmail,
         message: messageEmail,
         methods: {
            onChange: handleChangeEmail,
            onBlur: handleBlurEmail
         }
      },
      name: {
         value: valueName,
         status: statusName,
         message: messageName,
         methods: {
            onChange: handleChangeName,
            onBlur: handleBlurName
         }
      },
      lastName: {
         value: valueLastName,
         status: statusLastName,
         message: messageLastName,
         methods: {
            onChange: handleChangeLastName,
            onBlur: handleBlurLastName
         }
      },
      birthday: {
         value: valueBirthday,
         status: statusBirthday,
         message: messageBirthday,
         trigger: infomation?.birthday?.trigger,
         methods: {
            onChange: handleChangeBirthday,
         }
      },
      identify: {
         value: valueID,
         status: statusID,
         message: messageID,
         methods: {
            onChange: handleChangeID,
            onBlur: handleBlurID
         }
      },
      gender: {
         value: valueGender,
         status: statusGender,
         message: messageGender,
         active: activeValue,
         methods: {
            onChange: handleChangeGender,
         }
      },
      address: {
         value: valueAddress,
         status: statusAddress,
         message: messageAddress,
         methods: {
            onChange: handleChangeAddress,
            onBlur: handleBlurAddress
         }
      },
      password: {
         value: valueLastPassword,
         status: statusLastPassword,
         message: messageLastPassword,
         methods: {
            onChange: handleChangePassword,
            onBlur: handleBlurPassword
         }
      },
      statusPerson: {
         value: valueLastStatusPerson,
         status: statusLastStatusPersond,
         message: messageLastStatusPerson,
         methods: {
            onChange: handleChangeStatusPerson,
            onBlur: handleBlurStatusPerson
         }
      },
      accountNumber: {
         value: valueAccountNumber,
         status: statusAccountNumber,
         message: messageAccountNumber,
         methods: {
            onChange: handleChangeAccountNumber,
         }
      },
      commission: {
         value: valueCommission,
         status: statusCommission,
         message: messageCommission,
         methods: {
            onChange: handleChangeCommission,
         }
      },
      accountOwner: {
         value: valueLastAccountOwner,
         status: statusLastAccountOwner,
         message: messageLastAccountOwner,
         methods: {
            onChange: handleChangeAccountOwner,
            onBlur: handleBlurAccountOwner
         }
      },
      bankName: {
         value: valueLastBankName,
         status: statusLastBankName,
         message: messageLastBankName,
         methods: {
            onChange: handleChangeBankName,
            onBlur: handleBlurBankName
         }
      },
      avatar: {
         value: infomation?.avatar?.value,
      },
      detail: infomation?.detail,
      actionList: {
         onSave: handleSave
      },
      onUploadAvatar: handleUploadAvatar
   }


}
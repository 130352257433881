import useCustomerFilterForm from 'Pages/customer/hooks/useCustomerFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const CustomerInfo = () => {
  const { customerInfo } = useCustomerFilterForm()
  return (
    <CategoryInput
      className={'customer-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Khách hàng', value: '' }}
      categoryWidth={98}
      placeholder='Nhập tên khách hàng /Cardcode /Số điện thoại'
      value={customerInfo?.value || ''}
      onChange={customerInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}

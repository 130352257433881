import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import React from 'react'
import { Text } from 'common/text'

export const TheadByOrder = ({ ...props }) => {

  return (
    <>
      <Tr {...props} type="tHead">
        <Th className="revenue-order-table__cell">
          Ngày
        </Th>
        <Th className="revenue-order-table__cell">Mã đơn hàng</Th>
        <Th className="revenue-order-table__cell">Loại đơn hàng</Th>
        <Th className="revenue-order-table__cell">Giá trị sp bán</Th>
        <Th className="revenue-order-table__cell">Chiết khấu</Th>
        <Th className="revenue-order-table__cell">
          <Text as={'p'} style={{ width: '100%' }} fontWeight={600}>Doanh thu</Text>
          <Text as={'p'} style={{ width: '100%' }}>(Sau chiết khấu)</Text>
        </Th>
        <Th className="revenue-order-table__cell">Phí vận chuyển</Th>
        {/*<Th className="revenue-order-table__cell">Hiệu trưởng</Th>*/}
        {/*<Th className="revenue-order-table__cell">Sale quản lý</Th>*/}
        <Th className="revenue-order-table__cell">Hình thức thanh toán</Th>
        <Th className="revenue-order-table__cell">Trạng thái</Th>
      </Tr>
    </>
  )
}

import React from 'react';
import {Tr} from "../../../../../../layouts/tableLayout/_tr";
import {Th} from "../../../../../../layouts/tableLayout/_th";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {GLOBAL_ICONS} from '../../../../../../interface/icon'

const Thead = ({...props}) => {
   const {t} = useTranslation()
   return (
      <StyledThead>
         <Tr {...props} type="tHead">
            <Th className="store-upos-table__cell">STT</Th>
            <Th className="store-upos-table__cell">Tên sách</Th>
            <Th className="store-upos-table__cell">Danh mục</Th>
            <Th className="store-upos-table__cell">Giá bán</Th>
            <Th className="store-upos-table__cell">Chiết khấu</Th>
            <Th className="store-upos-table__cell">Thời gian chiết khấu</Th>
            <Th className="store-upos-table__cell">Sản phẩm nổi bật</Th>
            <Th className="store-upos-table__cell">Hình ảnh</Th>
            <Th className="store-upos-table__cell">{t('general_status')}</Th>
            <Th className="store-upos-table__cell">Thứ tự ưu tiên</Th>
            <Th className="store-upos-table__cell" style={{display: 'flex'}}>
               {GLOBAL_ICONS.setting}
            </Th>
         </Tr>
      </StyledThead>
   )
}

export default Thead


export const StyledThead = styled.div`
  .store-upos-table {
    &__cell {
      margin: 0 8px;
    }
    &__cell:nth-child(1) {
      width: 2%;
    }
    &__cell:nth-child(2) {
      flex: 1;
    }
    &__cell:nth-child(3) {
      width: 10%;
    }
    &__cell:nth-child(4) {
      width: 10%;
    }
    &__cell:nth-child(5) {
      width: 7%;
    }
    &__cell:nth-child(6) {
      width: 8%;
    }
    &__cell:nth-child(7) {
      width: 8%;
    }
    &__cell:nth-child(8) {
      width: 7%;
      display: flex;
      justify-content: center;
    }
    &__cell:nth-child(9) {
      width: 10%;
      text-align: center;
    }
    &__cell:nth-child(10) {
      width: 8%;
      //text-align: center;
      //justify-content: center;
    }
    &__cell:nth-child(11) {
      width: 68px;
      justify-content: end;
    }
  }
`

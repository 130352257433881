import { THEME_COLORS } from 'common/theme/_colors'
import styled from 'styled-components'
// import {formatDatetime} from "../../../../../../common/form/datePicker/_functions";
import {fDateTimeCustom} from "../../../../../../util/formatTime";
import {Text} from "../../../../../../common/text";
export const RowTeacherExtra = ({ id, active, data, rowData, ...props }) => {
    const fullName = `${data?.last_name || ''} ${data?.name || ''}`
    const DETAIL_ROW_INFO_TEACHER=[
        {name:'Tên khách hàng', value:data?.card_name || '---'},
        {name:'Group khách hàng', value: data?.group_name || '---'},
        {name:'Cardcode', value:data?.card_code || '---'},
        {name:'Địa chỉ', value:data?.province_name ? data?.province_name + ', ' + data?.region_name : '---'},
        {name:'Ngày phân công', value:data?.assign_at ? fDateTimeCustom(data.assign_at, { format: 'HH:mm:ss dd/MM/yyyy' }) : '---'},
    ]
    const DETAIL_ROW_INFO_BANK=[
        {name:'Số tài khoản', value:data?.bank_number || '---'},
        {name:'Ngân hàng', value:data?.bank_name || '---'},
        {name:'Chủ tài khoản', value:data?.bank_owner || '---'},
    ]
    return (
        <StyledRowOrderExtra {...props} data-active={active}>
            {active && (
                <div className="row-order-extra__container">
                    <div className="row-order-extra__tabs">
                        <div
                            className="row-order-extra__tab"
                            data-active={true}
                        >
                            Chi tiết khách hàng
                        </div>
                    </div>
                    <div className="row-order-extra__content">
                        <div className={'row-order-extra__items'} >
                            <Text fontWeight={600} fontSize={16}>Thông tin khách hàng</Text>
                            <div className={'row-order-extra__grid'}>
                                {DETAIL_ROW_INFO_TEACHER?.map((map,i)=>{
                                    return <div className={'row-order-extra__grid-item'}>
                                        <Text as={'p'} color={'#7c88a6'} >{map?.name}:</Text>
                                        <Text>{map?.value}</Text>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </StyledRowOrderExtra>
    )
}

const StyledRowOrderExtra = styled.div`
  max-height: 0;

  overflow: hidden;

  transition: all 0.25s;

  &[data-active='true'] {
    max-height: 75vh;
    padding: 4px 0 7px 0;
  }

  .row-order-extra {
    &__container {
      overflow: hidden;

      border-left: 4px solid #3264FF;
      border-radius: 0px 8px 8px 8px;
    }

    &__tabs {
      height: 36px;

      display: flex;
    }

    &__tab {
      margin-right: 4px;
      padding: 0 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #e2eaf8;
      border-radius: 8px 8px 0px 0px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;

      transition: all 0.25s;

      cursor: pointer;

      &:first-child {
        border-radius: 0 8px 0px 0px;
      }

      &[data-active='true'] {
        background: #fff;
      }
    }

    &__content {
      max-height: 60vh;
      padding: 24px 36px 32px 36px;

      overflow: auto;

      background: #fff;
      border-radius: 0 8px 0 0;
      
      display: flex;
      justify-content: space-between;
    }
    &__items{
      height: 100%;
      &:nth-child(1){
         width: calc(100% - 40%);
      } 
      &:nth-child(2){
         width: calc(100% - 60%);
      }
    }
    &__grid{
      align-items: center;
      flex-wrap: wrap;
      display: flex;
    }
    &__grid-item{
      width: calc(50% - 8px);
      margin: 12px 0;
    }
    &__loading {
      width: 100%;
      height: 200px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import useAlert from 'hook/useAlert'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import useRevenueRow from 'Pages/revenue/hooks/useRevenueRow'
import { RevenueContext } from 'Pages/revenue/provider/_context'
import { useContext, useState } from 'react'
import { RevenueEmpty } from '../../revenueEmpty'
import { formatMoney } from 'util/functionUtil'
import { fDateTimeDMY } from 'util/formatTime'
import { fNumber } from 'util/formatNumber'

export const TBodyByDate = () => {
  const { pageState } = useContext(RevenueContext)
  const { table } = pageState

  const displayList = table.display.list
  const displayLoading = table.display.loading
  const paginationTotalItems = table.pagination.totalItems
  return (
    <>
      {displayLoading ? (
        Array.from(Array(20), (e, i) => (
          <RevenuePlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map(item => <RevenueTr key={item.id} data={item}/>)
      ) : (
        <RevenueEmpty/>
      )}
    </>
  )
}

const RevenuePlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="revenue-date-table__row">
      {Array.from(Array(12), (e, i) => (
        <Td key={i} className="revenue-date-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const RevenueTr = ({ data, ...props }) => {
  return (
    <>
      <Tr
        {...props}
        className="revenue-date-table__row"
      >
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{fDateTimeDMY(data?.date_created)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{fNumber(data?.count_orders)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{fNumber(data?.count_book_order)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{fNumber(data?.count_digital_order)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{fNumber(data?.count_completed_order)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{fNumber(data?.count_canceled_order)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_amounts)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_discount)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_revenue)}</Text>
        </Td>
        <Td className="revenue-date-table__cell" data-type="td">
          <Text>{formatMoney(data?.shipping_fee)}</Text>
        </Td>
        {/*<Td className="revenue-date-table__cell" data-type="td">*/}
        {/*  <Text>{formatMoney(data?.commission_principal)}</Text>*/}
        {/*</Td>*/}
        {/*<Td className="revenue-date-table__cell" data-type="td">*/}
        {/*  <Text>{formatMoney(data?.commission_teacher)}</Text>*/}
        {/*</Td>*/}
      </Tr>
    </>
  )
}

import React, {useReducer} from 'react'
import {bookManagementReducer} from "../provider/_reducer"
import {bookManagementInitialState, bookManagementActions} from  "../provider/_init"
import {sendRequestAuth} from "../../../../api/api";
import config from "../../../../config";

const useBookManagement = () => {
  const [state, dispatch] = useReducer(bookManagementReducer, bookManagementInitialState)

  const handleOriginFetch = async () => {
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/book/books?keyword=&category_id=&status=&per_page=20&start=`),
      // sendRequestAuth('get', `${config.API}/product-category/categories`),
      sendRequestAuth('get', `${config.API}/product-category/group-categories?type=book&is_parent=0`),
    ])
    if(response[0]?.data?.success) {
      const books = response[0]?.data
      dispatch({
        type: bookManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: books?.data,
          listDefault: books?.data,
          loading: false
        }
      })
      dispatch({
        type: bookManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          active: 0,
          amount: books?.meta?.per_page,
          total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
          totalItems: books?.meta?.total,
        }
      })
    }

    if(response[1]?.data?.length > 0) {
      dispatch({
        type: bookManagementActions.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
        payload: {
          list: response[1]?.data,
          listOrigin: response[1]?.data
        }
      })
    }
  }


  const querySearch = {
    per_page: state?.table?.pagination?.amount || 20,
    category_id: state.filter?.category?.value?.id || '',
    status: state.filter?.status?.value?.value || '',
    keyword: state.filter?.search?.value || '',
  }

  const handleAmountChange = async n => {
    const qs = {...querySearch, per_page: n, page: 1}
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchBookByFilter(queryString)

    dispatch({
      type: bookManagementActions.TABLE_SELECTED_LIST_UPDATE,
      payload: {selected: {list: []}},
    })
  }

  const handlePageChange = async page => {
    const qs = {
      ...querySearch,
      per_page: state?.table?.pagination?.amount,
      start: (page * state?.table?.pagination?.amount) || 0,
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchBookByFilter(queryString)
  }

  const fetchBookByFilter = async (qs) => {
    dispatch({
      type: bookManagementActions.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/product/book/books${qs}`)
    ])
    if (response[0]?.data?.success) {
      dispatch({
        type: bookManagementActions.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const books = response[0]?.data
      dispatch({
        type: bookManagementActions.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: books?.data,
          listDefault: books?.data,
          loading: false
        }
      })
      dispatch({
        type: bookManagementActions.TABLE_UPDATE_PAGINATION,
        payload: {
          totalItems: books?.meta?.total || 0,
          active: books?.meta?.start/books?.meta?.per_page,
          amount: books?.meta?.per_page,
          total: books?.meta?.total
            ? Math.ceil(
              books?.meta?.total / books?.meta?.per_page,
            )
            : 0,
        }
      })
    }
  }
  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    }
  }
}

export default useBookManagement
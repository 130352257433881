import { PATH } from '../const/path'
import UpdateProfile from '../Pages/updateProfile'
import { UserPage } from '../Pages/userManagement'
import { CreateUser } from '../Pages/userManagement/createUser'
import { UserRole } from '../Pages/userRole'
import { UserRoleCreate } from '../Pages/userRole/create'
import ComingSoon from '../Pages/comingSoon'
import Category from '../Pages/productGroup'
import BookManagement from '../Pages/products/book'
import DigitalManagement from '../Pages/products/digital'
import CreateProduct from '../Pages/creatProduct'
import CreateDigitalProduct from '../Pages/creatDigitalProduct'
import NotPermission from '../Pages/permision/notPermission'
import BannerManagement from '../Pages/bannerManagement'
import SaleComponent from "../Pages/sale";
import SaleCreate from "../Pages/sale/component/createSale";
import { OrderBook } from '../Pages/orderBook'
import { EditOrderBook } from '../Pages/orderBook/edit'
import {LicenseKey} from "../Pages/licenceKey";
import { OrderDigital } from '../Pages/orderDigital'
import { CustomerPage } from '../Pages/customer'
import { InvoicePage } from '../Pages/invoice'
import { CreateInvoicePage } from '../Pages/invoice/create'
import { RevenueReport } from '../Pages/revenue'
import { CommissionReport } from '../Pages/commission'
import ProductOfCustomer from "../Pages/productOfCustomer";

export const userRoleGroup = [
  {path: PATH.USER_MANAGEMENT, component: UserPage},
  {path: PATH.CREATE_USER, component: CreateUser},
  {path: PATH.UPDATE_USER, component: CreateUser},
  // {path: PATH.USER_ROLE, component: UserRole},
  // {path: PATH.USER_ROLE_CREATE, component: UserRoleCreate},
  // {path: PATH.USER_ROLE_EDIT, component: UserRoleCreate},
  {path: PATH.CONFIG_PAYMENT, component: ComingSoon},
  {path: PATH.CONFIG_NOTIFICATION, component: ComingSoon},
]
export const profileGroup = [
  {path: PATH.UPDATE_PROFILE, component: UpdateProfile},
]
export const categoryGroup = [
  {path: PATH.CATEGORY, component: Category},
]
export const productGroup = [
  {path: PATH.PRODUCT, component: BookManagement},
  {path: PATH.CREATE_PRODUCT, component: CreateProduct},
  {path: PATH.DETAIL_PRODUCT, component: CreateProduct},
  {path: PATH.DIGITAL_PRODUCT, component: DigitalManagement},
  {path: PATH.CREATE_DIGITAL_PRODUCT, component: CreateDigitalProduct},
  {path: PATH.DETAIL_DIGITAL_PRODUCT, component: CreateDigitalProduct},
]
export const orderGroup = [
  {path: PATH.ORDER_BOOK, component: OrderBook},
  {path: PATH.EDIT_ORDER_BOOK, component: EditOrderBook},
  {path: PATH.ORDER_DIGITAL, component: OrderDigital},
  {path: PATH.CREATE_ORDER, component: ComingSoon},
  {path: PATH.CREATE_DIGITAL_ORDER, component: ComingSoon},
  {path: PATH.DETAIL_DIGITAL_ORDER, component: ComingSoon},
]
export const customerGroup = [
  {path: PATH.SALE, component: SaleComponent},
  {path: PATH.CREATE_SALE, component: SaleCreate},
  {path: PATH.DETAIL_SALE, component: SaleCreate},
  {path: PATH.CUSTOMER, component: CustomerPage},
  {path: PATH.PRODUCT_OF_CUSTOMER, component: ProductOfCustomer},
  {path: PATH.CREATE_CUSTOMER, component: ComingSoon},
  {path: PATH.DETAIL_CUSTOMER, component: ComingSoon},
]

export const statisticalGroup = [
  {path: PATH.REVENUE, component: RevenueReport},
  {path: PATH.DETAIL_REVENUE, component: ComingSoon},
  {path: PATH.COMMISSION, component: CommissionReport},
  {path: PATH.DETAIL_COMMISSION, component: ComingSoon},
]
export const accountantGroup = [
  {path: PATH.COMMISSION_VOUCHER, component: InvoicePage},
  {path: PATH.CREATE_COMMISSION_VOUCHER, component: CreateInvoicePage},
]
export const bannerGroup = [
  {path: PATH.BANNER, component: BannerManagement},
]
export const keyProductGroup = [
  // {path: PATH.KEY_PRODUCT, component: LicenseKey},
]
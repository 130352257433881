import {useEffect, useState} from "react"
import {useContext} from "react"
import {ProductGroup} from "../provider/_context"
import {useProductAction} from "../provider/_reducer"
import {SCRIPT_NOTE_PRODUCT} from "../interface/script";
import {getData, postData, sendRequestAuth} from "../../../api/api";
import {createProductGroup, detailProductGroup, updateProductGroup} from "../../../api/url";
import {useTranslation} from "react-i18next";
import useAlert from '../../../hook/useAlert'
import StringUtils from '../util/string'
import useProductGroupFilter from "./useProductGroupFilter";
import {bannerManagementActions} from "../../bannerManagement/provider/_init";
import {removeAcent} from "../../../common/fieldText/_functions";
import config from "../../../config";
import {LINK_BANNER} from "../../bannerManagement/components/modal/component/linkTo";

export const useModal = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(ProductGroup)
   const [animate, setAnimate] = useState(false)
   const [status, setStatus] = useState(pageState.status_product)
   const {methods} = useProductGroupFilter()
   const {showAlert} = useAlert()
   const handleClose = () => {
      if (!pageState.change_modal) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
         pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
         pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: '', id: ''}})
         pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: false, message: ''}
         })
         pageDispatch({
            type: useProductAction.VALID_NAME_PRODUCT,
            payload: {status: false, message: ''}
         })
         pageDispatch({
            type: useProductAction.VALID_NOTE,
            payload: {status: false, message: ''}
         })
         pageDispatch({type: useProductAction.GET_ID, payload: ''})
         pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
      } else {

         pageDispatch({type: useProductAction.MODAL_CONFIRM, payload: true})
      }
   }
   const checkBeforeSubmit = [
      pageState.valid?.filde_name?.status,
      pageState.valid?.filde_parent?.status,
   ].includes(true)

   const {image} = pageState
   const handleUploadImage = async () => {
      const formData = new FormData()
      formData.append('image', pageState?.image?.file)
      const response = await postData(`${config.API}/banner/upload`, formData)
      return response
   }

   const editGroupProduct = async (new_product) => {
      const res = await postData(updateProductGroup(pageState.id_product), new_product)
      if (res.data.success) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
         pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
         pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: null})
         pageDispatch({type: useProductAction.GET_ID, payload: ''})
         pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
         pageDispatch({type: useProductAction.CREATE_BANNER_DESCRIPTION_UPDATE, payload: ''})
         pageDispatch(
            {
               type: useProductAction.UPDATE_FORM_CREATE,
               payload: {
                  image: {
                     file: null,
                     link: null
                  }
               }
            }
         )

         pageDispatch({
            type: useProductAction.CREATE_BANNER_CATEGORY_UPDATE,
            payload: {
               value: null
            }
         })

         pageDispatch({
            type: useProductAction.CREATE_BANNER_PRODUCT_UPDATE,
            payload: {
               value: null
            }
         })
         showAlert({
            type: 'success',
            content: t(SCRIPT_NOTE_PRODUCT.UPDATE_SUCCESS)
         })
         methods.applyOtherFilter()
      } else {
         errorResponse(res?.data?.errors)
      }
   }
   const handleCreateProductGroup = async (new_product) => {
      const res = await postData(createProductGroup(), new_product)
      if (res.data.success) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
         pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
         pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
         pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: null})
         pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
         pageDispatch({type: useProductAction.SET_SEARCH, payload: ''})
         pageDispatch({type: useProductAction.CREATE_BANNER_DESCRIPTION_UPDATE, payload: ''})
         pageDispatch(
            {
               type: useProductAction.UPDATE_FORM_CREATE,
               payload: {
                  image: {
                     file: null,
                     link: null
                  }
               }
            }
         )

         pageDispatch({
            type: useProductAction.CREATE_BANNER_CATEGORY_UPDATE,
            payload: {
               value: null
            }
         })

         pageDispatch({
            type: useProductAction.CREATE_BANNER_PRODUCT_UPDATE,
            payload: {
               value: null
            }
         })
         showAlert({
            type: 'success',
            content: t(SCRIPT_NOTE_PRODUCT.CREATE_SUCEESS)
         })
         methods.applyOtherFilter()
      } else {
         errorResponse(res?.data?.errors)
      }
   }
   const handleAccept = async () => {
      if (!checkBeforeSubmit) {
         if (pageState.name_product.title === '') {
            pageDispatch({
               type: useProductAction.VALID_NAME_PRODUCT,
               payload: {
                  status: pageState.name_product?.title === '' ? true : false,
                  message: t(SCRIPT_NOTE_PRODUCT.VALID_NAME.EMPTY_NAME)
               }
            })
         } else {
            if (image?.file) {
               const uploadResponse = handleUploadImage()

               uploadResponse.then(res => {
                  const new_product = {
                     image: res.data?.file_url,
                     title: pageState.name_product,
                     parent: pageState.item_category?.id || '0',
                     status: pageState.status_category?.value,
                     type: "book",
                     'link_type': pageState?.linkTo?.value || 'none',
                     'link_id': pageState?.linkTo?.value === 'category' ? pageState.category.value?.id : pageState?.linkTo?.value === 'product' ? pageState.product.value?.id : '',
                     'description': pageState?.description || '',
                  }
                  try {
                     if (!!pageState?.id_product) {
                        editGroupProduct(new_product)
                     } else {
                        handleCreateProductGroup(new_product)
                     }
                  } catch (e) {
                     console.log(e)
                  }
               })
            } else {

               // pageDispatch({
               //    type: useProductAction.SET_LOADING,
               //    payload: true
               // })

               const new_product = {
                  image: image?.link,
                  title: pageState.name_product,
                  parent: pageState.item_category?.id || '0',
                  status: pageState.status_category?.value,
                  type: "book",
                  'link_type': pageState?.linkTo?.value || 'none',
                  'link_id': pageState?.linkTo?.value === 'category' ? pageState.category.value?.id : pageState?.linkTo?.value === 'product' ? pageState.product.value?.id : '',
                  'description': pageState?.description || '',
               }
               await editGroupProduct(new_product)
            }
         }

      }
   }
   const errorResponse = response => {
      response?.map(res => {
         switch (res.field) {
            case 'title':
               pageDispatch({
                  type: useProductAction.VALID_NAME_PRODUCT,
                  payload: {status: true, message: res.message}
               })
               break
            case 'parent':
               pageDispatch({
                  type: useProductAction.VALID_PARENT_CATEGORY,
                  payload: {status: true, message: res.message}
               })
               break
            default:
               break
         }
      })
   }

   const PRODUCT_GROUP_STATUS = [
      {name: 'Đang hoạt động', value: 'active'},
      {name: 'Không hoạt động', value: 'inactive'},
   ]
   const getDetailProduct = async productId => {
      try {
         const res = await getData(detailProductGroup(pageState.id_product || productId))
         if (res.data.success) {
            let detail = res.data.data
            const item = pageState.category_list.find(find => find.id == detail.parent)
            const list = pageState.listCategory
            const findParent = list.find(item => {
               if (item.title === detail.title) return item
            })
            if (findParent?.childs.length > 0) pageDispatch({type: useProductAction.DISABLE_SELECT, payload: true})
            else pageDispatch({type: useProductAction.CHECK_PARENT, payload: detail.title})
            if (item !== undefined) pageDispatch({
               type: useProductAction.PICK_ITEM_CATEGORY,
               payload: item
            })
            pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: detail.title})
            pageDispatch({
               type: useProductAction.CHANGE_STATUS_CATEGORY,
               payload: PRODUCT_GROUP_STATUS.find(item => item.value === detail.status)
            })
            pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: detail?.note || ''})
            pageDispatch({type: useProductAction.VALID_PARENT_CATEGORY, payload: {status: false, message: ''}})
            pageDispatch({
               type: useProductAction.UPDATE_FORM_CREATE, payload: {
                  description: detail?.description,
                  image: {
                     link: detail?.image,
                     file: null
                  },
                  linkTo: !!detail?.link_type ? LINK_BANNER.find(link => link.value === detail?.link_type) : null,
                  category: {
                     ...pageState.category,
                     value: (detail?.link_type === 'category') ? pageState.category.list.find(cate => +cate.id === +detail?.link_id) : null,
                  },
                  product: {
                     ...pageState.product,
                     value: (detail?.link_type === 'product') ? pageState.product.list.find(product => product.id === detail?.link_id) : null,
                  },
               }
            })

         }
      } catch (e) {
         console.log(e)
      }
   }
   const handleCancelConfirm = () => {
      pageDispatch({type: useProductAction.MODAL_CONFIRM, payload: false})
   }
   const handleAcceptConfirm = () => {
      pageDispatch({type: useProductAction.MODAL_CONFIRM, payload: false})
      pageDispatch({type: useProductAction.CHANGE_MODAL, payload: false})
      setAnimate(true)
      setTimeout(() => {
         pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
         setAnimate(false)
      }, 300)
      pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: ''})
      pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
      pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
      pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
      pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: null})
      pageDispatch({
         type: useProductAction.VALID_CODE_PRODUCT,
         payload: {status: false, message: ''}
      })
      pageDispatch({
         type: useProductAction.VALID_NAME_PRODUCT,
         payload: {status: false, message: ''}
      })
      pageDispatch({
         type: useProductAction.VALID_NOTE,
         payload: {status: false, message: ''}
      })
      pageDispatch({type: useProductAction.GET_ID, payload: ''})
      pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
   }
   const onPickup = (item) => {
      pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: item})
      pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
      pageDispatch({type: useProductAction.VALID_PARENT_CATEGORY, payload: {status: false, message: ''}})
      pageDispatch({
         type: useProductAction.VALID_NAME_PRODUCT,
         payload: {status: false, message: ""}
      })
   }
   const onChangeCodeProduct = (e) => {
      pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
      pageDispatch({
         type: useProductAction.VALID_CODE_PRODUCT,
         payload: {status: false, message: ''}
      })
      pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: e.target.value})

   }
   const onChangeStatus = (item) => {
      pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
      pageDispatch({type: useProductAction.CHANGE_STATUS_CATEGORY, payload: item})

   }
   const onBlurCodePrduct = e => {
      const {value} = e.target;
      // const regex = REGEX_CUSTOMER_CODE

      const regex = /^[a-zA-Z0-9_.-]*$/
      if (value == '') {
         pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_CODE.EMPTY_CODE)}
         })
      } else if (regex.test(value)) {
         pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: false, message: ''}
         })
         pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: value})
      } else if (value.length > 80) pageDispatch({
         type: useProductAction.VALID_CODE_PRODUCT,
         payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_CODE.MAX_CODE)}
      })
      else pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_CODE.SPECIAL_CODE)}
         })
   }

   const onChangeNameProduct = (e) => {
      let {value} = e.target;
      pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
      pageDispatch({
         type: useProductAction.VALID_NAME_PRODUCT,
         payload: {status: false, message: ''}
      })
      pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: value})
   }
   const onBlurNameProduct = (e) => {
      const {value} = e.target;
      if (value == '') pageDispatch({
         type: useProductAction.VALID_NAME_PRODUCT,
         payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_NAME.EMPTY_NAME)}
      })
      else pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: value.trim()})
   }

   const onChangeNote = value => {
      pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
      if (value.length > 255) {
         pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: value})
         pageDispatch({
            type: useProductAction.VALID_NOTE,
            payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_NOTE.MAX_NOTE)}
         })
      } else {
         pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: value})
         pageDispatch({
            type: useProductAction.VALID_NOTE,
            payload: {status: false, message: ''}
         })
      }
   }
   const onBlurNoteProduct = (value) => {

   }
   // const onChangeStatus = () => {
   //     pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
   //     if (status) {
   //         setStatus(!status)
   //         pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: -1})
   //     } else {
   //         setStatus(!status)
   //         pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
   //     }
   // }

   const searchkeyWord = (data) => {
      const formatDataValue = data?.value
         ? StringUtils.removeAcent(data?.value?.toLowerCase())
         : ''
      const listData = pageState.category_list_origin?.filter(item => {
         const formatNameItem = item?.title
            ? StringUtils.removeAcent(item?.title.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue.trim())) return true
         return false
      })
      pageDispatch({type: useProductAction.CATEGORY_LIST, payload: listData})
      pageDispatch({type: useProductAction.KEY_WORD, payload: data?.value})
   }

   // LINK TO

   const onChangeLinkTo = data => {

      pageDispatch({
         type: useProductAction.CREATE_BANNER_CATEGORY_UPDATE,
         payload: {
            value: null
         }
      })

      pageDispatch({
         type: useProductAction.CREATE_BANNER_PRODUCT_UPDATE,
         payload: {
            value: null
         }
      })

      pageDispatch({
         type: useProductAction.CREATE_BANNER_VALIDATE_UPDATE,
         payload: {
            product: '',
            category: ''
         }
      })
      pageDispatch({type: useProductAction.CREATE_BANNER_LINK_TO_UPDATE, payload: data})
   }

   // CATEGORY
   const categoryList = pageState?.category?.list
   const categoryListOrigin = pageState?.category?.listOrigin
   const categoryValue = pageState?.category?.value

   const handleCategoryChange = value => {
      pageDispatch({
         type: useProductAction.CREATE_BANNER_CATEGORY_UPDATE,
         payload: {
            value: value
         }
      })
      pageDispatch({
         type: useProductAction.CREATE_BANNER_VALIDATE_UPDATE,
         payload: {
            category: ''
         }
      })
   }

   const handleCategoryKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const categoryListData = categoryListOrigin.filter(item => {
         const formatNameItem = item?.title
            ? removeAcent(item?.title.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue?.trim())) return true
         return false
      })
      pageDispatch({
         type: useProductAction.CREATE_BANNER_CATEGORY_UPDATE,
         payload: {
            list: categoryListData
         }
      })
   }

   // PRODUCT

   const productList = pageState?.product?.list
   const productListOrigin = pageState?.product?.listOrigin
   const productValue = pageState?.product?.value

   const handleProductChange = value => {
      pageDispatch({
         type: useProductAction.CREATE_BANNER_PRODUCT_UPDATE,
         payload: {
            value: value
         }
      })

      pageDispatch({
         type: useProductAction.CREATE_BANNER_VALIDATE_UPDATE,
         payload: {
            product: ''
         }
      })
   }

   let productKeywordTimeout
   const fetchProduct = async (keyword) => {
      const res = await sendRequestAuth(
         'get',
         `${
            config.API
         }/order/filter/books?keyword=${keyword}&category_id=&status=&per_page=999&start=`,
      )
      return res
   }
   const handleProductKeywordChange = async data => {
      clearTimeout(productKeywordTimeout)

      productKeywordTimeout = setTimeout(() => {
         // pageDispatch({ type: orderActions.FILTER_PRODUCT_ENABLE_LOADING })
         const keyword = data?.value || ''
         const response = fetchProduct(data?.value)
         response.then(res => {
            if (res?.status === 200) {
               const productListData = res?.data || []

               pageDispatch({
                  type: useProductAction.CREATE_BANNER_PRODUCT_UPDATE,
                  payload: {
                     isFetchNew: true,
                     keyword: keyword,
                     list: productListData,
                  },
               })
            }
         })
      }, 500)

   }

   const onChangeDescription = data => {
      pageDispatch({type: useProductAction.CREATE_BANNER_DESCRIPTION_UPDATE, payload: data.target?.value || ''})
   }
   return {
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
      },
      animate,
      onPickup,
      field_name: {
         onChangeNameProduct,
         onBlurNameProduct,
         onChangeLinkTo,
         onChangeDescription
      },
      field_code: {
         onChangeCodeProduct,
         onBlurCodePrduct
      },
      field_status: {
         onChangeStatus,
         onBlurCodePrduct
      },
      onChangeNote,
      onBlurNoteProduct,
      // status_product: {
      //     status,
      //     onChangeStatus,
      // },
      getDetailProduct,
      search: {
         searchkeyWord,
      },

      category: {
         value: categoryValue,
         list: categoryList,
         onChange: handleCategoryChange,
         onKeywordChange: handleCategoryKeywordChange
      },
      product: {
         value: productValue,
         list: productList,
         onChange: handleProductChange,
         onKeywordChange: handleProductKeywordChange
      },
   }
}
import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import { OrderContext } from 'Pages/orderBook/provider/_context'
import { orderActions } from 'Pages/orderBook/provider/_reducer'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { OrderTags } from '../orderTags'
import { OrderDateTime } from './_orderDateTime'
import { OrderSearch } from './_orderSearch'
import { OrderPaymentMethod } from './_orderPaymentMethod'
// import { PaymentStatus } from './_paymentStatus'
import { CustomerType } from './_customerType'
import { OrderType } from './_orderType'
import { UtmSource } from './_utmSource'
import { OrderStatus } from './_orderStatus'
import { StyledOrderFilterForm } from './_styled'
import { OrderProduct } from './_orderProduct'
import { OrderCustomerInfo } from './_ordeCustomerInfo'
// import { OrderPrincipal } from './_orderPrincipal'
import { OrderTeacher } from './_orderTeacher'
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const OrderFilterForm = memo(({ ...props }) => {
  const { canSubmitOtherFilter, functions } = useOrderFilterForm()

  const { pageDispatch } = useContext(OrderContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Promise.all([
        // sendRequestAuth(
        //   'get',
        //   `${config.API}/principal/principals?per_page=999&start=0`,
        // ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/product/book/books?keyword=&category_id=&status=&per_page=999&start=`,
        ),
        sendRequestAuth(
          'get',
          `${
            config.API
          }/order/filter/teachers?keyword=`,
        )
      ])
      pageDispatch({
        type: orderActions.FILTER_ORIGIN_DATA_UPDATE,
        payload: {
          // list: response[0].data?.data?.map(item => ({
          //   name: item?.name || '',
          //   value: item?.id || '',
          // })),
          product: {
            list: response[0].data?.data.map(item => ({
              data: item || null,
              name: item?.title || '',
              value: item?.id || '',
            })),
          },
          // teacher: {
          //   list: response[2].data.map(item => ({
          //     data: item || null,
          //     name: (!!item?.last_name ? `${item?.last_name} ` : '' ) + (!!item?.name ? item?.name : ''),
          //     value: item?.id || '',
          //   })),
          // },
        },
      })
      pageDispatch({
        type: orderActions.FILTER_TEACHER_LIST_UPDATE,
        payload: {
          teacher: {
            list: response[1].data?.map(item => ({
              name: item?.last_name ? item?.last_name + " " + item?.name : item?.name,
              value: item?.id || '',
            })),
          }
        },
      })
    }

    fetchData()
  }, [])

  return (
    <StyledOrderFilterForm {...props}>
      <div
        className="order-filter-form__group order-filter-form__collapse"
        data-collapse={true}
      >
        <OrderSearch/>
        <OrderCustomerInfo/>
        <OrderProduct/>
        {/* <OrderPrincipal/> */}
        <OrderTeacher/>
        {/* <PaymentStatus/> */}
        <CustomerType/>
        <OrderType/>
        <UtmSource/>
        <OrderStatus/>
        <OrderDateTime/>
        <div className="order-filter-form__input-wide flex">
          <Button
            appearance="secondary"
            disabled={!canSubmitOtherFilter}
            size="md-"
            onClick={() =>
              canSubmitOtherFilter && functions.applyOrderOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          <Button
            appearance="secondary"
            size="md-"
            onClick={functions.refresh}
            className={"order-filter-form__btn-refresh"}
          >
            {GLOBAL_ICONS.repeat}
          </Button>
        </div>
      </div>
      <div className="order-filter-form__group" style={{ marginBottom: 4 }}>
        <OrderTags/>
      </div>
    </StyledOrderFilterForm>
  )
})

import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import useAlert from 'hook/useAlert'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import useRevenueRow from 'Pages/revenue/hooks/useRevenueRow'
import { RevenueContext } from 'Pages/revenue/provider/_context'
import React, { useContext, useState } from 'react'
import { RevenueEmpty } from '../../revenueEmpty'
import { formatMoney } from 'util/functionUtil'
import { fDateTimeDMY } from 'util/formatTime'
import { fNumber } from 'util/formatNumber'
import { CellStatusOrder } from '../../../../orderBook/components/orderTable/_cellStatusOrder'
import { THEME_SEMANTICS } from '../../../../../common/theme/_semantics'

export const TBodyByOrder = () => {
  const { pageState } = useContext(RevenueContext)
  const { table } = pageState

  const displayList = table.display.list
  const displayLoading = table.display.loading
  const paginationTotalItems = table.pagination.totalItems
  return (
    <>
      {displayLoading ? (
        Array.from(Array(20), (e, i) => (
          <RevenuePlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map(item => <RevenueTr key={item.id} data={item}/>)
      ) : (
        <RevenueEmpty/>
      )}
    </>
  )
}

const RevenuePlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="revenue-order-table__row">
      {Array.from(Array(12), (e, i) => (
        <Td key={i} className="revenue-order-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const RevenueTr = ({ data, ...props }) => {
  const { showAlert } = useAlert()
  const revenueRow = useRevenueRow(data)
  const { cell, detail, row } = revenueRow
  const { codeRevenue, payment } = cell

  return (
    <>
      <Tr
        {...props}
        className="revenue-order-table__row"
      >
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{!!data?.created_at ? fDateTimeDMY(data?.created_at) : '---'}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{data?.id}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{data?.type == 1 ? 'Đơn hàng sách' : 'Đơn hàng số'}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_amount)}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_discount)}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.sub_total)}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{formatMoney(data?.shipping_fee)}</Text>
        </Td>
        {/*<Td className="revenue-order-table__cell" data-type="td">*/}
        {/*  <Text as={'a'}*/}
        {/*        target={"_blank"}*/}
        {/*        href={`/principal/${data?.principal_id}`}*/}
        {/*        color={THEME_SEMANTICS.delivering}*/}
        {/*        style={{cursor: 'pointer'}} >{data?.principal_name ? ((!!data?.principal_last_name ? (data?.principal_last_name + " ") : ``)  +  data?.principal_name) : '---'}</Text>*/}
        {/*</Td>*/}
        {/*<Td className="revenue-order-table__cell" data-type="td">*/}
        {/*  <Text as={'a'}*/}
        {/*        href={`/principal/${data?.principal_id}?teacherId=${data?.teacher_id}`}*/}
        {/*        target={"_blank"}*/}
        {/*        color={THEME_SEMANTICS.delivering}*/}
        {/*        style={{cursor: 'pointer'}}>{data?.teacher_name ? ((!!data?.teacher_last_name ? (data?.teacher_last_name + " ") : ``)  +  data?.teacher_name) : '---'}</Text>*/}
        {/*</Td>*/}
        <Td className="revenue-order-table__cell" data-type="td">
          <Text>{data?.payment_method_name}</Text>
        </Td>
        <Td className="revenue-order-table__cell" data-type="td">
          <CellStatusOrder id={data.order_status}>
            {data?.order_status_name}
          </CellStatusOrder>
        </Td>
      </Tr>
    </>
  )
}

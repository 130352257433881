import {Text} from '../../../../../common/text'
import {fDateTimeCustom} from '../../../../../util/formatTime'
import {THEME_SEMANTICS} from '../../../../../common/theme/_semantics'
import {Tooltip as TooltipV2} from '../../../../../common/tooltipv2'
import {formatMoney} from '../../../../../util/functionUtil'
import styled from 'styled-components'
import {THEME_COLORS} from '../../../../../common/theme/_colors'

export const NewHistory = ({data, ...props}) => {

  const newData = !!data?.new_data ? JSON.parse(data.new_data) : null

  return (
    <StyledRowTabDetail>
      <div className="row-tab-detail__content row-tab-detail__content-title">
        <Text fontSize={20} fontWeight={600}>Giá trị mới</Text>
      </div>

      <div className="row-tab-detail__content">
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            Thông tin đơn hàng
          </Text>
          {!!newData?.created_at && (
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Ngày mua hàng
              </Text>
              <Text
              >
                {fDateTimeCustom(newData?.created_at, {format: 'HH:mm dd/MM/yyyy'}) || '---'}
              </Text>
            </div>
          )}
          {!!newData?.updated_at && (
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Cập nhật lần cuối
              </Text>
              <Text>
                {fDateTimeCustom(newData?.updated_at, {format: 'HH:mm dd/MM/yyyy'}) || '---'}</Text>
            </div>
          )}
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Ghi chú
            </Text>
            <Text>
              {newData?.order_notes || '---'}</Text>
          </div>
        </div>
        {(!!newData?.customer_phone || !!newData?.customer_address) && (
          <div className="row-tab-detail__content-group">
            <Text as="h4" fontSize={16} lineHeight={22}>
              Thông tin liên hệ
            </Text>
            {!!newData?.customer_name && (
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Tên khách hàng</Text>
                <Text color={THEME_SEMANTICS.delivering}>
                  {newData?.card_code ? newData?.card_code + ' - ' + newData?.customer_name : newData?.customer_name}
                </Text>
              </div>
            )}
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">Mã người liên hệ</Text>
              <Text as="strong">
                {newData?.cntct_code || '---'}
              </Text>
            </div>
            {!!newData?.customer_phone && (
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Người liên hệ</Text>
                <Text as="strong">
                  {newData?.card_code ? newData?.contact_name + ' - ' + newData?.customer_phone : newData?.customer_phone}
                </Text>
              </div>
            )}
          </div>
        )}
        {!!newData?.customer_address && (
          newData?.card_code ? (
              <div className="row-tab-detail__content-group">
                <Text as="h4" fontSize={16} lineHeight={22}>
                  Thông tin địa chỉ
                </Text>
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Mã địa chỉ:</Text>
                  <Text>{newData?.ship_to_code || '---'}</Text>
                </div>
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Địa chỉ:</Text>
                  <TooltipV2 title={newData?.customer_address} className="order-table__tooltipV2">
                    <Text>{newData?.customer_address || '---'}</Text>
                  </TooltipV2>
                </div>
              </div>
            ) :
            <div className="row-tab-detail__content-group">
              <Text as="h4" fontSize={16} lineHeight={22}>
                Thông tin địa chỉ
              </Text>
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Trường học:</Text>
                <TooltipV2 title={newData?.customer_school} className="order-table__tooltipV2">
                  <Text>{newData?.customer_school || '---'}</Text>
                </TooltipV2>
              </div>
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Địa chỉ:</Text>
                <TooltipV2 title={newData?.customer_address} className="order-table__tooltipV2">
                  <Text>{newData?.customer_address || '---'}</Text>
                </TooltipV2>
              </div>
            </div>
        )}

        {!!!props?.detail?.card_code && !!newData?.order_notes && (
          <div className="row-tab-detail__content-group">
            <Text as="h4" fontSize={16} lineHeight={22}>
              Thông tin khác
            </Text>
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Ghi chú đơn hàng:
              </Text>
              <Text>{newData?.order_notes || '---'}</Text>
            </div>
          </div>
        )}
      </div>

      {
        data?.action === 'sap_update_order' && (
          <>
            <div className="row-tab-detail__info-table">
              <div>
                <Text as="b" fontSize={16} lineHeight={22}>
                  Thông tin sản phẩm{' '}
                </Text>
                <Text>
                  (Tổng số lượng:{' '}
                  {Array.isArray(newData?.details)
                    ? newData.details.length
                    : '0'}
                  )
                </Text>
              </div>
            </div>
            {Array.isArray(newData?.details) && newData.details.length > 0 && (
              <div className="row-tab-detail__table">
                <div className="row-tab-detail__thead">
                  <div className="row-tab-detail__tr">
                    <div className="row-tab-detail__th">Mã sản phẩm/SKU</div>
                    <div className="row-tab-detail__th">Tên sản phẩm</div>
                    <div className="row-tab-detail__th">Số lượng</div>
                    <div className="row-tab-detail__th">Đơn giá</div>
                    <div className="row-tab-detail__th">Chiết khấu</div>
                    <div className="row-tab-detail__th">Thành tiền</div>
                  </div>
                </div>
                <div className="row-tab-detail__tbody">
                  {newData.details.map(item => (
                    <div key={item.product_id} className="row-tab-detail__tr">
                      <div
                        className="row-tab-detail__td"
                        title={item?.product_sku}
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item?.product_sku || '---'}
                      </div>
                      <div className="row-tab-detail__td">
                        <Text
                          color={THEME_SEMANTICS.delivering}
                          style={{cursor: 'pointer'}}
                        >
                          {item?.product_name || '---'}
                        </Text>
                        <span style={{cursor: 'pointer'}}></span>
                      </div>
                      <div className="row-tab-detail__td">
                        {item?.quantity
                          ? Number(item.quantity) < 10
                            ? `0${item.quantity}`
                            : item.quantity
                          : '---'}
                      </div>
                      <div className="row-tab-detail__td">
                        {formatMoney(item?.price)}
                      </div>
                      <div className="row-tab-detail__td">
                        {formatMoney(Number(item?.discount_value) * Number(item?.quantity || 1))}
                        {` ( -${item?.discount}% )`}
                      </div>
                      <div className="row-tab-detail__td">
                        {formatMoney(Number(item?.price) * Number(item?.quantity || 1))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )
      }
    </StyledRowTabDetail>
  )
}

export const OldHistory = ({data, ...props}) => {

  const oldData = !!data?.old_data ? JSON.parse(data.old_data) : null

  return (
    <StyledRowTabDetail>
      <div className="row-tab-detail__content row-tab-detail__content-title ">
        <Text fontSize={20} fontWeight={600}>Giá trị cũ</Text>
      </div>
      <div className="row-tab-detail__content">
        <div className="row-tab-detail__content-group">
          <Text as="h4" fontSize={16} lineHeight={22}>
            Thông tin đơn hàng
          </Text>
          {!!oldData?.created_at && (
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Ngày mua hàng
              </Text>
              <Text
              >
                {fDateTimeCustom(oldData?.created_at, {format: 'HH:mm dd/MM/yyyy'}) || '---'}
              </Text>
            </div>
          )}
          {!!oldData?.updated_at && (
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Cập nhật lần cuối
              </Text>
              <Text>
                {fDateTimeCustom(oldData?.updated_at, {format: 'HH:mm dd/MM/yyyy'}) || '---'}</Text>
            </div>
          )}
          <div className={'row-tab-detail__content-group--items'}>
            <Text as="p" color="#7C88A6">
              Ghi chú
            </Text>
            <Text>
              {oldData?.order_notes || '---'}</Text>
          </div>
        </div>
        {(!!oldData?.customer_phone || !!oldData?.customer_address) && (
          <div className="row-tab-detail__content-group">
            <Text as="h4" fontSize={16} lineHeight={22}>
              Thông tin liên hệ
            </Text>
            {!!oldData?.customer_name && (
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Tên khách hàng</Text>
                <Text color={THEME_SEMANTICS.delivering}>
                  {oldData?.card_code ? oldData?.card_code + ' - ' + oldData?.customer_name : oldData?.customer_name}
                </Text>
              </div>
            )}
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">Mã người liên hệ</Text>
              <Text as="strong">
                {oldData?.cntct_code || '---'}
              </Text>
            </div>
            {!!oldData?.customer_phone && (
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Người liên hệ</Text>
                <Text as="strong">
                  {oldData?.card_code ? oldData?.contact_name + ' - ' + oldData?.customer_phone : oldData?.customer_phone}
                </Text>
              </div>
            )}
          </div>
        )}
        {!!oldData?.customer_address && (
          oldData?.card_code ? (
              <div className="row-tab-detail__content-group">
                <Text as="h4" fontSize={16} lineHeight={22}>
                  Thông tin địa chỉ
                </Text>
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Mã địa chỉ:</Text>
                  <Text>{oldData?.ship_to_code || '---'}</Text>
                </div>
                <div className={'row-tab-detail__content-group--items'}>
                  <Text as="p" color="#7C88A6">Địa chỉ:</Text>
                  <TooltipV2 title={oldData?.customer_address} className="order-table__tooltipV2">
                    <Text>{oldData?.customer_address || '---'}</Text>
                  </TooltipV2>
                </div>
              </div>
            ) :
            <div className="row-tab-detail__content-group">
              <Text as="h4" fontSize={16} lineHeight={22}>
                Thông tin địa chỉ
              </Text>
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Trường học:</Text>
                <TooltipV2 title={oldData?.customer_school} className="order-table__tooltipV2">
                  <Text>{oldData?.customer_school || '---'}</Text>
                </TooltipV2>
              </div>
              <div className={'row-tab-detail__content-group--items'}>
                <Text as="p" color="#7C88A6">Địa chỉ:</Text>
                <TooltipV2 title={oldData?.customer_address} className="order-table__tooltipV2">
                  <Text>{oldData?.customer_address || '---'}</Text>
                </TooltipV2>
              </div>
            </div>
        )}

        {!!!props?.detail?.card_code && !!oldData?.order_notes && (
          <div className="row-tab-detail__content-group">
            <Text as="h4" fontSize={16} lineHeight={22}>
              Thông tin khác
            </Text>
            <div className={'row-tab-detail__content-group--items'}>
              <Text as="p" color="#7C88A6">
                Ghi chú đơn hàng:
              </Text>
              <Text>{oldData?.order_notes || '---'}</Text>
            </div>
          </div>
        )}
      </div>
      {
        data?.action === 'sap_update_order' && (
          <>
            <div className="row-tab-detail__info-table">
              <div>
                <Text as="b" fontSize={16} lineHeight={22}>
                  Thông tin sản phẩm{' '}
                </Text>
                <Text>
                  (Tổng số lượng:{' '}
                  {Array.isArray(oldData?.details)
                    ? oldData.details.length
                    : '0'}
                  )
                </Text>
              </div>
            </div>
            {Array.isArray(oldData?.details) && oldData.details.length > 0 && (
              <div className="row-tab-detail__table">
                <div className="row-tab-detail__thead">
                  <div className="row-tab-detail__tr">
                    <div className="row-tab-detail__th">Mã sản phẩm/SKU</div>
                    <div className="row-tab-detail__th">Tên sản phẩm</div>
                    <div className="row-tab-detail__th">Số lượng</div>
                    <div className="row-tab-detail__th">Đơn giá</div>
                    <div className="row-tab-detail__th">Chiết khấu</div>
                    <div className="row-tab-detail__th">Thành tiền</div>
                  </div>
                </div>
                <div className="row-tab-detail__tbody">
                  {oldData.details.map(item => (
                    <div key={item.product_id} className="row-tab-detail__tr">
                      <div
                        className="row-tab-detail__td"
                        title={item?.product_sku}
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item?.product_sku || '---'}
                      </div>
                      <div className="row-tab-detail__td">
                        <Text
                          color={THEME_SEMANTICS.delivering}
                          style={{cursor: 'pointer'}}
                        >
                          {item?.product_name || '---'}
                        </Text>
                        <span style={{cursor: 'pointer'}}></span>
                      </div>
                      <div className="row-tab-detail__td">
                        {item?.quantity
                          ? Number(item.quantity) < 10
                            ? `0${item.quantity}`
                            : item.quantity
                          : '---'}
                      </div>
                      <div className="row-tab-detail__td">
                        {formatMoney(item?.price)}
                      </div>
                      <div className="row-tab-detail__td">
                        {formatMoney(Number(item?.discount_value) * Number(item?.quantity || 1))}
                        {` ( -${item?.discount}% )`}
                      </div>
                      <div className="row-tab-detail__td">
                        {formatMoney(Number(item?.price) * Number(item?.quantity || 1))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          </>
        )
      }
    </StyledRowTabDetail>
  )
}

const StyledRowTabDetail = styled.div`
  margin-top: 24px;
  .row-tab-detail {
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
      &-note {
        display: flex;
        justify-content: right;
        margin-top: -12px;
      }
      &-title{
        padding: 16px 0;
        border-top: 1px solid #e2eaf8;
      }
    }

    &__content-group {
      width: calc(100% / 3 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
      h4 {
        margin-bottom: 12px;
      }
      &--items {
        margin-bottom: 12px;
      }
    }

    &__info-table {
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 18%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 13%;

        text-align: right;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 18%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 13%;

        text-align: right;
      }
      &:nth-child(7) {
        width: 13%;

        text-align: right;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }
  }
`
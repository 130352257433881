import React, { useContext, useState } from 'react'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CreateSaleContext } from '../../provider/_context'
import { Tr } from '../../../../../../layouts/tableLayout/_tr'
import { Td } from '../../../../../../layouts/tableLayout/_td'
import { Text } from '../../../../../../common/text'
import { Button } from '../../../../../../common/button'
import { STORE_UPOS_ICON } from '../../../../../products/book/interface/_icons'
import { GLOBAL_ICONS } from '../../../../../../interface/icon'
import { Tooltip } from '../../../../../../common/tooltip'
import { AddProductModal } from './popup/_addProduct'
import ReactImageFallback from 'react-image-fallback'
import { useCreateProductAction, useModalProductAction } from '../../provider/_action'
import { useCreateProductTable } from '../../hook/useCreateProductTable'
import { Checkbox } from '../../../../../../common/form/checkbox'
import { ConfirmDeleteModal } from './_confirmDeleteModal'
import {formatMoney} from "../../../../../../util/functionUtil";

const TbodyTeacher = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(CreateSaleContext)
  const { productTab } = pageState
  const { table } = productTab
  const { modalProduct } = productTab
  const displayList = table?.display?.list
  const displayListDefault = table?.display?.listDefault
  const displayLoading = table.display.loading
  const paginationAmount = table.pagination.amount
  const paginationTotalItems = table.pagination.totalItems
  const { methods } = useCreateProductTable()
  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(false)

  const showDeleteModalConfirm = (data) => {
    setConfirmDeleteModalData({
      title: 'Xác nhận xóa sản phẩm',
      description: (
        <>
          <Text as={'p'}> Bạn có chắc chắn muốn xóa sản phẩm này ra khỏi danh sách sản phẩm có thể
            bán của hiệu trưởng không?</Text>
          <Text as={'p'} fontWeight={600}>Lưu ý:</Text>
          <ul>
            <li>
              <Text as={'p'}> &bull; Khi xóa sản phẩm, sẽ loại bỏ sản phẩm khỏi danh sách các sản
                phảm mà hiệu trưởng có thể bán.</Text>
            </li>
            <li>
              <Text as={'p'}> &bull; Học sinh của giáo viên trực thuộc hiệu trưởng sẽ không thấy /
                tìm kiếm được các sản phẩm này trên Zalo Mini App</Text>
            </li>
          </ul>
        </>
      ),
      id: data
    })
  }
  return (
    <StyledTBody>
      {displayLoading ? (
        Array.from(Array(paginationAmount), (e, i) => (
          <TeacherPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map((item, key) => <TeacherTr t={t} key={item.id} stt={key} data={item} onDelete={showDeleteModalConfirm}/>)
      ) : (
        <TeacherEmpty t={t} list={displayListDefault}/>
      )}
      <AddProductModal open={modalProduct.show}
                       onClose={() => {
                         methods.applyOtherFilter()
                         pageDispatch({type: useModalProductAction.DISPLAY_MODAL_PRODUCT,payload: false})
                         pageDispatch({type: useModalProductAction.RESET_MODAL_FILTER})
                       }}/>
      {!!confirmDeleteModalData && (
        <ConfirmDeleteModal
          content={confirmDeleteModalData?.description}
          title={confirmDeleteModalData?.title}
          onClose={() => setConfirmDeleteModalData(null)}
          onSubmit={() => {
            methods.removeProductFromSale(confirmDeleteModalData.id)
            setConfirmDeleteModalData(null)
          }}
        />
      )}
    </StyledTBody>
  )
}

export default TbodyTeacher

const TeacherPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="create-teacher-table">
      {Array.from(Array(6), (e, i) => (
        <Td key={i} className="create-teacher-table_cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const TeacherEmpty = ({ list, t, ...props }) => {
  return (
    <StyledTeacherEmpty {...props}>
      <img
        className="create-teacher-table-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}>
        {!!!list ? t('Bạn chưa có danh sách giáo viên nào') : t('no_matching_data')}
      </Text>
      {!!!list && (
        <Button href={'/upos/shop/create'} icon={STORE_UPOS_ICON.plus}>{t('add_new_shop')}</Button>
      )}
    </StyledTeacherEmpty>
  )
}

const TeacherTr = ({ data, t, stt,onDelete, ...props }) => {
  const {methods, selected} = useCreateProductTable()
  const { pageDispatch } = useContext(CreateSaleContext)
  const isSelected = !!selected.list.find(item => item?.id === data?.id)

  const rowCheckboxChange = () =>
    pageDispatch({
      type: useCreateProductAction.TABLE_SELECTED_LIST_UPDATE,
      payload: {
        selected: {
          list: isSelected
            ? selected.list.filter(item => item?.id !== data?.id)
            : [...selected.list, data],
        },
      },
    })

  return (
    <>
      <Tr
        {...props}
        className="create-teacher-table__row"
        onClick={e => {
          rowCheckboxChange()
        }}
      >
        <Td className="create-teacher-table__cell" data-type="td">
          <Checkbox
            checked={isSelected}
            onClick={e => {
              e.stopPropagation()
              rowCheckboxChange()
            }}
            className={"create-teacher-table_cell-checkbox"}
          />
          {+stt + 1}
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <div className="create-teacher-table__cell-image">
            <ReactImageFallback
              src={data?.image}
              fallbackImage={'/img/grid-default.png'}
              alt="img bottm img"
            />
          </div>
          <div>
            <Text as={'p'} fontWeight={600}>{data?.product_sku || '---'}</Text>
            <Text as={'p'} color={"#2150B7"}>{data?.title || '---'}</Text>
          </div>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text>{formatMoney(data?.price || 0)}</Text>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text>{!data?.price_discount ? formatMoney(data?.price_discount || 0) : '---'}</Text>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text>{data?.option_license_term || '---'}</Text>
        </Td>
        <Td
          className="create-teacher-table__cell"
          data-menu="true"
          data-type="td"
          onClick={(e) => {
            e.stopPropagation()
            onDelete(data?.id)
          }}
        >
          <Text
            className="create-teacher-table__edit"
          >
            <Tooltip title={'Xóa sản phẩm'}>{GLOBAL_ICONS.recycle}</Tooltip>
          </Text>
        </Td>
      </Tr>
    </>
  )
}

export const StyledTBody = styled.div`
  .create-teacher-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;

      width: 100vw;
      height: 100vh;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.25);

      img {
        width: 80px;
        height: 80px;
      }
    }

    &__row {
      &:hover {
        .create-teacher-table__delete {
          display: block;
        }
        .create-teacher-table__edit {
          display: block;
        }
      }
    }

    &__cell {
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }

      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 76px;
        display: flex;
        align-items: center;
        .create-teacher-table_cell-checkbox{
          margin-right: 12px;
        }
      }
      &:nth-child(2) {
        flex: 1;
        display: flex;
        align-items: center;
        .create-teacher-table__cell-image{
          height: auto;
          max-height: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          img{
            height: auto;
            max-height: 100%;
            width: 100%
          }
        }
      }
      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 20%;
      }

      &:nth-child(6) {
        width: 100px;
        align-items: center;
        justify-content: center;
      }

    }
    &__edit {
      width: 20px;
      height: 20px !important;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;

      font-size: 12px !important;
      line-height: 24px !important;

      cursor: pointer;
    }

    &__selected-action-dropdown {
      position: relative;

      margin-left: 12px;
    }

    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;

      border-radius: 14px !important;

      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;

      width: 100vw;
      height: 100vh;
    }

    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;

      width: 150px;
      padding: 8px;

      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__selected-action-menu-item {
      padding: 8px;

      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      transition: color 0.25s;

      cursor: pointer;

      &:hover {
        color: #3264FF;
      }
    }

    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledTeacherEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .sale-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`

import React, {useContext, useEffect, useState} from 'react';
import {ProductContext} from "../provider/~context";
import {useTranslation} from "react-i18next";
import {useCategoryAction} from "../provider/~reducer";

const useCategoryRow = data => {
  const {t} = useTranslation()
  const {pageState, pageDispatch} = useContext(ProductContext)
  const {formCreate} = pageState
  const listDefault = formCreate?.categoryType?.listDefault

  const rowCheckboxChange = async () => {
    const newListCate = listDefault.map(cate => {
      let newChildsCate = []
      if (cate.id === data.id) {
        cate.selected = !cate.selected
        if (cate?.childs?.length > 0) {
          newChildsCate = cate.childs.map(child => ({
            ...child,
            selected: cate.selected
          }))
        }
        return {
          ...cate,
          childs: newChildsCate
        }
      }
      return cate
    })

    pageDispatch({
      type: useCategoryAction.UPDATE_CATEGORY_DATA,
      payload: {
        listDefault: newListCate,
        selected: newListCate,
      }
    })
  }

  const handleCheckBoxTabDetail = value => {
    const newListCate = listDefault.map(cate => {
      let newChildsCate = []
      if (cate.id === data.id) {
        if (cate?.childs?.length > 0) {
          let checkSelectedChild = true
          newChildsCate = cate.childs.map(child => {
            if (child.id === value.id) {
              child.selected = !child.selected
            }
            if(!child.selected) checkSelectedChild = false
            return child
          })
          cate.selected = checkSelectedChild
        }

        return {
          ...cate,
          childs: newChildsCate
        }
      }

      return cate
    })
    // console.log(newListCate)
    pageDispatch({
      type: useCategoryAction.UPDATE_CATEGORY_DATA,
      payload: {
        listDefault: newListCate,
        selected: newListCate,
      }
    })
  }
  const childSelected = data?.childs.filter(child => !!child.selected) || []
  const indeterminate = data.childs?.length > 0 && childSelected.length > 0 && childSelected.length < data?.childs?.length
  console.log(indeterminate)
  return {
    row: {
      data,
      indeterminate,
      onCheckboxChange: rowCheckboxChange,
    },
    handleCheckBoxTabDetail,
  }
}

export default useCategoryRow;
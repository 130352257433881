import React from 'react'
export const TableProductHeader = () => {
  return (
     <div className="row-tab-detail__thead">
        <div className="row-tab-detail__tr">
           <div className="row-tab-detail__th">STT</div>
           <div className="row-tab-detail__th">Sản phẩm</div>
           <div className="row-tab-detail__th">Giá bán</div>
           <div className="row-tab-detail__th">Chiết khấu</div>
           <div className="row-tab-detail__th">Thời hạn chiết khấu</div>
        </div>
     </div>
  )
}
import {useReducer} from 'react'
import {productInitialState} from '../provider/~initState'
import {productReducer, useCategoryAction, useProductAction} from '../provider/~reducer'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import {productActions, useModalProductRelateAction} from '../provider/~action'
import {useParams} from 'react-router-dom'

const useProduct = () => {
   const [state, dispatch] = useReducer(productReducer, productInitialState)

   const {customerId} = useParams()

   const getListProduct = async () => {
      const response = await Promise.all([
         sendRequestAuth('get', `${config.API}/product-category/group-categories?type=book&is_parent=0`),
         sendRequestAuth('get', `${config.API}/customer/detail/${customerId}/linked-products?product_name=&category_id=&status&start=0&per_page=20`)
      ])
      if (response[0]?.data?.length > 0) {
         const listCategories = response[0]?.data
         dispatch({
            type: useProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
            payload: {
               list: listCategories,
               listOrigin: listCategories
            }
         })
         dispatch({
            type: useModalProductRelateAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
            payload: {
               list: listCategories,
               listOrigin: listCategories
            }
         })
      }
      if (!!response[1]?.data?.success) {
         const productList = response[1]?.data
         dispatch({
            type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
            payload: {
               list: productList?.data,
               listDefault: productList?.data,
               loading: false
            }
         })

         dispatch({
            type: useProductAction.TABLE_UPDATE_PAGINATION,
            payload: {
               active: Math.ceil(productList?.meta?.start / productList?.meta?.per_page),
               amount: productList?.meta?.per_page,
               total: Math.ceil(productList?.meta?.total / productList?.meta?.per_page),
               totalItems: productList?.meta?.total,
            }
         })
      }
   }
   const getListCategory = async (selectedList) => {
      const response = await sendRequestAuth('get', `${config.API}/product-category/all-categories?type=digital&is_parent=`)
      if (response?.data?.length > 0) {
         const categories = response?.data?.map(item => {
            let countChildSelected = 0
            if (item?.childs?.length > 0) {
               const childCates = item.childs.map(child => {
                  const selected = selectedList.includes(child.id)
                  if (!!selected) countChildSelected += 1
                  return {
                     ...child,
                     selected: selected
                  }
               })
               return {

                  ...item,
                  selected: item.childs.length === countChildSelected,
                  childs: childCates
               }

            } else {
               return {
                  ...item,
                  selected: !!selectedList.includes(item.id)
               }
            }
         })
         dispatch({
            type: useCategoryAction.UPDATE_CATEGORY_DATA,
            payload: {
               listDefault: categories,
               selected: categories,
            }
         })
      }

   }
   const handleOriginFetch = async () => {
      const response = await Promise.all([
         sendRequestAuth('get', `${config.API}/customer/detail/${customerId}`),
      ])
      if (response[0]?.data?.success) {
         const customerDetail = response[0]?.data?.data
         dispatch({
            type: productActions.UPDATE_CUSTOMER_DATA, payload: customerDetail
         })

         dispatch({
            type: productActions.SETTING_DEFAULT_QUANTITY,
            payload: +customerDetail?.quantity_default || ''
         })
         dispatch({
            type: useProductAction.UPDATE_TYPE_PRODUCT_CONFIG,
            payload: {
               value: customerDetail?.product_sale_type
            }
         })
         if (customerDetail?.product_sale_type === 'category') {
            const productSaleCategories = customerDetail?.product_sale_categories.split(',')
            getListCategory(productSaleCategories)
         }
         if (customerDetail?.product_sale_type === 'product') {
            getListProduct()
         }
      }

   }

   return {
      fetch: {
         origin: handleOriginFetch
      },
      provider: {
         state,
         dispatch
      }
   }
}
export default useProduct
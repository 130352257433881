import {Input} from 'common/form/input';
import {Text} from 'common/text';
import {THEME_SEMANTICS} from 'common/theme/_semantics';
import {useModal} from 'Pages/productGroup/hook/useModal';
import {ProductGroup} from 'Pages/productGroup/provider/_context';
import React, {useEffect, useState} from 'react';
import {useContext} from 'react';
import {StyledProductGroupContentModal} from './_styled';
import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete';
import {Tooltip} from 'common/tooltipv2';
import {Tooltip as TooltipV1} from 'common/tooltip';
import {useTranslation} from "react-i18next";
import {AutoCompleteSingleOption} from '../autocompleteSingleOption'
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {LinkTo} from "./component/linkTo";
import {ProductCategory} from "./component/_category";
import {ProductBanner} from "./component/_product";
import {StyledInfoProduct} from "../../../creatDigitalProduct/createProduct/conponents/infoProduct";
import styled from "styled-components";
import {Galleries} from "../../../bannerManagement/components/modal/contentModal";
import useAlert from "../../../../hook/useAlert";
import {useProductAction} from "../../provider/_reducer";
import {Textarea} from "../../../../common/form/textarea";

const Index = ({...props}) => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(ProductGroup)
   const {onPickup, field_name, field_status, search} = useModal()
   const disabled = pageState.disabled

   const images = pageState?.image
   const {showAlert} = useAlert()

   const [validates, setValidates] = useState({
      images: {overload: false},
   })

   const handleImageFileChange = e => {
      const files = e.target.files
      let count = 0
      const item = files[0]
      if (
         !['image/jpg', 'image/jpeg', 'image/png'].includes(
            item.type.toLowerCase(),
         )
      ) {
         showAlert({
            type: 'danger',
            content: 'Chỉ hỗ trợ các định dạng: .jpg, .jpeg, .png.',
         })
      }
      if (item?.size <= 3000000) {
         pageDispatch(
            {
               type: useProductAction.UPDATE_FORM_CREATE,
               payload: {
                  image: {
                     file: item,
                     link: window.URL.createObjectURL(item)
                  }
               }
            }
         )
         pageDispatch(
            {
               type: useProductAction.CHANGE_MODAL,
               payload: true
            }
         )
         // pageDispatch(
         //   {
         //     type: bannerManagementActions.CREATE_BANNER_VALIDATE_UPDATE,
         //     payload: {
         //       image: ''
         //     }
         //   }
         // )
      } else
         showAlert({
            type: 'danger',
            content: 'Ảnh tải lên không được vượt quá 3MB',
         })
   }

   const handleImageDelete = id => {
      pageDispatch(
         {
            type: useProductAction.UPDATE_FORM_CREATE,
            payload: {
               image: {
                  file: null,
                  link: null
               }
            }
         }
      )
      pageDispatch(
         {
            type: useProductAction.CHANGE_MODAL,
            payload: true
         }
      )
   }

   const PRODUCT_GROUP_STATUS = [
      {name: 'Đang hoạt động', value: 'active'},
      {name: 'Không hoạt động', value: 'inactive'},
   ]
   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className='product-group-content_group'>
               <Input
                  {...props}
                  label={
                     <>
                        {t('product_page_name_group_product')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                     </>
                  }
                  autoComplete={'false'}
                  maxLength={80}
                  value={pageState.name_product}
                  placeholder={t('product_page_enter_name_group_product')}
                  validateText={pageState.valid.filde_name.status ? pageState.valid.filde_name.message : null}
                  validateType={!pageState.valid.filde_name.status ? 'success' : 'danger'}
                  onChange={(e) => field_name.onChangeNameProduct(e)}
                  onBlur={(e) => field_name.onBlurNameProduct(e)}
               />
            </div>
            <div className='product-group-content_group'>
               <AlternativeAutoComplete
                  {...props}
                  inputProps={{
                     categoryList: [], // menu list in category dropdown
                     categoryValue: {name: '', value: ''}, // if not exist this value -> default category: categoryList[0]
                     // categoryWidth: 140,
                     categoryHidden: true,
                     label: (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                           <Text style={{marginRight: 4}}>{t('product_page_parent_group_product')}</Text>
                           <TooltipV1
                              title={'Nếu bạn bỏ trống Danh mục cha, hệ thống sẽ mặc định quy định đây là danh mục cấp 1 và không thể thay đổi'}>{GLOBAL_ICONS.question}</TooltipV1>
                        </div>
                     ),
                     placeholder: t('product_page_select_parent_group_product'),
                     readOnly: true,
                     disabled,
                     value: pageState.item_category.title ? pageState.item_category.title : '',

                  }}
                  menuProps={{
                     empty: pageState.category_list.length <= 0 ? t('product_page_empty_group_product') : '',
                  }}
                  searchInputProps={{
                     placeholder: t('product_page_find_group_product'),
                     value: pageState.keyword || '',
                     onChange: search.searchkeyWord,
                  }}
                  className={disabled && 'product-group-content_alternative'}
                  validateText={pageState.valid.filde_parent.status ? pageState.valid.filde_parent.message : null}
                  validateType={!pageState.valid.filde_parent.status ? 'success' : 'danger'}
               >
                  {pageState.category_list && disabled === false &&
                  pageState.category_list.map(item => {
                        return (
                           <AutoCompleteSingleOption
                              key={item.id}
                              data-active={item.id === pageState.item_category?.id}
                              onClick={() => onPickup(item)}
                           >
                              <Tooltip className='tooltip_select' title={item.item} baseOn='height'
                                       placement='top-center'>
                                 {item.title}
                              </Tooltip>
                           </AutoCompleteSingleOption>)
                     }
                  )}
               </AlternativeAutoComplete>
            </div>
            <div className='product-group-content_group'>
               <AlternativeAutoComplete
                  {...props}
                  inputProps={{
                     categoryList: [], // menu list in category dropdown
                     categoryValue: {name: '', value: ''}, // if not exist this value -> default category: categoryList[0]
                     categoryHidden: true,
                     label: 'Trạng thái',
                     value: t(pageState?.status_category?.name) || '',

                  }}
                  hideSearchBar={true}
               >
                  {PRODUCT_GROUP_STATUS.map(item => (
                        <AutoCompleteSingleOption
                           key={item.value}
                           data-active={item.value === pageState?.status_category?.value}
                           onClick={() => field_status.onChangeStatus(item)}
                        >
                           <Tooltip className='tooltip_select' title={item.name} baseOn='height'
                                    placement='top-center'>
                              {item.name}
                           </Tooltip>
                        </AutoCompleteSingleOption>
                     )
                  )}
               </AlternativeAutoComplete>
            </div>
            <div className='product-group-content_group flex'>
               <div className="product-group-content_group-items">
                  <StyledInfoProduct>
                     <div className={'product-info-product'}>
                        <Text>{t('Hình ảnh')} <Text color={THEME_SEMANTICS.failed}>*</Text></Text>
                        <div className={'product-info-product__img'}>
                           <StyledFacebookResponseContentScriptDetailDrawer>
                              <Galleries
                                 data={images}
                                 validate={validates?.images}
                                 onChange={handleImageFileChange}
                                 onCreateClick={() =>
                                    setValidates({
                                       ...validates,
                                       images: {...validates.images, overload: false},
                                    })
                                 }
                                 onImageDelete={imageId => handleImageDelete(imageId)}
                              />
                           </StyledFacebookResponseContentScriptDetailDrawer>
                        </div>
                        {!!pageState.validate?.image &&
                        <Text color={'#ff424e'} fontSize={12}>{pageState.validate?.image}</Text>}
                     </div>
                  </StyledInfoProduct>
               </div>
               <div className="product-group-content_group-items">
                  <Textarea
                     style={{width: '100%'}}
                     value={pageState?.description}
                     label={'Mô tả'}
                     onChange={field_name.onChangeDescription}/>
               </div>
            </div>

            <div className='product-group-content_group flex' style={{marginTop: 16}}>
               <div className="product-group-content_group-items" style={{marginRight: 8, width: "calc(50% - 8px)"}}>
                  <LinkTo/>
               </div>
               <div className="product-group-content_group-items" style={{marginLeft: 8, width: "calc(50% - 8px)"}}>
                  {pageState?.linkTo?.value === 'category' && <ProductCategory/>}
                  {pageState?.linkTo?.value === 'product' && <ProductBanner/>}
               </div>
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index;


const StyledFacebookResponseContentScriptDetailDrawer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  
  overflow: auto;
  display: flex;
  .file__galleries{
    &-item{
      display: flex;
      align-items: center;
      &:nth-child(1){
        width: 90%;
        margin-right: 8px;
        .file-content{
           width: 100%;
           a{
            width: 100%;
            
            height: 100%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
          }
        }
        
      }
      cursor: pointer;
    }
  }
  .facebook-response-content-script-detail-drawer {
    &__header {
      margin-bottom: 32px;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 64px;
      padding: 0 24px;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      background: #fff;
      border-top: 1px solid #ebeef5;
      z-index: 10
    }

    &__input-group {
      width: calc(100% + 8px);
      margin: 0 -4px 8px -4px;

      display: flex;
      flex-wrap: wrap;
    }

    &__input-item {
      width: calc(50% - 8px);
      margin: 0 4px 16px 4px;

      &[data-size='lg'] {
        width: calc(100% - 8px);
      }

      &[data-size='sm'] {
        width: calc(100% / 3 - 8px);
      }
    }
    &__galleries{
      display: flex;
    }
    &__galleries-list {
      margin: 12px -4px 0 -4px;

      display: flex;
      flex-wrap: wrap;
    }

    &__galleries-item {
      position: relative;

      width: 84px;
      height: 84px;
      margin: 0 4px 8px 4px;

      border-radius: 4px;

      cursor: pointer;

      @media screen and (max-width: 1599px) {
        width: 82px;
        height: 82px;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &__galleries-background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      border-radius: 4px;

      object-fit: cover;
      object-position: center;
    }

    &__galleries-delete {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;

      width: 18px;
      height: 18px;

      transform: translate(50%, -50%);

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__galleries-create {
      width: 84px;
      height: 84px;
      margin: 0 4px 8px 4px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      border: 1px dashed #ebeef5;
      border-radius: 4px;

      cursor: pointer;
    }

    &__loading-modal {
      .modal__body {
        min-height: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

import React, {useContext, useEffect} from 'react'
import {Modal} from '@mui/material'
import styled from 'styled-components'
import {Button} from 'common/button'
import {Text} from 'common/text'
import {FilterModalProduct} from './filter'
import {ProductTable} from './table'
import {sendRequestAuth} from 'api/api'
import config from 'config'
import {useParams} from 'react-router-dom'
import {GLOBAL_ICONS} from "interface/icon";
import {ProductContext} from "../../../../../provider/~context";
import {useModalProductRelateAction} from "../../../../../provider/~action";

export const AddProductModal = ({open, data, onClose}) => {
   const {pageState, pageDispatch} = useContext(ProductContext)
   const {productRelated} = pageState.formCreate
   const {table} = productRelated
   const displayList = table?.display?.list
   const {customerId} = useParams()

   const getProductList = async () => {

      const response = await sendRequestAuth('get', `${config.API}/customer/detail/${customerId}/filter-products?product_name=&category_id=&status&start=0&per_page=200`)

      if (response?.data?.success) {
         const digitals = response?.data
         pageDispatch({
            type: useModalProductRelateAction.TABLE_UPDATE_DISPLAY_LIST,
            payload: {
               list: digitals?.data,
               listDefault: digitals?.data,
               loading: false
            }
         })
         pageDispatch({
            type: useModalProductRelateAction.TABLE_UPDATE_PAGINATION,
            payload: {
               active: 0,
               amount: digitals?.meta?.per_page,
               total: Math.ceil(digitals?.meta?.total / digitals?.meta?.per_page),
               totalItems: digitals?.meta?.total,
            }
         })
      }

   }
   useEffect(() => {
      getProductList()
   }, [displayList])

   return (
      <Modal
         open={open}
         className={'add-product_modal'}
      >
         <StyleResultImport>
            <div className={'add-product'}>
               <Text as="p"
                     fontSize={20}
                     fontWeight={600}
                     color={'#2150B7'}
                     style={{textAlign: 'center', width: '100%'}}
               >Cấu hình sản phẩm bán</Text>
               <Text className={"add-product__close"} onClick={onClose}>{GLOBAL_ICONS.close_payment}</Text>
               <div className={'add-product_filter'}>
                  <FilterModalProduct/>
               </div>
               <div className={'add-product_content'}>
                  <ProductTable/>
               </div>
               <div style={{textAlign: 'center', marginTop: 16}}>
                  <Button
                     size={'sm'}
                     onClick={onClose}
                  >Đóng</Button>
               </div>
            </div>
         </StyleResultImport>
      </Modal>
   )
}
const StyleResultImport = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
    .add-product{
        position: relative;
        padding: 24px;
        width: 720px;
        height: 80vh;
        background: rgb(255, 255, 255);
        border-radius: 8px;
        &__close{
          position: absolute;
          top: 24px;
          right: 24px;
          cursor: pointer;
        }
        &_filter{
          margin-top: 32px;
        }
        &_content{
          padding-top: 16px;
        }
        &_tabs{
            display: flex ;
            align-items: center;
            margin: 24px 0 32px 0;
            gap: 16px;
        }
         &_tab{
            width: calc(50% - 16px);
            padding: 16px 12px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            background: rgb(246, 250, 254);
            border: 1px solid rgb(240, 245, 255);
            border-radius: 8px;
        }
      
    }
`
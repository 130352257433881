import React from 'react';
import {useContext} from 'react';
import {ConfirmModal} from "layouts/rightSightPopup/confirm";
import {Text} from 'common/text';
import "../index.scss"
import {useTranslation} from "react-i18next";
import {CustomerContext} from '../../../provider/_context'
import {useContactModal} from "../../../hooks/useContactModal";

export const ConfirmDeleteContactModal = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(CustomerContext)
   const {confirm} = useContactModal()
   return (
      <ConfirmModal
         openModal={pageState.contactModal.modal_confirm_delete?.open}
         body={<Confirm t={t} data={pageState.contactModal.modal_confirm_delete?.data}/>}
         footer={
            {
               cancel: {
                  width: 110,
                  title: t('general_cancel'),

               },
               acceptance: {
                  width: 110,
                  title: t('confirm')
               },
            }
         }
         footerProps={
            {className: 'product-group-modal_dismiss'}
         }
         closeModal={() => confirm.handleCancelDeleteConfirm()}
         acceptance={() => confirm.handleAcceptDeleteConfirm()}
      />

   )
}

const Confirm = ({t, data}) => {
   return (
      <div style={{marginBottom: '24px'}}>
         <Text
            as={'p'}
            fontSize={20}
            fontWeight={600}
            style={{marginBottom: '16px'}}
         >{t(' Xác nhận xóa thông tin liên hệ')}</Text>
         <Text as='p' className='product-group-modal_txt'>
            Bạn có chắc chắn muốn xóa <Text fontWeight={600}>{data?.contact_name} - {data?.contact_phone}</Text> ra khỏi danh sách liên hệ của khách hàng <Text fontWeight={600}>{data?.customer_name}</Text> không?
         </Text>
      </div>
   )
}
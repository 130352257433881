import {Popover} from '@mui/material'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {useState} from 'react'
import styled from 'styled-components'
import {GLOBAL_ICONS} from 'interface/icon'

export const RowMenuPopover = ({
                                  id,
                                  data,
                                  onActionClick,
                               }) => {

   const ROW_MENU = [
      {
         id: 3,
         name: 'Chỉnh sửa',
         icon: GLOBAL_ICONS.edit,
         action: 'edit',
      },
      {
         id: 1,
         name: data?.status !== 'inactive' ? 'Khóa khách hàng' : ' Mở khóa khách hàng',
         icon: data?.status !== 'inactive'
            ? GLOBAL_ICONS.lockCustomer
            : GLOBAL_ICONS.unlock
         ,
         action: 'lock',
      },
      {
         id: 2,
         name: 'Phân công Sale quản lý',
         icon: GLOBAL_ICONS.sale,
         action: 'circular',
      },
      {
         id: 4,
         name: 'Tắt DTP phát hành 100%',
         icon: GLOBAL_ICONS.offSync,
         action: 'off_sync',
      },
      {
         id: 5,
         name: 'Bật DTP phát hành 100%',
         icon: GLOBAL_ICONS.onSync,
         action: 'on_sync',
      },
      {
         id: 6,
         name: 'Cấu hình sản phẩm bán',
         icon: GLOBAL_ICONS.cart,
         action: 'config_product',
      },
   ]
   const [anchorEl, setAnchorEl] = useState(null)
   const handleClick = e => {
      e.preventDefault()
      e.stopPropagation()
      setAnchorEl(e.currentTarget)
   }

   const handleClose = () => setAnchorEl(null)

   const open = Boolean(anchorEl)
   const idData = open ? id : undefined

   const actionList = ROW_MENU

   const handleItemClick = type => {
      onActionClick(type)
      handleClose()
   }
   console.log(111, data)
   return (
      <>
         <StyledIconToggle
            aria-describedby={idData}
            variant="contained"
            style={{cursor: 'pointer'}}
            onClick={handleClick}
         >
            {GLOBAL_ICONS.manipulation}
         </StyledIconToggle>
         {open && actionList.length > 0 && (
            <Popover
               id={idData}
               className="common-popover"
               open={true}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
            >
               <StyledRowMenuPopover>
                  {actionList.map(item => {
                        if (item.action !== "circular" || (item.action === "circular" && !!!data.teacher_name)) {
                           if (['on_sync', 'off_sync'].includes(item.action)) {
                              if ((data?.auto_sync == 0 && item.action == "on_sync") || (data?.auto_sync == 1 && item.action == "off_sync"))
                                 return (
                                    <li
                                       key={item.id}
                                       className="row-menu-popover__item"
                                       data-danger={item?.isDanger}
                                       onClick={() => handleItemClick(item.action)}
                                    >
                                       {item.icon} <span>{item.name}</span>
                                    </li>
                                 )
                           } else
                              return (
                                 <li
                                    key={item.id}
                                    className="row-menu-popover__item"
                                    data-danger={item?.isDanger}
                                    onClick={() => handleItemClick(item.action)}
                                 >
                                    {item.icon} <span>{item.name}</span>
                                 </li>
                              )
                        }
                     }
                  )}
               </StyledRowMenuPopover>
            </Popover>
         )}
      </>
   )
}

const StyledRowMenuPopover = styled.ul`
  width: 212px;
  padding: 8px 12px;

  .row-menu-popover {
    &__item {
      height: 36px;

      display: flex;
      align-items: center;

      color: #00081d;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      transition: color 0.25s;

      cursor: pointer;

      &[data-danger='true'] {
        &:hover {
          color: ${THEME_SEMANTICS.failed};

          svg {
            color: ${THEME_SEMANTICS.failed};

            path[stroke] {
              stroke: ${THEME_SEMANTICS.failed};
            }

            path[fill] {
              fill: ${THEME_SEMANTICS.failed};
            }
          }
        }
      }

      &:hover {
        color: ${THEME_COLORS.primary_300};

        svg {
          color: ${THEME_COLORS.primary_300};

          path[stroke] {
            stroke: ${THEME_COLORS.primary_300};
          }

          path[fill] {
            fill: ${THEME_COLORS.primary_300};
          }
        }
      }

      svg {
        width: 18px;
        height: 18px;

        margin-right: 10px;
      }
    }
  }
`

const StyledIconToggle = styled.i`
  position: relative;
  z-index: 1;

  &:hover {
    svg {
      color: ${THEME_COLORS.primary_300};

      path[stroke] {
        stroke: ${THEME_COLORS.primary_300};
      }

      path[fill] {
        fill: ${THEME_COLORS.primary_300};
      }
    }
  }
`

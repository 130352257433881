import { useContext, useState } from 'react'
import { useProductAction } from '../provider/~reducer'
import { sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { removeAcent } from 'common/fieldText/_functions'
import {ProductContext} from "../provider/~context";
import {useParams} from "react-router-dom";

export const useProductTab = () => {
  const { pageState, pageDispatch } = useContext(ProductContext)
  const { productTab } = pageState.formCreate
  const { filter } = productTab
  const {customerId} = useParams()

  const searchActiveValue = filter.search.activeValue
  const searchValue = filter.search.value

  const handleSearchChange = value => {
    pageDispatch({
      type: useProductAction.FILTER_SEARCH_UPDATE,
      payload: value,
    })
  }
  // STATUS FILTER
  // ===== ===== ===== ===== =====
  const statusValue = filter?.status?.value
  const statusActiveValue = filter?.status?.activeValue

  const handleStatusChange = value => {
    pageDispatch({
      type: useProductAction.FILTER_STATUS_VALUE_UPDATE,
      payload: value
    })
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // CATEGORY FILTER
  // ===== ===== ===== ===== =====
  const categoryList = filter?.category?.list
  const categoryListOrigin = filter?.category?.listOrigin
  const categoryValue = filter?.category?.value
  const categoryActiveValue = filter?.category?.activeValue

  const handleCategoryChange = value => {
    pageDispatch({
      type: useProductAction.FILTER_CATEGORY_VALUE_UPDATE,
      payload: value
    })
  }

  const handleCategoryKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const categoryListData = categoryListOrigin.filter(item => {
      const formatNameItem = item?.title
        ? removeAcent(item?.title.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    pageDispatch({
      type: useProductAction.FILTER_CATEGORY_LIST_UPDATE,
      payload: categoryListData
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====


  const queries = {
    category_id: categoryValue?.id || '',
    status: statusValue?.value|| '',
    product_name: searchValue || '',
    per_page: 20,
    start: (productTab?.table?.pagination?.active * productTab?.table?.pagination?.amount) || 0,
  }

  const applyOtherFilter = async () => {
    const qs = {
      ...queries,
      start: 0
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyProduct(queryString)
  }


  const handleAmountChange = async n => {
    const qs = { ...queries, per_page: n, start: 0 }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyProduct(queryString)

  }

  const handlePageChange = async page => {
    const amount = productTab?.table?.pagination?.amount || 999
    const qs = {
      ...queries,
      per_page: productTab?.table?.pagination?.amount,
      start: page * amount
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyProduct(queryString)
  }

  const fetchQrbyProduct = async (qs) => {
    pageDispatch({
      type: useProductAction.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/customer/detail/${customerId}/linked-products${qs}`)
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: useProductAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const products = response[0]?.data
      pageDispatch({
        type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: products?.data,
          listDefault: products?.data,
          loading: false
        }
      })
      pageDispatch({
        type: useProductAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(products?.meta?.start / products?.meta?.per_page),
          amount: products?.meta?.per_page,
          total: Math.ceil(products?.meta?.total / products?.meta?.per_page),
          totalItems: products?.meta?.total,
        }
      })

      pageDispatch({
        type: useProductAction.TABLE_SELECTED_LIST_UPDATE,
        payload: {selected: {list: []}},
      })
    }
  }
  return {
    table: productTab.table,
    queries,
    search: {
      value: searchValue,
      activeValue: searchActiveValue,
      onChange: handleSearchChange,
    },
    statusFilter: {
      value: statusValue,
      statusValue: statusActiveValue,
      activeValue: statusActiveValue,
      onChange: handleStatusChange,
    },
    categoryFilter: {
      value: categoryValue,
      activeValue: categoryActiveValue,
      list: categoryList,
      onChange: handleCategoryChange,
      onKeywordChange: handleCategoryKeywordChange
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    },

    methods: {
      applyOtherFilter,
    },
  }
}
import { Option } from '../../../../../common/form/autoComplete/_option'
import { AlternativeAutoComplete } from '../../../../../common/form/autoComplete/_alternativeAutoComplete'
import React, { useContext } from 'react'
import { ProductGroup } from '../../../provider/_context'
import { useModal } from '../../../hook/useModal'

export const LINK_BANNER = [
  { name: 'Danh mục', value: 'category' },
  { name: 'Sản phẩm', value: 'product' },
]
export const LinkTo = () => {

  const { pageState } = useContext(ProductGroup)
  const { field_name } = useModal()

  return (
    <AlternativeAutoComplete
      className="principal-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Link đến', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 82,
        categoryHidden: true,
        placeholder: 'Chọn đích đến của banner', label: (
          <>
            Link đến
          </>
        ),
        readOnly: true,
        value: pageState?.linkTo?.name || '',
        onIconClick: () => field_name.onChangeLinkTo(null)
      }}
      hideSearchBar={true}
    >
      {LINK_BANNER.map(item => (
        <Option
          key={item.value}
          className="principal-filter-form__option-text"
          data-active={item.value === pageState?.position?.value}
          onClick={() => field_name.onChangeLinkTo(item)}
        >
          {item.name}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
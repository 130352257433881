import React, {useContext, useState} from "react";
import useCustomerFilterForm from "../../hooks/useCustomerFilterForm";
import useAlert from "../../../../hook/useAlert";
import {customerActions, useCreateCustomerModalAction} from "../../provider/_reducer";
// import {BOOK_COLUMN_NAMES} from "../../../customer/interfaces/_constants";
import PopupNotPermission from "../../../permision/popupNotPermission";
import {PageHeader} from "../customerPageHeader";
import {CUSTOMER_BREADCRUMB, CUSTOMER_COLUMN_NAMES, CUSTOMER_COLUMN_SALE_NAMES} from "../../interfaces/_constants";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {ImportFileModal} from "../../../../Component/importComponent";
import {ResultImport} from "../../../products/book/components/modal/_resultImport";
import {ModalExport} from "../../../../common/modalExport";
import {CustomerContext} from "../../provider/_context";

export const CustomerHeader = () => {
   const [showPermission, setShowPermission] = useState(false)
   const [exportModalData, setExportModalData] = useState(null)
   const {pageState, pageDispatch} = useContext(CustomerContext)
   const {queries, functions} = useCustomerFilterForm()
   const {showAlert} = useAlert()
   const handleOpenModalCreateCustomer = _ => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL,
         payload: {
            open_modal: true,
            change_modal: true
         }
      })
   }
   const handleExportClick = () => {
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
         ...queries,
         per_page: '',
         start: '',
      })) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }

      handleLargeExport(
         {
            ...queries,
            per_page: '',
            start: '',
         }
      )
   }

   const handleLargeExport = (q) => {
      if (pageState.table.pagination.totalItems <= 0) {
         showAlert({type: 'info', content: `Số lượng khách hàng phải lớn hơn 0`})
         return
      }

      setExportModalData({
         data: {
            query: q,
            total: pageState.table.pagination.totalItems,
         },
         onClose: () => setExportModalData(null),
      })
   }


   const onCloseImport = () => {
      pageDispatch({type: customerActions.MODAL_IMPORT_CUSTOMER, payload: false})
   }

   const onOpenImport = () => {
      pageDispatch({type: customerActions.MODAL_IMPORT_CUSTOMER, payload: true})
   }

   const handleGetResult = res => {
      if (res) {
         pageDispatch({
            type: customerActions.MODAL_IMPORT_RESULT_OPEN,
            payload: {
               open: true,
               data: res
            }
         })
         functions?.applyCustomerOtherFilter()
      }
   }
   const handleCloseResult = _ => {
      pageDispatch({
         type: customerActions.MODAL_IMPORT_RESULT_OPEN,
         payload: {
            open: false,
            data: null
         }
      })
   }

   const handleCollectionData = (data) => {
      let sliceData = data?.slice(
         CUSTOMER_COLUMN_NAMES?.startRowNumber - 1
      )
      if (sliceData.length > 1000) sliceData = sliceData.slice(0, 1000)

      let qs = {
         items: sliceData?.map((map, i) => (
            {
               'row': +CUSTOMER_COLUMN_NAMES?.startRowNumber + i,
               'card_code': map[0],
               'card_name': map[1],
               'region': map[2],
               'region_name': map[3],
               'province': map[4],
               'province_name': map[5],
               'group_code': map[6],
               'group_name': map[7],
               'slp_code': map[8],
               'sale_name': map[9],
               'cntct_code': map[10],
               'contact_name': map[11],
               'contact_phone': map[12],
               'ship_to_code': map[13],
               'address': map[14],
               'auto_sync': map[15],
            }
         ))
      }
      return qs
   }

   const handleCollectionCsv = data => {
      const sliceData = data?.slice(4)
      const keys = sliceData?.map(map => Object.keys(map))[0]
      let qs = {
         items: sliceData?.map((map, i) => (
            {
               'row': +CUSTOMER_COLUMN_NAMES?.startRowNumber + i,
               'card_code': map[keys[0]],
               'card_name': map[keys[1]],
               'region': map[keys[2]],
               'region_name': map[keys[3]],
               'province': map[keys[4]],
               'province_name': map[keys[5]],
               'group_code': map[keys[6]],
               'group_name': map[keys[7]],
               'slp_code': map[keys[8]],
               'sale_name': map[keys[9]],
               'cntct_code': map[keys[10]],
               'contact_name': map[keys[11]],
               'contact_phone': map[keys[12]],
               'ship_to_code': map[keys[13]],
               'address': map[keys[14]],
               'auto_sync': map[keys[15]],
            }
         ))
      }
      return qs
   }

   // Import sale


   const onCloseImportSale = () => {
      pageDispatch({type: customerActions.MODAL_IMPORT_SALE_CUSTOMER, payload: false})
   }

   const onOpenImportSale = () => {
      pageDispatch({type: customerActions.MODAL_IMPORT_SALE_CUSTOMER, payload: true})
   }


   const handleGetSaleResult = res => {
      if (res) {
         pageDispatch({
            type: customerActions.MODAL_IMPORT_SALE_RESULT_OPEN,
            payload: {
               open: true,
               data: res
            }
         })
         functions?.applyCustomerOtherFilter()
      }
   }
   const handleCloseSaleResult = _ => {
      pageDispatch({
         type: customerActions.MODAL_IMPORT_SALE_RESULT_OPEN,
         payload: {
            open: false,
            data: null
         }
      })
   }

   const handleCollectionSaleData = (data) => {
      let sliceData = data?.slice(
         CUSTOMER_COLUMN_SALE_NAMES?.startRowNumber - 1
      )
      if (sliceData.length > 1000) sliceData = sliceData.slice(0, 1000)

      let qs = {
         items: sliceData?.map((map, i) => (
            {
               'row': +CUSTOMER_COLUMN_SALE_NAMES?.startRowNumber + i,
               'card_code': map[0],
               'card_name': map[1],
               'sale_email': map[2],
            }
         ))
      }
      return qs
   }

   const handleCollectionSaleCsv = data => {
      const sliceData = data?.slice(4)
      const keys = sliceData?.map(map => Object.keys(map))[0]
      let qs = {
         items: sliceData?.map((map, i) => (
            {
               'row': +CUSTOMER_COLUMN_SALE_NAMES?.startRowNumber + i,
               'card_code': map[keys[0]],
               'card_name': map[keys[1]],
               'sale_email': map[keys[2]],
            }
         ))
      }
      return qs
   }


   return (
      <>
         {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
         <PageHeader
            breadcrumbLinks={CUSTOMER_BREADCRUMB}
            breadcrumbTitle="Quản lý khách hàng"
            actions={[
               {
                  id: 1,
                  name: 'Import Sale',
                  appearance: 'secondary',
                  icon: GLOBAL_ICONS.filePlus,
                  onClick: onOpenImportSale,
               },
               {
                  id: 2,
                  name: 'Import khách hàng',
                  appearance: 'secondary',
                  icon: GLOBAL_ICONS.filePlus,
                  onClick: onOpenImport,
               },
               {
                  name: 'Xuất excel',
                  icon: GLOBAL_ICONS.export,
                  onClick: handleExportClick,
                  appearance: 'secondary',
               },
               {
                  name: 'Thêm khách hàng',
                  icon: GLOBAL_ICONS.plus,
                  onClick: handleOpenModalCreateCustomer,
                  appearance: 'secondary',
               }
            ]}
         />

         {
            pageState?.importExcel?.open && <ImportFileModal
               onClose={onCloseImport}
               columnNames={CUSTOMER_COLUMN_NAMES?.columnNames}
               startRowNumber={CUSTOMER_COLUMN_NAMES?.startRowNumber}
               collectedData={handleCollectionData}
               url={'customer/import'}
               title={'Import khách hàng'}
               importFile={'/files/import_customers.xlsx'}
               positionStart={2}
               positionCsvStart={3}
               result={handleGetResult}
               collectedDataCsv={handleCollectionCsv}
            />
         }
         {
            pageState?.importSaleExcel?.open && <ImportFileModal
               onClose={onCloseImportSale}
               columnNames={CUSTOMER_COLUMN_SALE_NAMES?.columnNames}
               startRowNumber={CUSTOMER_COLUMN_SALE_NAMES?.startRowNumber}
               collectedData={handleCollectionSaleData}
               url={'customer/import-sale-manage'}
               title={'Import Sale'}
               importFile={'/files/import_sale_manage_customers.xlsx'}
               positionStart={2}
               positionCsvStart={3}
               result={handleGetSaleResult}
               collectedDataCsv={handleCollectionSaleCsv}
            />
         }
         {
            pageState?.importSaleExcel?.result?.open && <ResultImport
               open={pageState?.importSaleExcel?.result?.open}
               data={pageState?.importSaleExcel?.result?.data}
               title={'Sale'}
               onClose={handleCloseSaleResult}
            />
         }
         {
            pageState?.importExcel?.result?.open && <ResultImport
               open={pageState?.importExcel?.result?.open}
               data={pageState?.importExcel?.result?.data}
               title={'Khách hàng'}
               onClose={handleCloseResult}
            />
         }
         {!!exportModalData &&
         <ModalExport data={exportModalData} title={'Khách hàng'} api={`/customer/export-customers`}/>}
      </>
   )
}
import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateSaleForm} from "../../hook/useCreateSaleForm";

export const AccountNumber = ()=>{
    const {commission} = useCreateSaleForm()
    const {methods} = commission
    return (
        <Input
            label={
                <>
                    % hoa hồng <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={commission?.value}
            onChange={e => methods.onChange(e.target?.value)}
            validateText={commission?.message}
            validateType={commission?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập % hoa hồng của hiệu trưởng'}
            maxLength={32}
        />
    )
}
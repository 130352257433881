import React, {useState, useRef} from 'react'
import {useContext} from 'react'
import {StyledProductGroupContentModal} from './_styled'
import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {OrderContext} from '../../provider/_context'
import {Option} from '../../../../common/form/autoComplete/_option'
import {useEditOrderModal} from '../../hooks/useEditOrderModal'
import {Input} from "../../../../common/form/input";

const Index = ({...props}) => {

   const {pageState} = useContext(OrderContext)
   const {dataCurrent} = pageState.editOrderModal
   const {contact, school, address, detail, addressStrange} = useEditOrderModal()

   const [shouldOpenDropdown, setShouldOpenDropdown] = useState(false);

   const dropdownRef = useRef(null);
   const hoverInputRef = useRef(null);
   const menuList = useRef(null);

   return (
      <StyledProductGroupContentModal>
         <div className={'product-group-content'}>
            <div className={'product-group-content__bottom'}>
               <div className='product-group-content_group'>
                  <Input value={detail?.id} disabled={true} label={'Mã đơn hàng'}/>
               </div>
               {
                  detail?.card_code
                     ?
                     <>
                        <div className='product-group-content_group'>
                           <AlternativeAutoComplete
                              className="customer-filter-form__input-wide"
                              // main input
                              inputProps={{
                                 categoryList: [], // menu list in category dropdown
                                 categoryValue: {name: 'Người liên hệ', value: ''}, // if not exist this value -> default category: categoryList[0]
                                 categoryWidth: 82,
                                 categoryHidden: true,
                                 placeholder: 'Chọn người liên hệ', label: "Người liên hệ",
                                 readOnly: true,
                                 value: contact.value?.cntct_code ? contact.value?.contact_name : '',
                              }}
                              // menu
                              menuProps={{
                                 empty: contact.list.length <= 0 ? 'Không tìm thấy người liên hệ' : '',
                              }}
                              // search input in dropdown menu
                              searchInputProps={{
                                 placeholder: 'Tìm kiếm người liên hệ',
                                 value: contact.keyword,
                                 onChange: contact.onKeywordChange,
                              }}
                           >
                              {contact.list.length > 0 &&
                              contact.list.map(item => (
                                 <Option
                                    key={item.cntct_code}
                                    className="customer-filter-form__option-text"
                                    data-active={item.cntct_code === contact.value?.cntct_code}
                                    onClick={() => contact.onChange(item)}
                                 >
                                    {item.contact_name}
                                 </Option>
                              ))}
                           </AlternativeAutoComplete>
                        </div>
                        <div className='product-group-content_group'>
                           <AlternativeAutoComplete
                              className="customer-filter-form__input-wide"
                              // main input
                              inputProps={{
                                 categoryList: [], // menu list in category dropdown
                                 categoryValue: {name: 'Địa chỉ', value: ''}, // if not exist this value -> default category: categoryList[0]
                                 categoryWidth: 82,
                                 categoryHidden: true,
                                 placeholder: 'Chọn địa chỉ', label: 'Địa chỉ',
                                 readOnly: true,
                                 value: address.value?.address || '',
                              }}
                              // menu
                              menuProps={{
                                 empty: address.list.length <= 0 ? 'Không tìm thấy địa chỉ' : '',
                              }}
                              // search input in dropdown menu
                              searchInputProps={{
                                 placeholder: 'Tìm kiếm địa chỉ',
                                 value: address.keyword,
                                 onChange: address.onKeywordChange,
                              }}
                           >
                              {address.list.length > 0 &&
                              address.list.map(item => (
                                 <Option
                                    key={item.ship_to_code}
                                    className="customer-filter-form__option-text"
                                    data-active={item.ship_to_code === address.value?.ship_to_code}
                                    onClick={() => address.onChange(item)}
                                 >
                                    {item.address}
                                 </Option>
                              ))}
                           </AlternativeAutoComplete>
                        </div>
                     </>
                     :
                     <>
                        <div className='product-group-content_group school-select__search'>
                           <Input label={"Trường học"}
                                  placeholder={'Nhập trường học'}
                                  value={school.value?.school_name}
                                  onChange={e => school.onKeywordChange(e.target.value)}
                                  onFocus={() => {
                                     setShouldOpenDropdown(true);
                                  }}
                           />
                           {shouldOpenDropdown && (
                              <>
                                 <div
                                    className="input__backdrop"
                                    onClick={() => setShouldOpenDropdown(false)}
                                 ></div>
                                 <input ref={hoverInputRef} type="hidden"/>
                                 <div
                                    ref={dropdownRef}
                                    className={`input__dropdown common-scrollbar`}
                                    // onScroll={handleDropdownScroll}
                                 >
                                    <div ref={menuList} className="contact-list__list">
                                       {school.list?.length > 0 ? (
                                          school.list.map((item) => (
                                             <Option
                                                key={item.school_code}
                                                className="customer-filter-form__option-text"
                                                data-active={item.school_code === school.value?.school_code}
                                                onClick={() => {
                                                   school.onChange(item)
                                                   setShouldOpenDropdown(false);
                                                }}
                                             >
                                                {item.school_name}
                                             </Option>
                                          ))
                                       ) : (
                                          <div className={"contact-list__empty"}>trống</div>
                                       )}
                                    </div>
                                 </div>
                              </>
                           )}

                        </div>
                        <div className='product-group-content_group'>
                           <Input value={addressStrange?.value}
                                  onChange={e => addressStrange.onChange(e.target.value)}
                                  label={'Địa chỉ'}
                                  disabled={!!school.value?.school_code}/>
                        </div>
                     </>
               }
            </div>
         </div>

      </StyledProductGroupContentModal>

   )
}
export default Index
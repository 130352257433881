import React, { useContext, useEffect } from 'react'
import { StyledDashboardPanels } from './_styled'
import { Dashboard } from '../../provider/_context'
import { DashboardPanel } from './_dashboardPanel'
import { sendRequestAuth } from '../../../../api/api'
import config from '../../../../config'
import { useDashboardAction } from '../../provider/_reducer'
import { formatDatetime } from '../../../../common/form/datePicker/_functions'

export const DashboardPanels = ({ ...props }) => {
  const { pageState, pageDispatch } = useContext(Dashboard)
  const { panels } = pageState

  useEffect(
    () => {
      pageDispatch({
        type: useDashboardAction.IS_LOADING_PANEL,
        payload: true
      })
      const getOverview = async () => {
        const response = await sendRequestAuth('get', `${config.API}/dashboard/overview`)
        if (!!response?.data?.success) {
          pageDispatch({
            type: useDashboardAction.UPDATE_DASHBOARD,
            payload: {
              panels: response?.data?.data
            }
          })

          pageDispatch({
            type: useDashboardAction.IS_LOADING_PANEL,
            payload: false
          })
        }

      }
      getOverview()
    }
    , [])
  return (
    <StyledDashboardPanels {...props}>
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng doanh thu"
        value={panels?.revenue || 0}
      />
      {/*<DashboardPanel*/}
      {/*  className="order-panels__item"*/}
      {/*  currency=""*/}
      {/*  title="Tổng hiệu trưởng"*/}
      {/*  value={panels?.principals || 0}*/}
      {/*/>*/}
      <DashboardPanel
        className="order-panels__item"
        currency=""
        title="Tổng Sale quản lý"
        value={panels?.sales || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency=""
        title="Tổng khách hàng"
        value={panels?.customers || 0}
      />
      {/*<DashboardPanel*/}
      {/*  className="order-panels__item"*/}
      {/*  currency=""*/}
      {/*  title="Tổng khách hàng có GV giới thiệu"*/}
      {/*  value={panels?.customer_has_referral || 0}*/}
      {/*/>*/}
      {/*<DashboardPanel*/}
      {/*  className="order-panels__item"*/}
      {/*  currency=""*/}
      {/*  title="Tổng khách hàng không GV giới thiệu"*/}
      {/*  value={panels?.customer_non_referral || 0}*/}
      {/*/>*/}
    </StyledDashboardPanels>
  )
}

import {TableLayout} from "../../../../../../layouts/tableLayout";
import ProductTHead from "./~productTHead";
import ProductTBody from "./~productTBody";
import ActionFormBrnList from "../../actionFormBrnList";

export const CategoryType = () => {

   return (
      <>
         <TableLayout
            table={{
               tHead: <ProductTHead/>,
               tBody: <ProductTBody/>,
            }}
         />
         <div style={{
            position: 'sticky',
            bottom: -44,
            marginBottom: 0,
            zIndex: 10,
            padding: "12px 24px 12px 12px",
            background: '#fff'
         }}>
            <ActionFormBrnList type={'category'}/>
         </div>
      </>
   )
}
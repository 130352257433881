import {useEffect, useState} from "react"
import {useContext} from "react"
import {CustomerContext} from '../provider/_context'
import {customerActions, useContactModalAction, useCreateCustomerModalAction} from "../provider/_reducer"
import {useTranslation} from "react-i18next";
import useAlert from '../../../hook/useAlert'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import useCustomerFilterForm from "./useCustomerFilterForm";

export const useCreateCustomerModal = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(CustomerContext)
   const [animate, setAnimate] = useState(false)
   const {showAlert} = useAlert()
   const {createModal} = pageState

   const handleCustomerNameChange = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            customerName: data
         }
      })
   }
   const handleCustomerNameBlur = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
            customerName: data.trim().length === 0 ? "Tên khách hàng không được để trống" : ''
         }
      })
   }


   const handleCardCodeChange = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            cardCode: data
         }
      })
   }
   const handleCardCodeBlur = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
            cardCode: data.trim().length === 0 ? "Cardcode không được để trống" : ''
         }
      })
   }

   const handleGroupCustomerChange = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            groupCustomer: data
         }
      })
   }

   const handleGroupCustomerBlur = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
            groupCustomer: data.trim().length === 0 ? "Group khác hàng không được để trống" : ''
         }
      })
   }


   const handleStateCustomerChange = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            stateCustomer: data
         }
      })
   }

   const handleStateCustomerBlur = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
            stateCustomer: data.trim().length === 0 ? "Tỉnh thành không được để trống" : ''
         }
      })
   }

   const handleRegionCustomerChange = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            regionCustomer: data
         }
      })
   }

   const handleRegionCustomerBlur = data => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
            regionCustomer: data.trim().length === 0 ? "Khu vực không được để trống" : ''
         }
      })
   }

   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   const handleClose = () => {
      if (!pageState.createModal?.change_modal) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({
               type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
                  open_modal: false,
                  change_modal: true,
                  status: null,
                  cardCode: null,
                  customerName: null,
                  groupCustomer: null,
                  stateCustomer: null, // tỉnh thành
                  regionCustomer: null, // Vùng
                  validate: null
               }
            })
            setAnimate(false)
         }, 300)
         pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
      } else {
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
               modal_confirm: true
            }
         })
      }
   }
   const checkBeforeSubmit = [
      pageState.valid?.filde_name?.status,
      pageState.valid?.filde_parent?.status,
   ].includes(true)

   const validateModal = () => {
      let valid = true
      if (!createModal?.customerName) {
         valid = false
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
               customerName: "Tên khách hàng không được để trống"
            }
         })
      }
      if (!createModal?.cardCode) {
         valid = false
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
               cardCode: "Cardcode không được để trống"
            }
         })
      }
      if (!createModal?.groupCustomer) {
         valid = false
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
               groupCustomer: "Group khách hàng không được để trống"
            }
         })
      }
      if (!createModal?.regionCustomer) {
         valid = false
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
               regionCustomer: "Vùng không được để trống"
            }
         })
      }
      if (!createModal?.stateCustomer) {
         valid = false
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
               stateCustomer: "Tỉnh thành không được để trống"
            }
         })
      }
      return valid
   }
   const {functions} = useCustomerFilterForm()
   const handleAccept = async () => {

      if (!validateModal()) return

      const url = !!createModal?.status ? `${config.API}/customer/update/${createModal?.cardCode}` : `${config.API}/customer/create`
      const data = !!createModal?.status ?
         {
            "card_name": createModal?.customerName,
            "group_name": createModal?.groupCustomer,
            "region_name": createModal?.regionCustomer,
            "province_name": createModal?.stateCustomer
         }
         : {
            "card_code": createModal?.cardCode,
            "card_name": createModal?.customerName,
            "group_name": createModal?.groupCustomer,
            "region_name": createModal?.regionCustomer,
            "province_name": createModal?.stateCustomer
         }
      const response = await sendRequestAuth('post', url,
         data
      )
      if (!!response?.data?.success) {
         setTimeout(() => {
            pageDispatch({
               type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
                  open_modal: false
               }
            })
            setAnimate(false)
         }, 300)
         pageDispatch({
            type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
               modal_confirm: false,
               customerId: null,
               change_modal: true,
               cardCode: null,
               customerName: null,
               groupCustomer: null,
               stateCustomer: null, // tỉnh thành
               regionCustomer: null, // Vùng
               status: null,
               validate: null
            }
         })
         functions.applyCustomerOtherFilter()
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })

         response?.data?.errors?.forEach((error) => {
               switch (error.field) {
                  case 'card_code':
                     pageDispatch({
                        type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           cardCode: error.message
                        }
                     })
                     break;
                  case 'card_name':
                     pageDispatch({
                        type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           customerName: error.message
                        }
                     })
                     break;
                  case 'group_name':
                     pageDispatch({
                        type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           groupCustomer: error.message
                        }
                     })
                     break;
                  case 'region_name':
                     pageDispatch({
                        type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           regionCustomer: error.message
                        }
                     })
                     break;
                  case 'province_name':
                     pageDispatch({
                        type: useCreateCustomerModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           stateCustomer: error.message
                        }
                     })
                     break;
                  default:
                     break;
               }
            }
         )
      }

   }

   const handleCancelConfirm = () => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            modal_confirm: false,
         }
      })
   }
   const handleAcceptConfirm = () => {
      pageDispatch({
         type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {
            modal_confirm: false,
            customerId: null,
            change_modal: true,
            cardCode: null,
            customerName: null,
            groupCustomer: null,
            stateCustomer: null, // tỉnh thành
            regionCustomer: null, // Vùng
            status: null,
            validate: null
         }
      })
      setAnimate(true)
      setTimeout(() => {
         pageDispatch({type: useCreateCustomerModalAction.UPDATE_MODAL, payload: {open_modal: false}})
         setAnimate(false)
      }, 300)
      pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
   }


   return {
      status: createModal?.status,
      validate: createModal?.validate,
      customerName: {
         value: createModal.customerName,
         onChange: handleCustomerNameChange,
         onBlur: handleCustomerNameBlur,
      },
      cardCode: {
         value: createModal.cardCode,
         onChange: handleCardCodeChange,
         onBlur: handleCardCodeBlur,
      },
      groupCustomer: {
         value: createModal.groupCustomer,
         onChange: handleGroupCustomerChange,
         onBlur: handleGroupCustomerBlur
      },
      stateCustomer: {
         value: createModal.stateCustomer,
         onChange: handleStateCustomerChange,
         onBlur: handleStateCustomerBlur,
      },
      regionCustomer: {
         value: createModal.regionCustomer,
         onChange: handleRegionCustomerChange,
         onBlur: handleRegionCustomerBlur
      },
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
      },
      animate,
   }
}